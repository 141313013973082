import { Container, Grid, Paper } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    height: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  sectionHeader: {
    marginTop: theme.spacing(2),
    fontWeight: 500,
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(12)
    }
  },
  sectionColor: {
    padding: theme.spacing(2, 2, 2, 2),
    color: theme.palette.customs.lightBlue
  },
  paper: {
    background: '#f1f5f9',
    borderLeftColor: theme.palette.info.light,
    borderLeftStyle: 'solid',
    borderLeftWidth: '10px'
  }
}));

const BcrID = () => {
  const classes = useStyles();
  const location = useLocation();
  const bcrIdArray = location.state;
  const bcrId = Object.values(bcrIdArray);
  const hasError = JSON.stringify(bcrId[0]) === `"error"` ? true : false;
  const hasSessionExpired =
    JSON.stringify(bcrId[0]) === `"Your session has expired"` ? true : false;

  return (
    <>
      <Container maxWidth="lg">
        <Grid
          container
          item
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          {hasError === false && hasSessionExpired === false && (
            <>
              <Grid item>
                <Typography
                  variant="h4"
                  align="center"
                  className={classes.sectionHeader}
                >
                  Thank you for submitting your Border Cash Report
                  <br />
                  to New Zealand Customs Service
                  <br />
                  <br />
                  <Paper elevation={0} square={true} className={classes.paper}>
                    <Typography variant="h6" className={classes.sectionColor}>
                      The completion of this Border Cash Report does not mean
                      you have declared your cash to New Zealand Customs
                      Service. You must still inform a Customs officer that you
                      are moving or receiving cash across the border as required
                      under the Anti-Money Laundering and Countering Financing
                      of Terrorism Act 2009.
                    </Typography>
                  </Paper>
                  <br />
                  Your Border Cash Report ID:{' '}
                  <strong>{JSON.stringify(bcrId[0])}</strong>
                </Typography>
                <br />
                <br />
                <br />
              </Grid>
            </>
          )}
          {hasSessionExpired === true && (
            <>
              <Typography
                variant="h4"
                align="center"
                className={classes.sectionHeader}
              >
                <strong>{JSON.stringify(bcrId[0])}</strong>
              </Typography>
            </>
          )}

          {hasError === true && (
            <>
              <Typography
                variant="h4"
                align="center"
                className={classes.sectionHeader}
              >
                <strong>{JSON.stringify(bcrId[0])}</strong>
                <br />
                Oops! currently there is a technical problem, please try again
                later.
              </Typography>
            </>
          )}
        </Grid>
      </Container>
    </>
  );
};

export default BcrID;
