export interface FetchResponse<TData> {
  status: number;
  data?: TData; // TODO: use open-api spec codegen
  error?: any;
}

export interface ErrorResponse {
  status: number;
  statusText: string;
  data?: {
    errors?: { description: string }[];
  };
}

/* eslint-disable no-unused-vars */
async function fetch<TData = any>(
  url: string,
  options: any = {
    headers: null,
    formatResponse: null
  }
): Promise<FetchResponse<TData>> {
  const headers: {
    [key: string]: string;
  } = {};

  // set to run under your localhost
  if (process.env.REACT_APP_ENV === 'local') {
    const access_token = localStorage.getItem('access_token');
    if (access_token) {
      headers.Authorization = `Bearer ${access_token}`;
    }
  }

  const response = await attempt(() =>
    window.fetch(url, {
      ...options,
      headers: {
        ...headers,
        ...(options.headers || {})
      }
    })
  );

  if (response.status === 204)
    return Promise.resolve({
      status: response.status
    }); // DELETE
  if (response.ok) {
    const asJson = async (rsp: Response) => rsp.json();
    const format = options.formatResponse || asJson;
    const data = await format(response);
    return {
      status: response.status,
      data
    };
  }

  let err;
  try {
    err = await response.json();
    //try to get access-token again
    // if (response.status === 401) {
    //   return fetch(`${process.env.REACT_APP_GATEWAY_BASE_URL}/oauth/token`, {
    //     method: 'POST',
    //     body: new URLSearchParams({
    //       grant_type: 'client_credentials',
    //       client_id: process.env.REACT_APP_GATEWAY_CLIENT_ID,
    //       client_secret: process.env.REACT_APP_GATEWAY_CLIENT_SECRET
    //     } as any)
    //   });
    // }
  } catch {}
  throw new FetchError({
    status: response.status,
    statusText: response.statusText,
    data: err
  });
}

export default fetch;

class FetchError extends Error {
  response: ErrorResponse;

  constructor(response: ErrorResponse) {
    super(`FetchError - ${response.statusText}`);
    this.response = response;
  }
}

const attempt = async (
  fetch: () => Promise<Response>,
  errorCodes = [403],
  maxAttempts = 2
) => {
  let result = await fetch();
  if (errorCodes.includes(result.status) && maxAttempts > 1) {
    result = await attempt(fetch, errorCodes, maxAttempts - 1);
  }
  return result;
};
