import { ReactNode, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import CustomButton from 'components/Button';

/*---------------------------------
CUSTOM STYLES - MUI
-----------------------------------*/
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '90%',
    height: 'auto',
    maxWidth: '1000px',
    maxHeight: '90vh',
    overflow: 'auto',
    position: 'relative'
  },
  buttonRoot: {
    textAlign: 'left',
    '&.link': {
      textDecoration: 'none',
      background: 'transparent',
      border: 'none',
      cursor: 'pointer',
      fontSize: 'inherit',
      fontFamily: 'inherit',
      fontWeight: 'inherit'
    },
    '&.blue': {
      color: theme.palette.customs.mainBlue
    },
    '&.secondaryBlue': {
      color: theme.palette.customs.secondaryBlue
    },
    '&.white': {
      color: 'white'
    }
  },
  buttonClose: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    background: 'transparent',
    border: 'none',
    cursor: 'pointer'
  },
  buttonCloseIcon: {
    fontSize: '2rem'
  }
}));

/*---------------------------------
TYPES
-----------------------------------*/
interface CustomModalProps {
  children: ReactNode;
  buttonText: string;
  buttonStyle: 'link' | 'button';
  buttonColor: 'blue' | 'secondaryBlue' | 'white';
}

type CustomButtonColor = 'fillBlue' | 'fillSecondaryBlue' | 'fillWhite';

/*---------------------------------
EXPORT COMPONENT
-----------------------------------*/
const CustomModal = ({
  children,
  buttonText,
  buttonStyle,
  buttonColor
}: CustomModalProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Set button color for CustomButton component
  let customButtonColor = 'fillBlue' as CustomButtonColor;
  if (buttonStyle === 'button') {
    if (buttonColor === 'secondaryBlue') {
      customButtonColor = 'fillSecondaryBlue';
    } else if (buttonColor === 'white') {
      customButtonColor = 'fillWhite';
    }
  }

  return (
    <>
      {buttonStyle === 'link' && (
        <button
          className={`${classes.buttonRoot} ${buttonStyle} ${buttonColor}`}
          type="button"
          onClick={handleOpen}
        >
          {buttonText}
        </button>
      )}
      {buttonStyle === 'button' && (
        <CustomButton
          handleClick={handleOpen}
          text={buttonText}
          buttonSize="sizeSmall"
          buttonColor={customButtonColor}
        />
      )}

      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
        disableScrollLock={true}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <button
              type="button"
              className={classes.buttonClose}
              onClick={handleClose}
            >
              <CloseIcon className={classes.buttonCloseIcon} />
            </button>
            {children}
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default CustomModal;
