import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Snackbar,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useFormContext } from 'react-hook-form';
import ControllerDatePicker from 'components/ControllerDatePicker';
import ControllerAutocomplete from '../components/ControllerAutocomplete';
import ControllerTextField from '../components/ControllerTextField';
import ControllerRadioGroup from 'components/ControllerRadioGroup';
import { CodeDescription } from 'types';
import * as yup from 'yup';
import { SubmittingWarningNote } from './SubmittingWarningNote';
import {
  ALPHABETS_NUMBERS_HYPHENS,
  ALPHABETS_NUMBERS_HYPHENS_MATCHES,
  MAX_LONG_STRING_LENGTH,
  MAX_LONG_STRING_LENGTH_WARNING
} from 'lib/yupConstants';
import moment from 'moment';
import { bcrTheme } from 'config/bcrTheme';

const useStyles = makeStyles((theme: Theme) => ({
  fields: {
    margin: theme.spacing(2, 2, 4, 0)
  },
  sectionHeader: {
    margin: theme.spacing(0, 5, 0, 0)
  },
  bottom: {
    margin: theme.spacing(2, 0, 2, 0)
  },
  snackBar: {
    position: 'relative',
    zIndex: 1
  },
  datePicker: {
    zIndex: 2
  }
}));

export const form4PersonalInfoDefaultValues = () => ({
  cashOwner: {
    nzResident: '',
    citizenship: null,
    givenNames: '',
    familyName: '',
    occupation: '',
    gender: null
  }
});

export const Form4PersonalInfoSchema = () =>
  yup.object().shape({
    cashOwner: yup
      .object()
      .shape({
        nzResident: yup.string().required('This is a required field'),
        citizenship: yup
          .object()
          .nullable()
          .when('nzResident', {
            is: (nzResident) => nzResident === 'yes',
            then: yup
              .object()
              .shape({
                code: yup.string().nullable().notRequired(),
                description: yup.string().nullable().notRequired()
              })
              .nullable()
              .notRequired(),
            otherwise: yup
              .object()
              .shape({
                code: yup
                  .string()
                  .nullable()
                  .required('This is a required field'),
                description: yup
                  .string()
                  .nullable()
                  .required('This is a required field')
              })
              .nullable()
              .required('This is a required field')
          }),
        givenNames: yup
          .string()
          .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
          .matches(
            ALPHABETS_NUMBERS_HYPHENS_MATCHES,
            `${ALPHABETS_NUMBERS_HYPHENS}`
          )
          .required('This is a required field')
          .trim()
          .min(1),
        familyName: yup
          .string()
          .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
          .matches(
            ALPHABETS_NUMBERS_HYPHENS_MATCHES,
            `${ALPHABETS_NUMBERS_HYPHENS}`
          )
          .required('This is a required field')
          .trim()
          .min(1),
        gender: yup
          .object()
          .shape({
            code: yup.string().nullable().notRequired(),
            description: yup.string().nullable().notRequired()
          })
          .nullable()
          .required('This is a required field'),
        dateOfBirth: yup
          .string()
          .nullable()
          .required('This is a required field')
          .test('DOB', 'Please choose a valid date of birth', (value) => {
            if (
              moment().diff(moment(value).add(1, 'day'), 'days') < 0 ||
              moment('1902-01-01').diff(moment(value), 'years') >= 0
            ) {
              return false;
            } else if (moment().diff(moment(value), 'days') >= 0) {
              return true;
            }
          }),
        occupation: yup
          .string()
          .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
          .matches(
            ALPHABETS_NUMBERS_HYPHENS_MATCHES,
            `${ALPHABETS_NUMBERS_HYPHENS}`
          )
          .required('This is a required field')
          .trim()
          .min(1)
      })
      .required()
  });

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const genders: CodeDescription[] = [
  { description: 'Female', code: 'F' },
  { description: 'Male', code: 'M' },
  { description: 'Gender diverse', code: 'X' }
];
interface Form4Props {
  countryCodes: CodeDescription[];
}

export const Form4PersonalInfo: FC<Form4Props> = ({ countryCodes }) => {
  const classes = useStyles();

  const isDown281 = useMediaQuery(bcrTheme.breakpoints.down(281));

  const [open, setOpen] = useState(false);
  const [dateBirthMessage, setDateBirthMessag] = useState('');

  const { watch, clearErrors } = useFormContext();

  const nzResident = watch(`form4.cashOwner.nzResident`);

  let isNzResident = null;
  isNzResident = nzResident === 'yes' ? true : false;

  const dateBirth = watch(`form4.cashOwner.dateOfBirth`);
  let birthYear = dateBirth === null ? null : moment(dateBirth).format('YYYY');

  const currentYear = new Date().getFullYear();
  const yearGap = birthYear === null ? null : currentYear - parseInt(birthYear);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (isNzResident) {
      clearErrors(`form4.cashOwner.citizenship`);
    }
  }, [clearErrors, isNzResident]);

  useEffect(() => {
    if (yearGap >= 100 || yearGap === 0) {
      if (yearGap >= 100) {
        setDateBirthMessag(
          'Warning: You have entered a Date of Birth indicating the person is older than 100 years.'
        );
      }

      if (yearGap === 0) {
        setDateBirthMessag(
          'Warning: You have entered a Date of Birth in the current year.'
        );
      }
      setOpen(true);
    }
  }, [open, yearGap]);

  return (
    <>
      <Grid container item className={classes.bottom}>
        <Grid item xs={12}>
          <Typography variant="h4">Your personal information</Typography>
        </Grid>
      </Grid>
      <Grid container item spacing={2} className={classes.fields}>
        <SubmittingWarningNote />
      </Grid>
      <Box mb={2}>
        <Grid container item spacing={2} className={classes.fields}>
          <Grid item xs={11} md={6} xl={6}>
            <ControllerRadioGroup
              label="Are you a New Zealand resident?"
              name={`form4.cashOwner.nzResident`}
              options={[
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' }
              ]}
            />
          </Grid>
          {!isNzResident && (
            <Grid item xs={11} md={6} xl={6}>
              {isDown281 && (
                <>
                  <Typography>What is your country of citizenship?</Typography>
                </>
              )}
              <ControllerAutocomplete
                name={`form4.cashOwner.citizenship`}
                label={isDown281 ? '' : 'What is your country of citizenship?'}
                options={countryCodes}
                getOptionLabel={(option: CodeDescription) =>
                  option.description || ''
                }
                getOptionSelected={(option, selected) =>
                  option?.code === selected?.code
                }
              />
            </Grid>
          )}
          <Grid container item spacing={3}>
            <Grid item xs={11} md={6} xl={6}>
              <ControllerTextField
                name={`form4.cashOwner.givenNames`}
                label="Given Name(s)"
                fullWidth
              />
            </Grid>
            <Grid item xs={11} md={6} xl={6}>
              <ControllerTextField
                name={`form4.cashOwner.familyName`}
                label="Family Name"
                fullWidth
              />
            </Grid>
            <Grid item xs={11} md={6} xl={6}>
              <ControllerAutocomplete
                name={`form4.cashOwner.gender`}
                label="Gender"
                options={genders}
                getOptionLabel={(option: CodeDescription) =>
                  option?.description || ''
                }
                getOptionSelected={(option, selected) =>
                  option?.code === selected?.code
                }
              />
            </Grid>
            <Grid item xs={11} md={6} xl={6}>
              <ControllerDatePicker
                name={`form4.cashOwner.dateOfBirth`}
                label="Date of Birth"
                defaultValue={null}
                minDate={moment('01/01/1903')}
                disableFuture
                fullWidth
                className={classes.datePicker}
              />
              <Snackbar
                autoHideDuration={1000}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
                className={classes.snackBar}
              >
                <Alert severity="warning">{dateBirthMessage}</Alert>
              </Snackbar>
              <br />
            </Grid>
          </Grid>
          <Grid item xs={11} md={6} xl={6}>
            {isDown281 && (
              <>
                <Typography>Occupation, Business, or Main Activity:</Typography>
              </>
            )}
            <ControllerTextField
              name={`form4.cashOwner.occupation`}
              label={isDown281 ? '' : 'Occupation, Business, or Main Activity'}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
