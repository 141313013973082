import React from 'react';
import { Grid, Hidden, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ControllerRadioGroup from 'components/ControllerRadioGroup';
import ControllerTextField from 'components/ControllerTextField';
import { useFormContext } from 'react-hook-form';
import * as yup from 'yup';
import {
  ALPHABETS_NUMBERS_HYPHENS,
  ALPHABETS_NUMBERS_HYPHENS_MATCHES,
  MAX_LONG_STRING_LENGTH,
  MAX_LONG_STRING_LENGTH_WARNING
} from 'lib/yupConstants';
import { bcrTheme } from 'config/bcrTheme';

const useStyles = makeStyles((theme) => ({
  fields: {
    margin: theme.spacing(1, 0, 0, 0)
  },
  sectionHeader: {
    fontFamily: 'theme.typography.fontFamily',
    margin: theme.spacing(1, 5, 0, 0)
  },
  cardPadding: {
    margin: theme.spacing(2, 0, 0, 0),
    padding: theme.spacing(2, 0)
  },
  someTextField: {
    minHeight: 30
  }
}));

export const Form14InfoOnCashSchema = () =>
  yup.object().shape({
    moneyFrom: yup
      .string()
      .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
      .matches(
        ALPHABETS_NUMBERS_HYPHENS_MATCHES,
        `${ALPHABETS_NUMBERS_HYPHENS}`
      )
      .nullable()
      .required('This is a required field'),
    reasonForCarryingCash: yup
      .string()
      .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
      .matches(
        ALPHABETS_NUMBERS_HYPHENS_MATCHES,
        `${ALPHABETS_NUMBERS_HYPHENS}`
      )
      .nullable()
      .required('This is a required field'),
    moneyUsedFor: yup
      .string()
      .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
      .matches(
        ALPHABETS_NUMBERS_HYPHENS_MATCHES,
        `${ALPHABETS_NUMBERS_HYPHENS}`
      )
      .nullable()
      .required('This is a required field'),
    movingCashOnBehalf: yup.string().required('This is a required field'),
    deliveringCashToCompanyOrPerson: yup
      .string()
      .required('This is a required field')
  });

export const form14InfoOnCashDefaultValues = () => ({
  moneyFrom: '',
  reasonForCarryingCash: '',
  moneyUsedFor: '',
  movingCashOnBehalf: '',
  deliveringCashToCompanyOrPerson: ''
});

export const Form14InfoOnCash = () => {
  const classes = useStyles();

  const isDown590 = useMediaQuery(bcrTheme.breakpoints.down(590));

  const { watch } = useFormContext();

  const cashMovementDirection = watch('form7.cashMovementDirection');
  const isArrival =
    cashMovementDirection?.code === 'bringingIn' ||
    cashMovementDirection?.code === 'receiving'
      ? true
      : false;

  return (
    <>
      <Grid container item spacing={2} className={classes.fields}>
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.sectionHeader}>
            Information about the cash movement
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid container item spacing={2} className={classes.cardPadding}>
          <Grid item xs={12}>
            <ControllerTextField
              name={`form14.moneyFrom`}
              label="Explain the source of funds"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Hidden xsDown>
              <ControllerTextField
                name={`form14.reasonForCarryingCash`}
                label={
                  isArrival
                    ? 'Why are you moving or receiving the cash into New Zealand?'
                    : 'Why are you moving the cash out of New Zealand?'
                }
                fullWidth
              />
            </Hidden>
            <Hidden smUp>
              <Typography variant="subtitle1">
                {isArrival
                  ? 'Why are you moving or receiving the cash into New Zealand?'
                  : 'Why are you moving the cash out of New Zealand?'}
              </Typography>
              <ControllerTextField
                name={`form14.reasonForCarryingCash`}
                label=""
                fullWidth
              />
            </Hidden>
          </Grid>
          <Grid item xs={12}>
            {isDown590 && (
              <>
                <Typography variant="subtitle1">
                  What is the cash going to be used for?
                </Typography>
              </>
            )}
            <ControllerTextField
              name={`form14.moneyUsedFor`}
              label={isDown590 ? '' : 'What is the cash going to be used for?'}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2} className={classes.cardPadding}>
          <Grid item xs={12}>
            <ControllerRadioGroup
              label="Are you moving the cash on behalf of a business/organisation or another person?"
              name={`form14.movingCashOnBehalf`}
              options={[
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' }
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <ControllerRadioGroup
              label="Are you delivering the cash to a business/organisation or another person?"
              name={`form14.deliveringCashToCompanyOrPerson`}
              options={[
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' }
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
