import TextField, { OutlinedTextFieldProps } from '@material-ui/core/TextField';
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import Popper, { PopperProps } from '@material-ui/core/Popper';
import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Controller } from 'react-hook-form';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { bcrTheme } from 'config/bcrTheme';

const useStyles = makeStyles((theme) => ({
  paper: {
    background: '#6f6f6f',
    color: theme.palette.common.white
  }
}));

const PopperFitContent = function (
  props: JSX.IntrinsicAttributes & PopperProps
) {
  return (
    <Popper
      {...props}
      style={{ maxWidth: 'fit-content' }}
      placement="bottom-start"
    />
  );
};

export interface MainProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
> extends Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    'renderInput'
  > {
  textFieldProps?: Partial<OutlinedTextFieldProps>;
  /** Name of the text field & name of the controller. No spaces allowed - (set label prop instead ) */
  name: string;
  label: string;
  placeholder?: string;
}

function ControllerAutocomplete<
  T,
  Multiple extends boolean,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
  textFieldProps,
  label,
  name,
  placeholder,
  defaultValue = null,
  ...autocompleteProps
}: MainProps<T, Multiple, DisableClearable, FreeSolo>): ReactElement {
  const classes = useStyles();
  const isUp910 = useMediaQuery(bcrTheme.breakpoints.up(910));

  return (
    <Controller
      data-testid="ControllerAutocomplete"
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, onBlur, ...field }, fieldState }) => (
        <Autocomplete
          id={`autocomplete_${name}`}
          onChange={(event, data) => onChange(data)}
          autoSelect
          openOnFocus
          autoHighlight
          blurOnSelect
          onBlur={onBlur}
          classes={{ paper: classes.paper }}
          loadingText="Loading options..."
          {...field}
          {...autocompleteProps}
          PopperComponent={PopperFitContent}
          renderInput={(params) => (
            <TextField
              name={name}
              onBlur={onBlur}
              placeholder={placeholder}
              inputProps={{
                ...params.inputProps,
                'data-testid': 'ControllerAutocomplete-input',
                autoComplete: 'new-password'
              }}
              inputRef={params.InputProps.ref}
              {...params}
              variant="outlined"
              size={isUp910 ? 'small' : 'medium'}
              label={label}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              {...textFieldProps}
            />
          )}
        />
      )}
    />
  );
}

export default ControllerAutocomplete;
