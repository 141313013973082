import type {
  CheckboxProps,
  FormControlLabelProps,
  FormControlProps
} from '@material-ui/core';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText
} from '@material-ui/core';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { fieldErrors } from '../lib/util';

/** Have different defaultValue and control props */
type FormControlLabelEx = Partial<
  Omit<FormControlLabelProps, 'control' | 'defaultValue'>
>;
interface ControllerProps extends FormControlLabelEx {
  /** Name of the text field & name of the controller. No spaces allowed - (set label prop instead ) */
  name: string;
  /** Must be set here or when setting up useForm */
  defaultValue?: boolean;
  /** This will take higher priority than the message out of errors  */
  helperText?: string;
  /** If you need to extend the form control component */
  FormControlProps?: FormControlProps;
  /** If you need to add props to the checkbox component */
  CheckboxProps?: CheckboxProps;
  FormControlLabelProps?: Omit<
    FormControlLabelProps,
    'control' | 'defaultValue' | 'label'
  >;
}

/**
 * Material UI Checkbox with label wrapped with rhf controller.
 * Handles setting error state and
 * @returns
 */
function ControllerCheckbox({
  name = 'controlled_checkbox',
  defaultValue = false,
  helperText,
  label = 'Checkbox label',
  CheckboxProps,
  FormControlProps,
  FormControlLabelProps
}: ControllerProps) {
  const {
    formState: { errors }
  } = useFormContext();
  const helperOrError = helperText || fieldErrors(errors, name)?.message;
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => (
        <FormControl error={!!fieldErrors(errors, name)} {...FormControlProps}>
          <FormControlLabel
            control={
              <Checkbox
                {...field}
                checked={!!field.value}
                onChange={(e) => field.onChange(e.target.checked)}
                {...CheckboxProps}
              />
            }
            label={label}
            {...FormControlLabelProps}
          />
          {helperOrError && <FormHelperText>{helperOrError}</FormHelperText>}
        </FormControl>
      )}
    />
  );
}

export default ControllerCheckbox;
