import React, { useEffect } from 'react';
import { Grid, Hidden, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useFormContext } from 'react-hook-form';
import ControllerRadioGroup from 'components/ControllerRadioGroup';
import ControllerTextField from 'components/ControllerTextField';
import ControllerAutocomplete from 'components/ControllerAutocomplete';
import {
  AddressComponent,
  addressDefaultValues
} from 'components/AddressComponent';
import { CodeDescription } from 'types';
import { PhoneComponent } from 'components/PhoneComponent';
import * as yup from 'yup';
import { address } from 'lib/yupAddress';
import { phones } from 'lib/yupPhones';
import {
  ALPHABETS_NUMBERS_HYPHENS,
  ALPHABETS_NUMBERS_HYPHENS_MATCHES,
  MAX_LONG_STRING_LENGTH,
  MAX_LONG_STRING_LENGTH_WARNING
} from 'lib/yupConstants';
import isEqual from 'lodash/isEqual';
import { email } from 'lib/yupEmail';
import EmailComponent from 'components/EmailComponent';

const useStyles = makeStyles((theme: Theme) => ({
  fields: {
    margin: theme.spacing(1, 1, 0, 0)
  },
  sectionHeader: {
    margin: theme.spacing(0, 5, 0, 0)
  },
  bottom: {
    margin: theme.spacing(0, 0, -4, 0)
  },
  top: {
    marginTop: theme.spacing(-1)
  }
}));

export const form19SubmitDeclarationDefaultValues = () => ({
  completingFormOnBehalf: '',
  reasonForCompletingFormOnBehalf: '',
  capacityCode: null,
  otherCapacity: '',
  personCompletingForm: {
    countryOfResidence: '',
    givenNames: '',
    familyName: '',
    passportNumber: '',
    occupation: '',
    address: addressDefaultValues,
    phones: [{}],
    emailAddress: ''
  }
});

export const Form19SubmitDeclarationSchema = () =>
  yup.object().shape({
    completingFormOnBehalf: yup.string().required('This is a required field'),

    // conditional when yes is checked
    reasonForCompletingFormOnBehalf: yup
      .string()
      .when('completingFormOnBehalf', {
        is: (completingFormOnBehalf) => completingFormOnBehalf === 'yes',
        then: yup
          .string()
          .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
          .matches(
            ALPHABETS_NUMBERS_HYPHENS_MATCHES,
            `${ALPHABETS_NUMBERS_HYPHENS}`
          )
          .nullable()
          .required('This is a required field')
          .trim()
          .min(1),
        otherwise: yup
          .string()
          .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
          .matches(
            ALPHABETS_NUMBERS_HYPHENS_MATCHES,
            `${ALPHABETS_NUMBERS_HYPHENS}`
          )
          .nullable()
          .notRequired()
      }),
    capacityCode: yup.object().when('completingFormOnBehalf', {
      is: (completingFormOnBehalf) => completingFormOnBehalf === 'yes',
      then: yup
        .object()
        .shape({
          code: yup.string().nullable().required('This is a required field'),
          description: yup
            .string()
            .nullable()
            .required('This is a required field')
        })
        .nullable()
        .required('This is a required field'),
      otherwise: yup
        .object()
        .shape({
          code: yup.string().nullable().notRequired(),
          description: yup.string().nullable().notRequired()
        })
        .nullable()
    }),
    // //condition when other selected
    otherCapacity: yup
      .string()
      .when(['capacityCode', 'completingFormOnBehalf'], {
        is: (capacityCode, completingFormOnBehalf) =>
          isEqual(capacityCode, { code: 'other', description: 'Other' }) &&
          completingFormOnBehalf === 'yes',
        then: yup
          .string()
          .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
          .matches(
            ALPHABETS_NUMBERS_HYPHENS_MATCHES,
            `${ALPHABETS_NUMBERS_HYPHENS}`
          )
          .nullable()
          .required('This is a required field')
          .trim()
          .min(1),
        otherwise: yup
          .string()
          .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
          .matches(
            ALPHABETS_NUMBERS_HYPHENS_MATCHES,
            `${ALPHABETS_NUMBERS_HYPHENS}`
          )
          .nullable()
          .notRequired()
      }),
    personCompletingForm: yup.object().when('completingFormOnBehalf', {
      is: (value) => value === 'yes',
      then: yup.object().shape({
        givenNames: yup
          .string()
          .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
          .matches(
            ALPHABETS_NUMBERS_HYPHENS_MATCHES,
            `${ALPHABETS_NUMBERS_HYPHENS}`
          )
          .nullable()
          .required('This is a required field')
          .trim()
          .min(1),
        familyName: yup
          .string()
          .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
          .matches(
            ALPHABETS_NUMBERS_HYPHENS_MATCHES,
            `${ALPHABETS_NUMBERS_HYPHENS}`
          )
          .nullable()
          .required('This is a required field')
          .trim()
          .min(1),
        passportNumber: yup
          .string()
          .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
          .matches(
            ALPHABETS_NUMBERS_HYPHENS_MATCHES,
            `${ALPHABETS_NUMBERS_HYPHENS}`
          )
          .nullable()
          .optional(),
        occupation: yup
          .string()
          .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
          .matches(
            ALPHABETS_NUMBERS_HYPHENS_MATCHES,
            `${ALPHABETS_NUMBERS_HYPHENS}`
          )
          .nullable()
          .required('This is a required field')
          .trim()
          .min(1),
        address: address().nullable(),
        phones: yup.array().min(1).of(phones()).required(),
        emailAddress: email.emailAddress,
        confirmEmailAddress: email.confirmEmailAddress
      })
    })
  });

interface Form19Props {
  countryCodes: CodeDescription[];
  streetTypes: CodeDescription[];
  capacityTypes: CodeDescription[];
}
export const Form19SubmitDeclaration = ({
  countryCodes,
  streetTypes,
  capacityTypes
}: Form19Props) => {
  const classes = useStyles();

  const { control, watch, clearErrors, setValue, getValues } = useFormContext();

  let isCompletingForOnBehalf = null;
  isCompletingForOnBehalf =
    watch('form19.completingFormOnBehalf') === 'yes' ? true : false;

  let isOtherCapacity = null;
  const capacity = watch('form19.capacityCode');
  isOtherCapacity = capacity?.code === 'other' ? true : false;

  useEffect(() => {
    if (!isCompletingForOnBehalf) {
      clearErrors();
    }
  }, [clearErrors, isCompletingForOnBehalf]);

  return (
    <>
      <Grid container className={classes.bottom}>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.sectionHeader}>
              Submitting the Border Cash Report declaration
            </Typography>
          </Grid>
        </Grid>
        <Grid container item spacing={2} className={classes.fields}>
          <Grid item xs={12}>
            <ControllerRadioGroup
              label="Are you submitting this Border Cash Report on behalf of someone who is unable to?"
              name={`form19.completingFormOnBehalf`}
              options={[
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' }
              ]}
            />
          </Grid>
        </Grid>
        {isCompletingForOnBehalf && (
          <Grid container item spacing={2} className={classes.fields}>
            <Hidden xsDown>
              <Grid item xs={12}>
                <ControllerTextField
                  name={`form19.reasonForCompletingFormOnBehalf`}
                  label="Why are you completing this Border Cash Report on their behalf?"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <ControllerAutocomplete
                  name={`form19.capacityCode`}
                  label="In what capacity are you completing this form on behalf of the owner of the cash?"
                  options={capacityTypes || [{ description: '', code: '' }]}
                  getOptionLabel={(option: CodeDescription) =>
                    option.description || ''
                  }
                  getOptionSelected={(option, selected) =>
                    option?.code === selected?.code
                  }
                  onChange={(e, value: CodeDescription) => {
                    setValue(`form19.capacityCode`, value);
                    if (value?.code !== 'other') {
                      clearErrors(`form19.otherCapacity`);
                    }
                  }}
                />
              </Grid>
            </Hidden>
            <Hidden smUp>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  Why are you completing this Border Cash Report on their
                  behalf?
                </Typography>
                <ControllerTextField
                  name={`form19.reasonForCompletingFormOnBehalf`}
                  label=""
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  In what capacity are you completing this form on behalf of the
                  owner of the cash?
                </Typography>
                <ControllerAutocomplete
                  name={`form19.capacityCode`}
                  label=""
                  options={capacityTypes || [{ description: '', code: '' }]}
                  getOptionLabel={(option: CodeDescription) =>
                    option.description || ''
                  }
                  getOptionSelected={(option, selected) =>
                    option?.code === selected?.code
                  }
                  onChange={(e, value: CodeDescription) => {
                    setValue(`form19.capacityCode`, value);
                    if (value?.code !== 'other') {
                      clearErrors(`form19.otherCapacity`);
                    }
                  }}
                />
              </Grid>
            </Hidden>
            {isOtherCapacity && (
              <Grid item xs={12}>
                <ControllerTextField
                  name={`form19.otherCapacity`}
                  label="Please specify the capacity"
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={12} md={6} xl={6}>
              <ControllerTextField
                name={`form19.personCompletingForm.givenNames`}
                label="Given Name(s)"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <ControllerTextField
                name={`form19.personCompletingForm.familyName`}
                label="Family Name"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <ControllerTextField
                name={`form19.personCompletingForm.passportNumber`}
                label="Passport Number (optional)"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <Hidden xsDown>
                <ControllerTextField
                  name={`form19.personCompletingForm.occupation`}
                  label="What is your occupation, business, or main activity?"
                  fullWidth
                />
              </Hidden>
              <Hidden smUp>
                <Typography variant="subtitle1">
                  What is your occupation, business, or main activity?
                </Typography>
                <ControllerTextField
                  name={`form19.personCompletingForm.occupation`}
                  label=""
                  fullWidth
                />
              </Hidden>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.sectionHeader}>
                Your Contact Information
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.top}>
              <AddressComponent
                description="What is your permanent residential address?"
                name={`form19.personCompletingForm.address`}
                countryCodes={countryCodes}
                streetTypes={streetTypes}
              />
            </Grid>
            <Grid item xs={12}>
              <PhoneComponent
                control={control}
                description="Phone Number:"
                name={`form19.personCompletingForm.phones`}
                growNo={3}
                getValues={getValues}
                setValue={setValue}
              />
            </Grid>
            <Grid item xs={12} md={12} xl={12}>
              <EmailComponent name={`form19.personCompletingForm`} />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};
