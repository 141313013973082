import React, { useEffect } from 'react';
import { Grid, Hidden, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ControllerTextField from 'components/ControllerTextField';
import ControllerAutocomplete from 'components/ControllerAutocomplete';
import moment from 'moment';
import { useFormContext } from 'react-hook-form';
import { CodeDescription } from 'types';
import * as yup from 'yup';
import {
  ALPHABETS_NUMBERS_HYPHENS,
  ALPHABETS_NUMBERS_HYPHENS_MATCHES,
  MAX_LONG_STRING_LENGTH,
  MAX_LONG_STRING_LENGTH_WARNING
} from 'lib/yupConstants';

const useStyles = makeStyles((theme: Theme) => ({
  fields: {
    margin: theme.spacing(1, 0, 0, 0)
  },
  sectionHeader: {
    margin: theme.spacing(1, 5, 0, 0)
  },
  cardPadding: {
    margin: theme.spacing(2, 0, 0, 0),
    padding: theme.spacing(2, 0)
  },
  inputTextColor: {
    color: 'rgba(0, 0, 0, 1)'
  }
}));

export const Form10MailImportInfoSchema = (
  isMail: boolean,
  isArrival: boolean
) => {
  if (isMail && isArrival) {
    return yup.object().shape({
      parcelTrackingNumber: yup
        .string()
        .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
        .matches(
          ALPHABETS_NUMBERS_HYPHENS_MATCHES,
          `${ALPHABETS_NUMBERS_HYPHENS}`
        )
        .nullable()
        .optional(),
      countryFrom: yup
        .object()
        .shape({
          code: yup.string().required('This is a required field'),
          description: yup.string().required('This is a required field')
        })
        .nullable()
        .required('This is a required field')
    });
  } else {
    return yup.object({});
  }
};

export const form10MailImportInfoDefaultValues = () => ({
  parcelTrackingNumber: '',
  dateOfArrivalOrDeparture: null,
  countryFrom: null
});

interface Form10Props {
  countryCodes: CodeDescription[];
}
export const Form10MailImportInfo = ({ countryCodes }: Form10Props) => {
  const classes = useStyles();

  const { setValue } = useFormContext();

  useEffect(() => {
    const newDate = new Date();
    setValue(
      'form10.dateOfArrivalOrDeparture',
      moment(newDate).format('DD/MM/YYYY')
    );
  }, [setValue]);

  return (
    <>
      <Grid container item spacing={2} className={classes.fields}>
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.sectionHeader}>
            Import Information – Mail Movement
          </Typography>
        </Grid>
      </Grid>

      {/* <Box my={2}> */}
      <Grid container>
        <Grid container item spacing={3} className={classes.cardPadding}>
          <Grid item xs={12} md={6} xl={6}>
            <ControllerTextField
              name={`form10.parcelTrackingNumber`}
              label="Parcel Tracking Number (optional)"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} xl={6}>
            <ControllerTextField
              name={`form10.dateOfArrivalOrDeparture`}
              label="Date of Border Cash Declaration"
              inputProps={{
                className: classes.inputTextColor
              }}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} xl={6}>
            <Hidden xsDown>
              <ControllerAutocomplete
                name={`form10.countryFrom`}
                label="Overseas country the cash is being moved from"
                options={countryCodes || [{ description: '', code: '' }]}
                getOptionLabel={(option: CodeDescription) =>
                  option.description || ''
                }
                getOptionSelected={(option, selected) =>
                  option?.code === selected?.code
                }
              />
            </Hidden>
            <Hidden smUp>
              <Typography variant="subtitle1">
                Overseas country the cash is being moved from:
              </Typography>
              <ControllerAutocomplete
                name={`form10.countryFrom`}
                label=""
                options={countryCodes || [{ description: '', code: '' }]}
                getOptionLabel={(option: CodeDescription) =>
                  option.description || ''
                }
                getOptionSelected={(option, selected) =>
                  option?.code === selected?.code
                }
              />
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
      {/* </Box> */}
    </>
  );
};
