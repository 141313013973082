import * as React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import ControllerAutocomplete from './ControllerAutocomplete';
import { Control, FieldValues, useFieldArray } from 'react-hook-form';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { CodeDescription } from 'types';
interface CountriesComponentProps {
  control: Control<FieldValues, any>;
  description: string;
  name: string;
  countryCodes: CodeDescription[];
}

export const CountriesComponent = ({
  control,
  description,
  name,
  countryCodes
}: CountriesComponentProps) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name
  });

  return (
    <>
      <Grid container item spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body1">{description}</Typography>
        </Grid>
      </Grid>
      {fields.map((item, index) => {
        return (
          <Grid container spacing={4} key={item.id}>
            <Grid container item spacing={2}>
              <Grid item xs={12} md={6} xl={6}>
                <ControllerAutocomplete
                  name={`${name}[${index}]`}
                  label="Country"
                  options={countryCodes || [{ description: '', code: '' }]}
                  getOptionLabel={(option: CodeDescription) =>
                    option.description || ''
                  }
                  getOptionSelected={(option, selected) =>
                    option?.code === selected?.code
                  }
                />
              </Grid>

              <Grid item xs={11} md={3} xl={3}>
                {index >= 1 ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    data-testid="Counties-Delete-button"
                    startIcon={<RemoveIcon />}
                    onClick={() => remove(index)}
                  >
                    Delete
                  </Button>
                ) : undefined}
              </Grid>
            </Grid>
          </Grid>
        );
      })}
      <Grid container item spacing={3}>
        <Grid item xs={11} md={5} xl={5}>
          <Button
            variant="contained"
            color="secondary"
            data-testid="Add-NZ-Phone-button"
            startIcon={<AddIcon />}
            onClick={() => {
              append({});
            }}
          >
            Add Another Country
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
