import { CodeDescription } from 'types';

export const InternationalCallingCodes: CodeDescription[] = [
  { description: ' Afghanistan  +93', code: '+93' },
  { description: ' Albania  +355', code: '+355' },
  { description: ' Algeria  +213', code: '+213' },
  { description: ' American Samoa  +684', code: '+684' },
  { description: ' Andorra  +376', code: '+376' },
  { description: ' Angola  +244', code: '+244' },
  { description: ' Anguilla  +809', code: '+809' },
  { description: ' Antigua  +268', code: '+268' },
  { description: ' Argentina  +54', code: '+54' },
  { description: ' Armenia  +374', code: '+374' },
  { description: ' Aruba  +297', code: '+297' },
  { description: ' Ascension Island  +247', code: '+247' },
  { description: ' Australia  +61', code: '+61' },
  { description: ' Australian External Territories  +672', code: '+672' },
  { description: ' Austria  +43', code: '+43' },
  { description: ' Azerbaijan  +994', code: '+994' },
  { description: ' Bahamas  +242', code: '+242' },
  { description: ' Barbados  +246', code: '+246' },
  { description: ' Bahrain  +973', code: '+973' },
  { description: ' Bangladesh  +880', code: '+880' },
  { description: ' Belarus  +375', code: '+375' },
  { description: ' Belgium  +32', code: '+32' },
  { description: ' Belize  +501', code: '+501' },
  { description: ' Benin  +229', code: '+229' },
  { description: ' Bermuda  +809', code: '+809' },
  { description: ' Bhutan  +975', code: '+975' },
  { description: ' British Virgin Islands  +284', code: '+284' },
  { description: ' Bolivia  +591', code: '+591' },
  { description: ' Bosnia and Hercegovina  +387', code: '+387' },
  { description: ' Botswana  +267', code: '+267' },
  { description: ' Brazil  +55', code: '+55' },
  { description: ' British V.I.  +284', code: '+284' },
  { description: ' Brunei Darussalm  +673', code: '+673' },
  { description: ' Bulgaria  +359', code: '+359' },
  { description: ' Burkina Faso  +226', code: '+226' },
  { description: ' Burundi  +257', code: '+257' },
  { description: ' Cambodia  +855', code: '+855' },
  { description: ' Cameroon  +237', code: '+237' },
  { description: ' Canada  +1', code: '+1' },
  { description: ' CapeVerde Islands  +238', code: '+238' },
  { description: ' Caribbean Nations  +1', code: '+1' },
  { description: ' Cayman Islands  +345', code: '+345' },
  { description: ' Cape Verdi  +238', code: '+238' },
  { description: ' Central African Republic  +236', code: '+236' },
  { description: ' Chad  +235', code: '+235' },
  { description: ' Chile  +56', code: '+56' },
  { description: `China (People's Republic)  +86`, code: '+86' },
  { description: ' China  +886', code: '+886' },
  { description: ' Colombia  +57', code: '+57' },
  { description: ' Comoros and Mayotte  +269', code: '+269' },
  { description: ' Congo  +242', code: '+242' },
  { description: ' Cook Islands  +682', code: '+682' },
  { description: ' Costa Rica  +506', code: '+506' },
  { description: ' Croatia  +385', code: '+385' },
  { description: ' Cuba  +53', code: '+53' },
  { description: ' Cyprus  +357', code: '+357' },
  { description: ' Czech Republic  +420', code: '+420' },
  { description: ' Denmark  +45', code: '+45' },
  { description: ' Diego Garcia  +246', code: '+246' },
  { description: ' Dominca  +767', code: '+767' },
  { description: ' Dominican Republic  +809', code: '+809' },
  { description: ' Djibouti  +253', code: '+253' },
  { description: ' Ecuador  +593', code: '+593' },
  { description: ' Egypt  +20', code: '+20' },
  { description: ' El Salvador  +503', code: '+503' },
  { description: ' Equatorial Guinea  +240', code: '+240' },
  { description: ' Eritrea  +291', code: '+291' },
  { description: ' Estonia  +372', code: '+372' },
  { description: ' Ethiopia  +251', code: '+251' },
  { description: ' Falkland Islands  +500', code: '+500' },
  { description: ' Faroe (Faeroe) Islands (Denmark)  +298', code: '+298' },
  { description: ' Fiji  +679', code: '+679' },
  { description: ' Finland  +358', code: '+358' },
  { description: ' France  +33', code: '+33' },
  { description: ' French Antilles  +596', code: '+596' },
  { description: ' French Guiana  +594', code: '+594' },
  { description: ' Gabon (Gabonese Republic)  +241', code: '+241' },
  { description: ' Gambia  +220', code: '+220' },
  { description: ' Georgia  +995', code: '+995' },
  { description: ' Germany  +49', code: '+49' },
  { description: ' Ghana  +233', code: '+233' },
  { description: ' Gibraltar  +350', code: '+350' },
  { description: ' Greece  +30', code: '+30' },
  { description: ' Greenland  +299', code: '+299' },
  { description: ' Grenada/Carricou  +473', code: '+473' },
  { description: ' Guam  +671', code: '+671' },
  { description: ' Guatemala  +502', code: '+502' },
  { description: ' Guinea  +224', code: '+224' },
  { description: ' Guinea  +245', code: '+245' },
  { description: ' Guyana  +592', code: '+592' },
  { description: ' Haiti  +509', code: '+509' },
  { description: ' Honduras  +504', code: '+504' },
  { description: ' Hong Kong  +852', code: '+852' },
  { description: ' Hungary  +36', code: '+36' },
  { description: ' Iceland  +354', code: '+354' },
  { description: ' India  +91', code: '+91' },
  { description: ' Indonesia  +62', code: '+62' },
  { description: ' Iran  +98', code: '+98' },
  { description: ' Iraq  +964', code: '+964' },
  { description: ' Ireland (Irish Republic; Eire)  +353', code: '+353' },
  { description: ' Israel  +972', code: '+972' },
  { description: ' Italy  +39', code: '+39' },
  { description: ` Ivory Coast (La Cote d'Ivoire)  +225`, code: '+225' },
  { description: ' Jamaica  +876', code: '+876' },
  { description: ' Japan  +81', code: '+81' },
  { description: ' Jordan  +962', code: '+962' },
  { description: ' Kazakhstan  +7', code: '+7' },
  { description: ' Kenya  +254', code: '+254' },
  { description: ' Khmer Republic (Cambodia/Kampuchea)  +855', code: '+855' },
  { description: ' Kiribati Republic (Gilbert Islands)  +686', code: '+686' },
  { description: ' Korea, Republic of (South Korea)  +82', code: '+82' },
  {
    description: ` Korea, People's Republic of (North Korea)  +850`,
    code: '+850'
  },
  { description: ' Kuwait  +965', code: '+965' },
  { description: ' Kyrgyz Republic  +996', code: '+996' },
  { description: ' Latvia  +371', code: '+371' },
  { description: ' Laos  +856', code: '+856' },
  { description: ' Lebanon  +961', code: '+961' },
  { description: ' Lesotho  +266', code: '+266' },
  { description: ' Liberia  +231', code: '+231' },
  { description: ' Lithuania  +370', code: '+370' },
  { description: ' Libya  +218', code: '+218' },
  { description: ' Liechtenstein  +423', code: '+423' },
  { description: ' Luxembourg  +352', code: '+352' },
  { description: ' Macao  +853', code: '+853' },
  { description: ' Macedonia  +389', code: '+389' },
  { description: ' Madagascar  +261', code: '+261' },
  { description: ' Malawi  +265', code: '+265' },
  { description: ' Malaysia  +60', code: '+60' },
  { description: ' Maldives  +960', code: '+960' },
  { description: ' Mali  +223', code: '+223' },
  { description: ' Malta  +356', code: '+356' },
  { description: ' Marshall Islands  +692', code: '+692' },
  { description: ' Martinique (French Antilles)  +596', code: '+596' },
  { description: ' Mauritania  +222', code: '+222' },
  { description: ' Mauritius  +230', code: '+230' },
  { description: ' Mayolte  +269', code: '+269' },
  { description: ' Mexico  +52', code: '+52' },
  { description: ' Micronesia (F.S. of Polynesia)  +691', code: '+691' },
  { description: ' Moldova  +373', code: '+373' },
  { description: ' Monaco  +33', code: '+33' },
  { description: ' Mongolia  +976', code: '+976' },
  { description: ' Montserrat  +473', code: '+473' },
  { description: ' Morocco  +212', code: '+212' },
  { description: ' Mozambique  +258', code: '+258' },
  { description: ' Myanmar (former Burma)  +95', code: '+95' },
  { description: ' Namibia (former South  +264', code: '+264' },
  { description: ' Nauru  +674', code: '+674' },
  { description: ' Nepal  +977', code: '+977' },
  { description: ' Netherlands  +31', code: '+31' },
  { description: ' Netherlands Antilles  +599', code: '+599' },
  { description: ' Nevis  +869', code: '+869' },
  { description: ' New Caledonia  +687', code: '+687' },
  { description: ' New Zealand  +64', code: '+64' },
  { description: ' Nicaragua  +505', code: '+505' },
  { description: ' Niger  +227', code: '+227' },
  { description: ' Nigeria  +234', code: '+234' },
  { description: ' Niue  +683', code: '+683' },
  { description: ' North Korea  +850', code: '+850' },
  { description: ' North Mariana Islands (Saipan)  +1 670 ', code: '+1 670 ' },
  { description: ' Norway  +47', code: '+47' },
  { description: ' Oman  +968', code: '+968' },
  { description: ' Pakistan  +92', code: '+92' },
  { description: ' Palau  +680', code: '+680' },
  { description: ' Panama  +507', code: '+507' },
  { description: ' Papua New Guinea  +675', code: '+675' },
  { description: ' Paraguay  +595', code: '+595' },
  { description: ' Peru  +51', code: '+51' },
  { description: ' Philippines  +63', code: '+63' },
  { description: ' Poland  +48', code: '+48' },
  { description: ' Portugal (includes Azores)  +351', code: '+351' },
  { description: ' Puerto Rico  +1 787 ', code: '+1 787 ' },
  { description: ' Qatar  +974', code: '+974' },
  { description: ' Reunion (France)  +262', code: '+262' },
  { description: ' Romania  +40', code: '+40' },
  { description: ' Russia  +7', code: '+7' },
  { description: ' Rwanda (Rwandese Republic)  +250', code: '+250' },
  { description: ' Saipan  +670', code: '+670' },
  { description: ' San Marino  +378', code: '+378' },
  { description: ' Sao Tome and Principe  +239', code: '+239' },
  { description: ' Saudi Arabia  +966', code: '+966' },
  { description: ' Senegal  +221', code: '+221' },
  { description: ' Serbia and Montenegro  +381', code: '+381' },
  { description: ' Seychelles  +248', code: '+248' },
  { description: ' Sierra Leone  +232', code: '+232' },
  { description: ' Singapore  +65', code: '+65' },
  { description: ' Slovakia  +421', code: '+421' },
  { description: ' Slovenia  +386', code: '+386' },
  { description: ' Solomon Islands  +677', code: '+677' },
  { description: ' Somalia  +252', code: '+252' },
  { description: ' South Africa  +27', code: '+27' },
  { description: ' Spain  +34', code: '+34' },
  { description: ' Sri Lanka  +94', code: '+94' },
  { description: ' St. Helena  +290', code: '+290' },
  { description: ' St. Kitts/Nevis  +869', code: '+869' },
  { description: ' St. Pierre &(et) Miquelon (France)  +508', code: '+508' },
  { description: ' Sudan  +249', code: '+249' },
  { description: ' Suriname  +597', code: '+597' },
  { description: ' Swaziland  +268', code: '+268' },
  { description: ' Sweden  +46', code: '+46' },
  { description: ' Switzerland  +41', code: '+41' },
  { description: ' Syrian Arab Republic (Syria)  +963', code: '+963' },
  { description: ' Tahiti (French Polynesia)  +689', code: '+689' },
  { description: ' Taiwan  +886', code: '+886' },
  { description: ' Tajikistan  +7', code: '+7' },
  { description: ' Tanzania (includes Zanzibar)  +255', code: '+255' },
  { description: ' Thailand  +66', code: '+66' },
  { description: ' Togo (Togolese Republic)  +228', code: '+228' },
  { description: ' Tokelau  +690', code: '+690' },
  { description: ' Tonga  +676', code: '+676' },
  { description: ' Trinidad and Tobago  +1 868 ', code: '+1 868 ' },
  { description: ' Tunisia  +216', code: '+216' },
  { description: ' Turkey  +90', code: '+90' },
  { description: ' Turkmenistan  +993', code: '+993' },
  { description: ' Tuvalu (Ellice Islands)  +688', code: '+688' },
  { description: ' Uganda  +256', code: '+256' },
  { description: ' Ukraine  +380', code: '+380' },
  { description: ' United Arab Emirates  +971', code: '+971' },
  { description: ' United Kingdom  +44', code: '+44' },
  { description: ' Uruguay  +598', code: '+598' },
  { description: ' USA  +1', code: '+1' },
  { description: ' Uzbekistan  +7', code: '+7' },
  { description: ' Vanuatu (New Hebrides)  +678', code: '+678' },
  { description: ' Vatican City  +39', code: '+39' },
  { description: ' Venezuela  +58', code: '+58' },
  { description: ' Viet Nam  +84', code: '+84' },
  { description: ' Virgin Islands  +1 340 ', code: '+1 340 ' },
  { description: ' Wallis and Futuna  +681', code: '+681' },
  { description: ' Western Samoa  +685', code: '+685' },
  {
    description: `Yemen (People's Democratic Republic of)  +381`,
    code: '+381'
  },
  { description: ' Yemen Arab Republic (North Yemen)  +967', code: '+967' },
  { description: ' Yugoslavia (discontinued)  +381', code: '+381' },
  { description: ' Zaire  +243', code: '+243' },
  { description: ' Zambia  +260', code: '+260' },
  { description: ' Zimbabwe  +263', code: '+263' }
];
