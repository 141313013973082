import MuiTextfield, { TextFieldProps } from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { bcrTheme } from 'config/bcrTheme';

/**
 * Sets default variant and size for our app
 * All other props of material textfield and form control are available.
 * @see https://material-ui.com/api/text-field/
 * @see https://material-ui.com/api/form-control/
 */

function TextField(props: Partial<TextFieldProps>) {
  const { variant = 'outlined', size = 'small', ...additionalProps } = props;
  const isUp910 = useMediaQuery(bcrTheme.breakpoints.up(910));
  return (
    <MuiTextfield
      variant={variant}
      size={isUp910 ? 'small' : 'medium'}
      autoComplete="new-password"
      {...additionalProps}
    />
  );
}

export default TextField;
