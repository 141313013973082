import React, { useState } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ControllerTextField from './ControllerTextField';
import ControllerAutocomplete from './ControllerAutocomplete';
import {
  Control,
  FieldValues,
  useFieldArray,
  UseFormGetValues,
  UseFormSetValue
} from 'react-hook-form';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { InternationalCallingCodes } from 'lib/constants';
import { CodeDescription } from 'types';

const useStyles = makeStyles((theme: Theme) => ({
  fields: {
    margin: theme.spacing(1, 0, 0, 0)
  }
}));

const phoneTypeList: string[] = ['Mobile', 'Work', 'Home'];

interface PhoneComponentProps {
  control: Control<FieldValues, any>;
  description: string;
  name: string;
  phoneTypes?: string[];
  growNo: number;
  getValues: UseFormGetValues<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
}

export const PhoneComponent = ({
  control,
  description,
  name,
  phoneTypes = phoneTypeList,
  growNo,
  getValues,
  setValue
}: PhoneComponentProps) => {
  const classes = useStyles();

  const [phoneUpdatedTypes, setPhoneUpdatedTypes] = useState(phoneTypes);

  const { fields, append, remove } = useFieldArray({
    control,
    name
  });

  return (
    <>
      <Grid container item>
        <Grid item xs={12}>
          <Typography variant="body1">{description}</Typography>
        </Grid>
      </Grid>
      {fields.map((item, index) => {
        return (
          <Grid container key={item.id}>
            <Grid container item spacing={2} className={classes.fields}>
              <Grid item xs={12} md={3} xl={3}>
                <ControllerAutocomplete
                  name={`${name}[${index}].phoneTypeCode`}
                  label="Phone Type"
                  options={phoneUpdatedTypes}
                  getOptionLabel={(option: string) => option}
                  getOptionSelected={(option, selected) => option === selected}
                  onChange={(e, value) => {
                    // when change, dynamically reset the phone type dropdown list
                    setValue(`${name}[${index}].phoneTypeCode`, value);

                    let perviousFirstValue = null;
                    if (index - 1 >= 0) {
                      perviousFirstValue = getValues(
                        `${name}[${index - 1}].phoneTypeCode`
                      );
                    }

                    let perviouseSecondValue = null;
                    if (index - 2 >= 0) {
                      perviouseSecondValue = getValues(
                        `${name}[${index - 2}].phoneTypeCode`
                      );
                    }

                    setPhoneUpdatedTypes(
                      phoneTypes.filter(
                        (t) =>
                          t !== value &&
                          t !== perviousFirstValue &&
                          t !== perviouseSecondValue
                      )
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} xl={6}>
                <ControllerAutocomplete
                  name={`${name}[${index}].countryCode`}
                  label="Country Code"
                  placeholder="Please select a country"
                  options={
                    InternationalCallingCodes || [{ description: '', code: '' }]
                  }
                  getOptionLabel={(option: CodeDescription) =>
                    option.description || ''
                  }
                  getOptionSelected={(option, selected) =>
                    option?.code === selected?.code
                  }
                  onChange={(e, value: CodeDescription) => {
                    setValue(`${name}[${index}].countryCode`, value);
                  }}
                />
              </Grid>
              <Box display="none">
                <ControllerTextField
                  name={`${name}[${index}].areaCode`}
                  label="Area Code"
                  defaultValue={''}
                  disabled
                  hidden
                />
              </Box>
              <Grid item xs={12} md={3} xl={3}>
                <ControllerTextField
                  name={`${name}[${index}].number`}
                  label="Number"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={3} xl={3}>
                {index >= 1 ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    data-testid="NZ-Phone-Delete-button"
                    startIcon={<RemoveIcon />}
                    onClick={() => {
                      //dynamically reset the phone type dropdown list
                      const removedValue = getValues(
                        `${name}[${index}].phoneTypeCode`
                      );
                      const currentValue = [...phoneUpdatedTypes];
                      currentValue.push(removedValue);
                      setPhoneUpdatedTypes(currentValue);

                      //remove function
                      remove(index);
                    }}
                  >
                    Delete
                  </Button>
                ) : undefined}
              </Grid>
            </Grid>
          </Grid>
        );
      })}
      {fields.length <= growNo - 1 ? (
        <Grid container item spacing={1} className={classes.fields}>
          <Grid item xs={12} md={5} xl={5}>
            <Button
              variant="contained"
              color="secondary"
              data-testid="Add-NZ-Phone-button"
              startIcon={<AddIcon />}
              onClick={() => {
                append({});
              }}
            >
              Add Another Phone Number
            </Button>
          </Grid>
        </Grid>
      ) : undefined}
    </>
  );
};
