import { Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import InfoIcon from '@material-ui/icons/Info';
import ControllerTextField from 'components/ControllerTextField';
import BCRTooltip from 'components/BCRTooltip';
import {
  AddressComponent,
  addressDefaultValues
} from 'components/AddressComponent';
import { useFormContext } from 'react-hook-form';
import { CodeDescription } from 'types';
import * as yup from 'yup';
import '../lib/yupOpetional';
import {
  ALPHABETS_NUMBERS_HYPHENS,
  ALPHABETS_NUMBERS_HYPHENS_MATCHES,
  MAX_LONG_STRING_LENGTH,
  MAX_LONG_STRING_LENGTH_WARNING
} from 'lib/yupConstants';

const useStyles = makeStyles((theme: Theme) => ({
  fields: {
    margin: theme.spacing(1, 0, 0, 0)
  },
  sectionHeader: {
    margin: theme.spacing(1, 5, 0, 0)
  },
  cardPadding: {
    margin: theme.spacing(2, 0, 0, 0),
    padding: theme.spacing(2, 0)
  },
  tooltipInfo: {
    color: theme.palette.customs.lightBlue
  },
  divider: {
    marginBottom: theme.spacing(0),
    marginTop: '4px',
    borderTop: `1px solid ${theme.palette.primary.main}`,
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    padding: 0
  }
}));

export const form3BNIDetailsFormDefaultValues = () => ({
  cash: [
    {
      nameOfIssuerOrDrawer: '',
      countryOfIssuerOrDrawer: '',
      addressOfIssuerOrDrawer: addressDefaultValues,
      nameOfPayeeOrBeneficiary: '',
      countryOfPayeeOrBeneficiary: '',
      addressOfPayeeOrBeneficiary: addressDefaultValues
    }
  ]
});

const PAYEE_BENEFICIARY_TOOLTIP_TEXT = (
  <Typography variant="body1" color="inherit">
    <strong>Payee or beneficiary</strong> is the person or company that is
    receiving the funds from the BNI
  </Typography>
);

const ISSUER_DRAWER_TOOLTIP_TEXT = (
  <Typography variant="body1" color="inherit">
    <strong>Issuer or drawer</strong> is the person, business, or organisation
    that the BNI is written out to
  </Typography>
);

const addressOfPayeeOrBeneficiary = {
  unit: yup
    .string()
    .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
    .matches(ALPHABETS_NUMBERS_HYPHENS_MATCHES, `${ALPHABETS_NUMBERS_HYPHENS}`)
    .nullable()
    .optional(),
  floor: yup
    .string()
    .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
    .matches(ALPHABETS_NUMBERS_HYPHENS_MATCHES, `${ALPHABETS_NUMBERS_HYPHENS}`)
    .nullable()
    .optional(),
  number: yup
    .string()
    .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
    .matches(ALPHABETS_NUMBERS_HYPHENS_MATCHES, `${ALPHABETS_NUMBERS_HYPHENS}`)
    .nullable(),
  streetName: yup
    .string()
    .matches(ALPHABETS_NUMBERS_HYPHENS_MATCHES, `${ALPHABETS_NUMBERS_HYPHENS}`)
    .nullable(),
  streetType: yup
    .object()
    .shape({
      code: yup.string(),
      description: yup.string()
    })
    .nullable(),
  suburb: yup
    .string()
    .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
    .matches(ALPHABETS_NUMBERS_HYPHENS_MATCHES, `${ALPHABETS_NUMBERS_HYPHENS}`)
    .nullable(),
  city: yup
    .string()
    .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
    .matches(ALPHABETS_NUMBERS_HYPHENS_MATCHES, `${ALPHABETS_NUMBERS_HYPHENS}`)
    .nullable(),
  postcode: yup
    .string()
    .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
    .matches(ALPHABETS_NUMBERS_HYPHENS_MATCHES, `${ALPHABETS_NUMBERS_HYPHENS}`)
    .nullable(),
  state: yup
    .string()
    .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
    .matches(ALPHABETS_NUMBERS_HYPHENS_MATCHES, `${ALPHABETS_NUMBERS_HYPHENS}`)
    .nullable()
    .optional(),
  country: yup
    .object()
    .shape({
      code: yup.string(),
      description: yup.string()
    })
    .nullable()
};

export const Form3BNIDetailsFormSchema = (hasBNI: number) => {
  if (hasBNI > 0) {
    return yup.object().shape({
      bni: yup
        .array()
        .of(
          yup.object().shape({
            nameOfIssuerOrDrawer: yup
              .string()
              .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
              .matches(
                ALPHABETS_NUMBERS_HYPHENS_MATCHES,
                `${ALPHABETS_NUMBERS_HYPHENS}`
              )
              .nullable()
              .required('This is a required field')
              .trim()
              .min(1),
            nameOfPayeeOrBeneficiary: yup
              .string()
              .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
              .matches(
                // /^([a-zA-Z\xC0-\uFFFF\s]{1,60}[ \-\']{0,1}){1,3}$/,
                ALPHABETS_NUMBERS_HYPHENS_MATCHES,
                `${ALPHABETS_NUMBERS_HYPHENS}`
              )
              .nullable()
              .required('This is a required field')
              .trim()
              .min(1),
            addressOfIssuerOrDrawer: yup.object().shape({
              unit: yup
                .string()
                .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
                .matches(
                  ALPHABETS_NUMBERS_HYPHENS_MATCHES,
                  `${ALPHABETS_NUMBERS_HYPHENS}`
                )
                .nullable()
                .optional(),
              floor: yup
                .string()
                .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
                .matches(
                  ALPHABETS_NUMBERS_HYPHENS_MATCHES,
                  `${ALPHABETS_NUMBERS_HYPHENS}`
                )
                .nullable()
                .optional(),
              number: yup
                .string()
                .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
                .matches(
                  ALPHABETS_NUMBERS_HYPHENS_MATCHES,
                  `${ALPHABETS_NUMBERS_HYPHENS}`
                )
                .nullable()
                .required('This is a required field')
                .trim()
                .min(1),
              streetName: yup
                .string()
                .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
                .matches(
                  ALPHABETS_NUMBERS_HYPHENS_MATCHES,
                  `${ALPHABETS_NUMBERS_HYPHENS}`
                )
                .nullable()
                .required('This is a required field')
                .trim()
                .min(1),
              streetType: yup
                .object()
                .shape({
                  code: yup.string().required('This is a required field'),
                  description: yup.string().required('This is a required field')
                })
                .nullable()
                .required('This is a required field'),
              suburb: yup
                .string()
                .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
                .matches(
                  ALPHABETS_NUMBERS_HYPHENS_MATCHES,
                  `${ALPHABETS_NUMBERS_HYPHENS}`
                )
                .nullable()
                .required('This is a required field')
                .trim()
                .min(1),
              city: yup
                .string()
                .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
                .matches(
                  ALPHABETS_NUMBERS_HYPHENS_MATCHES,
                  `${ALPHABETS_NUMBERS_HYPHENS}`
                )
                .required('This is a required field')
                .trim()
                .min(1),
              postcode: yup
                .string()
                .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
                .matches(
                  ALPHABETS_NUMBERS_HYPHENS_MATCHES,
                  `${ALPHABETS_NUMBERS_HYPHENS}`
                )
                .nullable()
                .required('This is a required field')
                .trim()
                .min(1),
              state: yup
                .string()
                .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
                .matches(
                  ALPHABETS_NUMBERS_HYPHENS_MATCHES,
                  `${ALPHABETS_NUMBERS_HYPHENS}`
                )
                .nullable()
                .optional(),
              country: yup
                .object()
                .shape({
                  code: yup
                    .string()
                    .nullable()
                    .required('This is a required field'),
                  description: yup
                    .string()
                    .nullable()
                    .required('This is a required field')
                })
                .nullable()
                .required('This is a required field')
            }),
            addressOfPayeeOrBeneficiary: yup
              .object(addressOfPayeeOrBeneficiary)
              .optional()
              .default(undefined)
          })
        )
        .compact()
    });
  } else {
    return yup.object({}).notRequired();
  }
};
interface Form3Props {
  countryCodes: CodeDescription[];
  streetTypes: CodeDescription[];
}

export const Form3BNIDetailsForm = ({
  countryCodes,
  streetTypes
}: Form3Props) => {
  const classes = useStyles();
  const { watch } = useFormContext();
  const cash = watch('form2.cash');

  let bniOnly = null;
  bniOnly = cash.filter(
    (item) =>
      item.typeOfCash !== null &&
      item.typeOfCash?.code !== 'physicalCash' &&
      item.typeOfCash?.code !== 'other' &&
      item.typeOfCash?.code !== ''
  );

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.sectionHeader}>
            Information about BNI being moved or received
          </Typography>
        </Grid>

        <Grid container item className={classes.fields}>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.sectionHeader}>
              You have declared that you are moving or receiving a
              bearer-negotiable instrument (BNI) (i.e. bearer bond, bill of
              exchange, cheque, money order, postal order, or similar order,
              promissory note, or traveller’s cheque). Please fill in the
              following information for the:
            </Typography>
          </Grid>
        </Grid>
        {bniOnly?.map((item, index) => {
          return (
            <Grid container key={index}>
              <Grid container item className={classes.fields}>
                <Typography variant="h6" className={classes.sectionHeader}>
                  <strong>{item?.typeOfCash?.description}</strong> valued at{' '}
                  <strong>{item?.amount}</strong>{' '}
                  <strong>{item?.currencyName?.description}:</strong>
                </Typography>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={11}>
                  <ControllerTextField
                    name={`form3.bni[${index}].nameOfIssuerOrDrawer`}
                    label="Full Name of Issuer or Drawer"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={1}>
                  <BCRTooltip title={ISSUER_DRAWER_TOOLTIP_TEXT}>
                    <InfoIcon
                      fontSize="small"
                      className={classes.tooltipInfo}
                    />
                  </BCRTooltip>
                </Grid>
                <Grid item xs={12}>
                  <AddressComponent
                    description="Address of the Issuer or Drawer:"
                    name={`form3.bni[${index}].addressOfIssuerOrDrawer`}
                    countryCodes={countryCodes}
                    streetTypes={streetTypes}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={11}>
                  <ControllerTextField
                    name={`form3.bni[${index}].nameOfPayeeOrBeneficiary`}
                    label="Full Name of the Payee or Beneficiary"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={1}>
                  <BCRTooltip title={PAYEE_BENEFICIARY_TOOLTIP_TEXT}>
                    <InfoIcon
                      fontSize="small"
                      className={classes.tooltipInfo}
                    />
                  </BCRTooltip>
                </Grid>
                <Grid item xs={12}>
                  <AddressComponent
                    description="Address of the Payee or Beneficiary (if specified):"
                    name={`form3.bni[${index}].addressOfPayeeOrBeneficiary`}
                    countryCodes={countryCodes}
                    streetTypes={streetTypes}
                  />
                </Grid>
                <Grid item xs={12} className={classes.fields}>
                  {/* <Divider /> */}
                  <hr className={classes.divider} />
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
