import React, { ReactNode } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

/*---------------------------------
CUSTOM STYLES - MUI
-----------------------------------*/
const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: 'none',
    transition: 'opacity 0.3s ease',
    '&:hover': {
      opacity: '0.8',
      transition: 'opacity 0.3s ease'
    },
    '& svg': {
      width: '30px'
    },
    '& span': {
      fontWeight: '600',
      letterSpacing: '0.2px'
    }
  },
  outlined: {
    border: '2px solid'
  },
  fillBlue: {
    backgroundColor: theme.palette.customs.mainBlue,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.customs.mainBlue
    }
  },
  fillSecondaryBlue: {
    backgroundColor: theme.palette.customs.lightBlue,
    color: theme.palette.customs.mainBlue,
    '&:hover': {
      backgroundColor: theme.palette.customs.lightBlue
    }
  },
  fillWhite: {
    backgroundColor: 'white',
    color: theme.palette.customs.mainBlue,
    '&:hover': {
      backgroundColor: 'white'
    }
  },
  sizeNormal: {
    padding: theme.spacing(2, 5),
    fontSize: '1.1rem',
    lineHeight: '1.3',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem',
      padding: theme.spacing(2, 3)
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem'
    }
  },
  sizeSmall: {
    padding: theme.spacing(1, 3),
    fontSize: '0.9rem',
    lineHeight: '1.2',
    '& svg': {
      width: '24px'
    },
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(1, 2)
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem'
    }
  }
}));

/*---------------------------------
TYPES
-----------------------------------*/
interface CustomButtonProps {
  text: string;
  variant?: 'text' | 'outlined' | 'contained';
  buttonColor?: 'fillBlue' | 'fillSecondaryBlue' | 'fillWhite';
  buttonSize?: 'sizeNormal' | 'sizeSmall';
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  handleClick?: () => void;
  classNames?: string;
}

/*---------------------------------
 EXPORT COMPONENT
 -----------------------------------*/
const CustomButton = ({
  text = '',
  variant = 'contained',
  buttonColor = 'fillBlue',
  buttonSize = 'sizeNormal',
  iconLeft,
  iconRight,
  handleClick = () => {},
  classNames = ''
}: CustomButtonProps) => {
  const classes = useStyles();

  return (
    <Button
      variant={variant}
      startIcon={iconLeft}
      endIcon={iconRight}
      className={`${classNames} ${classes[buttonColor]} ${classes[buttonSize]}`}
      classes={{ outlined: classes.outlined, root: classes.root }}
      disableElevation
      onClick={handleClick}
    >
      {text}
    </Button>
  );
};

export default CustomButton;
