import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Grid, useMediaQuery, LinearProgress } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useFormContext, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import { postBCR } from 'api/postBCR';
import omit from 'lodash/omit';
import Recaptcha from 'react-google-invisible-recaptcha';
import Notes from 'forms/Notes';
import moment from 'moment-timezone';
import { bcrTheme } from 'config/bcrTheme';

const useStyles = makeStyles((theme: Theme) => ({
  pageSteps: {
    margin: theme.spacing(1, 0, 0, 0),
    [theme.breakpoints.down(281)]: {
      fontSize: '0.875rem',
      letterSpacing: '0.25px',
      margin: theme.spacing(1, 0, 0, 4)
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.875rem',
      letterSpacing: '0.25px',
      margin: theme.spacing(1, 0, 0, 1)
    },
    [theme.breakpoints.up('md')]: {
      textTransform: 'none',
      fontSize: '1.25rem',
      letterSpacing: '0.25px'
    },
    [theme.breakpoints.up('xl')]: {
      textTransform: 'none',
      fontSize: '1.25rem',
      letterSpacing: '0.25px'
    }
  },
  fields: {
    margin: theme.spacing(6, 2, 0, 0)
  },
  recaptcha: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
      alignItems: 'flex-end'
    }
  },
  progress: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(3)
  }
}));

export const pageList = [
  'Form1RegOverview',
  'Form2DeclareCashItem',
  'Form3BNIDetailsForm',
  'Form4PersonalInfo',
  'Form5NZContactInfo',
  'Form6NonNZContactInfo',
  'Form7CashMovement',
  'Form8CargoImportInfo',
  'Form9CargoExportInfo',
  'Form10MailImportInfo',
  'Form11MailExportInfo',
  'Form12InPersonArrival',
  'Form13InPersonDeparture',
  'Form14InfoOnCash',
  'Form15InfoOnCashBehalf',
  'Form16InfoOnCashDeliverTo',
  // 'Form17InfoOnBehalfPerson', //This two forms moved to Form15 and Form16
  // 'Form18InfoOnBehalfCompany',
  'Form19SubmitDeclaration',
  'Form20Signature'
];

const convertCodeDescriptionAddressToBcrAddress = (address) => {
  return {
    ...address,
    streetType: address?.streetType?.code,
    country: address?.country?.code
  };
};

export const PageSteps = ({ setPage, pageNumber }) => {
  const classes = useStyles();
  const {
    control,
    handleSubmit,
    trigger,
    clearErrors,
    watch,

    formState: { errors }
  } = useFormContext();
  const history = useHistory();

  const [progress, setProgress] = useState(pageNumber);

  const reCaptchaRef = useRef(null);

  const cash = useWatch({ control, name: 'form2.cash' });

  let bniOnly = null;
  bniOnly = cash.filter(
    (item) =>
      item.typeOfCash !== null &&
      item.typeOfCash?.code !== 'physicalCash' &&
      item.typeOfCash?.code !== 'other' &&
      item.typeOfCash?.code !== ''
  );

  const nzResident = useWatch({ control, name: `form4.cashOwner.nzResident` });
  let isNzResident = null;
  isNzResident = nzResident === 'yes';

  const cashMovementMethod = useWatch({
    control,
    name: 'form7.cashMovementMethod'
  });
  const cashMovementDirection = useWatch({
    control,
    name: 'form7.cashMovementDirection'
  });
  let isCargo = cashMovementMethod === 'cargo';
  let isMail = cashMovementMethod === 'mail';
  let isInPerson = cashMovementMethod === 'inPerson';
  let isArrival = !!(
    cashMovementDirection?.code === 'bringingIn' ||
    cashMovementDirection?.code === 'receiving'
  );

  // console.log('cashMovementDirection', cashMovementDirection);

  const movingCashOnBehalf = useWatch({
    control,
    name: 'form14.movingCashOnBehalf'
  });
  let isOnBehalf = movingCashOnBehalf === 'yes';
  const deliveringCashToCompanyOrPerson = useWatch({
    control,
    name: 'form14.deliveringCashToCompanyOrPerson'
  });
  let isDeliver = deliveringCashToCompanyOrPerson === 'yes';

  let onBehalfOf;
  onBehalfOf = useWatch({ control, name: 'form15.onBehalfOf' });
  let isOnBehalfOfPerson = null;
  isOnBehalfOfPerson = onBehalfOf === 'person';
  let isOnBehalfOfCompany = null;
  isOnBehalfOfCompany = onBehalfOf === 'company';

  let deliveringTo = null;
  deliveringTo = useWatch({ control, name: 'form16.deliveringTo' });
  let isDeliverToPerson = null;
  isDeliverToPerson = deliveringTo === 'person';
  let isDeliverToCompany = null;
  isDeliverToCompany = deliveringTo === 'company';

  let isOtherCapacity = null;
  const capacity = watch('form19.capacityCode');

  isOtherCapacity = capacity?.code === 'other';

  const handlePreClick = () => {
    window.scrollTo({ top: 0, left: 0 });
    clearErrors();
    if (pageList[pageNumber] === 'Form4PersonalInfo' && bniOnly?.length === 0) {
      setPage((currentState) => ({
        ...currentState,
        name: 'Form2DeclareCashItem',
        number: pageList.indexOf('Form2DeclareCashItem')
      }));
    } else if (
      pageList[pageNumber] === 'Form6NonNZContactInfo' &&
      !isNzResident === true
    ) {
      setPage((currenState) => ({
        ...currenState,
        name: 'Form4PersonalInfo',
        number: pageList.indexOf('Form4PersonalInfo')
      }));
    } else if (
      pageList[pageNumber] === 'Form7CashMovement' &&
      isNzResident === true
    ) {
      setPage((currenState) => ({
        ...currenState,
        name: 'Form5NZContactInfo',
        number: pageList.indexOf('Form5NZContactInfo')
      }));
    } else if (
      pageList[pageNumber] === 'Form7CashMovement' &&
      !isNzResident === true
    ) {
      setPage((currenState) => ({
        ...currenState,
        name: 'Form6NonNZContactInfo',
        number: pageList.indexOf('Form6NonNZContactInfo')
      }));
    } else if (
      pageList[pageNumber] === 'Form9CargoExportInfo' ||
      pageList[pageNumber] === 'Form10MailImportInfo' ||
      pageList[pageNumber] === 'Form11MailExportInfo' ||
      pageList[pageNumber] === 'Form12InPersonArrival' ||
      pageList[pageNumber] === 'Form13InPersonDeparture'
    ) {
      setPage((currenState) => ({
        ...currenState,
        name: 'Form7CashMovement',
        number: pageList.indexOf('Form7CashMovement')
      }));
    } else if (
      pageList[pageNumber] === 'Form14InfoOnCash' &&
      isInPerson === true &&
      isArrival === true
    ) {
      setPage((currenState) => ({
        ...currenState,
        name: 'Form12InPersonArrival',
        number: pageList.indexOf('Form12InPersonArrival')
      }));
    } else if (
      pageList[pageNumber] === 'Form14InfoOnCash' &&
      isMail === true &&
      isArrival === false
    ) {
      setPage((currenState) => ({
        ...currenState,
        name: 'Form11MailExportInfo',
        number: pageList.indexOf('Form11MailExportInfo')
      }));
    } else if (
      pageList[pageNumber] === 'Form14InfoOnCash' &&
      isMail === true &&
      isArrival === true
    ) {
      setPage((currenState) => ({
        ...currenState,
        name: 'Form10MailImportInfo',
        number: pageList.indexOf('Form10MailImportInfo')
      }));
    } else if (
      pageList[pageNumber] === 'Form14InfoOnCash' &&
      isCargo === true &&
      isArrival === false
    ) {
      setPage((currenState) => ({
        ...currenState,
        name: 'Form9CargoExportInfo',
        number: pageList.indexOf('Form9CargoExportInfo')
      }));
    } else if (
      pageList[pageNumber] === 'Form14InfoOnCash' &&
      isCargo === true &&
      isArrival === true
    ) {
      setPage((currenState) => ({
        ...currenState,
        name: 'Form8CargoImportInfo',
        number: pageList.indexOf('Form8CargoImportInfo')
      }));
    } else if (
      (pageList[pageNumber] === 'Form19SubmitDeclaration' &&
        isOnBehalf === false &&
        isDeliver === false) ||
      (pageList[pageNumber] === 'Form16InfoOnCashDeliverTo' &&
        isOnBehalf === false &&
        isDeliver === true)
    ) {
      setPage((currenState) => ({
        ...currenState,
        name: 'Form14InfoOnCash',
        number: pageList.indexOf('Form14InfoOnCash')
      }));
    } else if (
      pageList[pageNumber] === 'Form19SubmitDeclaration' &&
      isOnBehalf === true &&
      isDeliver === false
    ) {
      setPage((currenState) => ({
        ...currenState,
        name: 'Form15InfoOnCashBehalf',
        number: pageList.indexOf('Form15InfoOnCashBehalf')
      }));
    } else if (
      pageList[pageNumber] === 'Form19SubmitDeclaration' &&
      isOnBehalf === false &&
      isDeliver === true
    ) {
      setPage((currenState) => ({
        ...currenState,
        name: 'Form16InfoOnCashDeliverTo',
        number: pageList.indexOf('Form16InfoOnCashDeliverTo')
      }));
    } else {
      setPage((currentState) => ({
        ...currentState,
        name: pageList[pageNumber - 1],
        number: pageNumber - 1
      }));
    }
  };

  const handleNextClick = async () => {
    window.scrollTo({ top: 0, left: 0 });
    if (pageList[pageNumber] === 'Form1RegOverview') {
      setPage((currenState) => ({
        ...currenState,
        name: 'Form2DeclareCashItem',
        number: pageList.indexOf('Form2DeclareCashItem')
      }));
    } else if (pageList[pageNumber] === 'Form2DeclareCashItem') {
      await Promise.all(
        cash.map(async (c, i) => {
          if (
            c.typeOfCash?.code === 'other' &&
            c.currencyName?.code === 'Other'
          ) {
            await trigger([
              `form2.cash[${i}].otherTypeOfCash`,
              `form2.cash[${i}].otherCurrencyName`
            ]);
          } else if (c.typeOfCash?.code === 'other') {
            await trigger([`form2.cash[${i}].otherTypeOfCash`]);
          } else if (c.currencyName?.code === 'Other') {
            await trigger([`form2.cash[${i}].otherCurrencyName`]);
          }

          await trigger([
            `form2.cash[${i}].typeOfCash`,
            `form2.cash[${i}].currencyName`,
            `form2.cash[${i}].amount`,
            `form2.cash[${i}].exchangeRate`
          ]);
        })
      );

      if (isEmpty(errors)) {
        if (bniOnly?.length > 0) {
          setPage((currenState) => ({
            ...currenState,
            name: 'Form3BNIDetailsForm',
            number: pageList.indexOf('Form3BNIDetailsForm')
          }));
        }

        if (bniOnly?.length === 0) {
          setPage((currenState) => ({
            ...currenState,
            name: 'Form4PersonalInfo',
            number: pageList.indexOf('Form4PersonalInfo')
          }));
        }
      }
    } else if (pageList[pageNumber] === 'Form3BNIDetailsForm') {
      await Promise.all(
        bniOnly?.map(async (item, i) => {
          await trigger([
            `form3.bni[${i}].nameOfIssuerOrDrawer`,
            `form3.bni[${i}].addressOfIssuerOrDrawer`,
            `form3.bni[${i}].nameOfPayeeOrBeneficiary`,
            `form3.bni[${i}].addressOfPayeeOrBeneficiary`
          ]);
        })
      );
      if (isEmpty(errors)) {
        setPage((currenState) => ({
          ...currenState,
          name: 'Form4PersonalInfo',
          number: pageList.indexOf('Form4PersonalInfo')
        }));
      }
    } else if (pageList[pageNumber] === 'Form4PersonalInfo') {
      if (isNzResident === true) {
        await trigger([
          `form4.cashOwner.nzResident`,
          `form4.cashOwner.givenNames`,
          `form4.cashOwner.familyName`,
          `form4.cashOwner.gender`,
          `form4.cashOwner.dateOfBirth`,
          `form4.cashOwner.occupation`
        ]);

        if (isEmpty(errors)) {
          setPage((currenState) => ({
            ...currenState,
            name: 'Form5NZContactInfo',
            number: pageList.indexOf('Form5NZContactInfo')
          }));
        }
      } else {
        await trigger([
          `form4.cashOwner.nzResident`,
          `form4.cashOwner.citizenship`,
          `form4.cashOwner.givenNames`,
          `form4.cashOwner.familyName`,
          `form4.cashOwner.gender`,
          `form4.cashOwner.dateOfBirth`,
          `form4.cashOwner.occupation`
        ]);
        if (isEmpty(errors)) {
          setPage((currenState) => ({
            ...currenState,
            name: 'Form6NonNZContactInfo',
            number: pageList.indexOf('Form6NonNZContactInfo')
          }));
        }
      }
    } else if (pageList[pageNumber] === 'Form5NZContactInfo') {
      await trigger([
        `form5.cashOwner.address`,
        `form5.cashOwner.phones`,
        `form5.cashOwner.emailAddress`,
        `form5.cashOwner.confirmEmailAddress`
      ]);
      if (isEmpty(errors)) {
        setPage((currenState) => ({
          ...currenState,
          name: 'Form7CashMovement',
          number: pageList.indexOf('Form7CashMovement')
        }));
      }
    } else if (pageList[pageNumber] === 'Form6NonNZContactInfo') {
      await trigger([
        `form6.cashOwner.address`,
        `form6.cashOwner.phones`,
        `form6.cashOwner.emailAddress`,
        `form6.cashOwner.confirmEmailAddress`
      ]);
      if (isEmpty(errors)) {
        setPage((currenState) => ({
          ...currenState,
          name: 'Form7CashMovement',
          number: pageList.indexOf('Form7CashMovement')
        }));
      }
    } else if (pageList[pageNumber] === 'Form7CashMovement') {
      await trigger([
        `form7.cashMovementMethod`,
        `form7.cashMovementDirection`
      ]);
      if (isEmpty(errors)) {
        if (isCargo === true && isArrival === true) {
          setPage((currenState) => ({
            ...currenState,
            name: 'Form8CargoImportInfo',
            number: pageList.indexOf('Form8CargoImportInfo')
          }));
        }

        if (isCargo === true && isArrival === false) {
          setPage((currenState) => ({
            ...currenState,
            name: 'Form9CargoExportInfo',
            number: pageList.indexOf('Form9CargoExportInfo')
          }));
        }

        if (isMail === true && isArrival === true) {
          setPage((currenState) => ({
            ...currenState,
            name: 'Form10MailImportInfo',
            number: pageList.indexOf('Form10MailImportInfo')
          }));
        }

        if (isMail === true && isArrival === false) {
          setPage((currenState) => ({
            ...currenState,
            name: 'Form11MailExportInfo',
            number: pageList.indexOf('Form11MailExportInfo')
          }));
        }

        if (isInPerson === true && isArrival === true) {
          setPage((currenState) => ({
            ...currenState,
            name: 'Form12InPersonArrival',
            number: pageList.indexOf('Form12InPersonArrival')
          }));
        }

        if (isInPerson === true && isArrival === false) {
          setPage((currenState) => ({
            ...currenState,
            name: 'Form13InPersonDeparture',
            number: pageList.indexOf('Form13InPersonDeparture')
          }));
        }
      }
    } else if (pageList[pageNumber] === 'Form8CargoImportInfo') {
      await trigger([
        `form8.airOrSea`,
        `form8.flightNumberOrVesselName`,
        `form8.nzCustomsNumber`,
        `form8.overseasPort`,
        `form8.countryFrom`,
        `form8.nzPort`,
        `form8.dateOfArrivalOrDeparture`
      ]);
      if (isEmpty(errors)) {
        setPage((currenState) => ({
          ...currenState,
          name: 'Form14InfoOnCash',
          number: pageList.indexOf('Form14InfoOnCash')
        }));
      }
    } else if (pageList[pageNumber] === 'Form9CargoExportInfo') {
      await trigger([
        `form9.airOrSea`,
        `form9.flightNumberOrVesselName`,
        `form9.nzCustomsNumber`,
        `form9.overseasPort`,
        `form9.countryTo`,
        `form9.nzPort`,
        `form9.dateOfArrivalOrDeparture`
      ]);
      if (isEmpty(errors)) {
        setPage((currenState) => ({
          ...currenState,
          name: 'Form14InfoOnCash',
          number: pageList.indexOf('Form14InfoOnCash')
        }));
      }
    } else if (pageList[pageNumber] === 'Form10MailImportInfo') {
      await trigger([`form10.parcelTrackingNumber`, `form10.countryFrom`]);
      if (isEmpty(errors)) {
        setPage((currenState) => ({
          ...currenState,
          name: 'Form14InfoOnCash',
          number: pageList.indexOf('Form14InfoOnCash')
        }));
      }
    } else if (pageList[pageNumber] === 'Form11MailExportInfo') {
      await trigger([`form11.parcelTrackingNumber`, `form11.countryTo`]);
      if (isEmpty(errors)) {
        setPage((currenState) => ({
          ...currenState,
          name: 'Form14InfoOnCash',
          number: pageList.indexOf('Form14InfoOnCash')
        }));
      }
    } else if (pageList[pageNumber] === 'Form12InPersonArrival') {
      await trigger([
        `form12.cashOwner.passportNumber`,
        `form12.airOrSea`,
        `form12.flightNumberOrVesselName`,
        `form12.dateOfArrivalOrDeparture`,
        `form12.overseasPort`,
        `form12.countryFrom`,
        `form12.nzPort`,
        `form12.hasTransitStops`,
        `form12.transitCountries`
      ]);
      if (isEmpty(errors)) {
        setPage((currenState) => ({
          ...currenState,
          name: 'Form14InfoOnCash',
          number: pageList.indexOf('Form14InfoOnCash')
        }));
      }
    } else if (pageList[pageNumber] === 'Form13InPersonDeparture') {
      await trigger([
        `form13.cashOwner.passportNumber`,
        `from13.airOrSea`,
        `form13.flightNumberOrVesselName`,
        `form13.dateOfArrivalOrDeparture`,
        `form13.overseasPort`,
        `form13.countryTo`,
        `form13.nzPort`,
        `form13.hasTransitStops`,
        `form13.transitCountries`
      ]);
      if (isEmpty(errors)) {
        setPage((currenState) => ({
          ...currenState,
          name: 'Form14InfoOnCash',
          number: pageList.indexOf('Form14InfoOnCash')
        }));
      }
    } else if (pageList[pageNumber] === 'Form14InfoOnCash') {
      await trigger([
        `form14.moneyFrom`,
        `form14.reasonForCarryingCash`,
        `form14.moneyUsedFor`,
        `form14.movingCashOnBehalf`,
        `form14.deliveringCashToCompanyOrPerson`
      ]);

      if (isEmpty(errors)) {
        if (isOnBehalf === false && isDeliver === true) {
          setPage((currenState) => ({
            ...currenState,
            name: 'Form16InfoOnCashDeliverTo',
            number: pageList.indexOf('Form16InfoOnCashDeliverTo')
          }));
        }

        if (isOnBehalf === false && isDeliver === false) {
          setPage((currenState) => ({
            ...currenState,
            name: 'Form19SubmitDeclaration',
            number: pageList.indexOf('Form19SubmitDeclaration')
          }));
        }

        if (isOnBehalf === true) {
          setPage((currenState) => ({
            ...currenState,
            name: 'Form15InfoOnCashBehalf',
            number: pageList.indexOf('Form15InfoOnCashBehalf')
          }));
        }
      }
    } else if (pageList[pageNumber] === 'Form15InfoOnCashBehalf') {
      await trigger([`form15.onBehalfOf`]);

      if (isEmpty(errors)) {
        if (isOnBehalfOfPerson) {
          await trigger([`form15.movingCashOnBehalfOf.person`]);
          if (isEmpty(errors)) {
            if (isDeliver) {
              setPage((currenState) => ({
                ...currenState,
                name: 'Form16InfoOnCashDeliverTo',
                number: pageList.indexOf('Form16InfoOnCashDeliverTo')
              }));
            } else {
              setPage((currenState) => ({
                ...currenState,
                name: 'Form19SubmitDeclaration',
                number: pageList.indexOf('Form19SubmitDeclaration')
              }));
            }
          }
        }

        if (isOnBehalfOfCompany) {
          await trigger([`form15.movingCashOnBehalfOf.organisation`]);
          if (isEmpty(errors)) {
            if (isDeliver) {
              setPage((currenState) => ({
                ...currenState,
                name: 'Form16InfoOnCashDeliverTo',
                number: pageList.indexOf('Form16InfoOnCashDeliverTo')
              }));
            } else {
              setPage((currenState) => ({
                ...currenState,
                name: 'Form19SubmitDeclaration',
                number: pageList.indexOf('Form19SubmitDeclaration')
              }));
            }
          }
        }
      }
    } else if (pageList[pageNumber] === 'Form16InfoOnCashDeliverTo') {
      await trigger([`form16.deliveringTo`]);

      if (isEmpty(errors)) {
        if (isDeliverToPerson) {
          await trigger([`form16.deliveringCashTo.person`]);
          if (isEmpty(errors)) {
            setPage((currenState) => ({
              ...currenState,
              name: 'Form19SubmitDeclaration',
              number: pageList.indexOf('Form19SubmitDeclaration')
            }));
          }
        }

        if (isDeliverToCompany) {
          await trigger([`form16.deliveringCashTo.organisation`]);
          if (isEmpty(errors)) {
            setPage((currenState) => ({
              ...currenState,
              name: 'Form19SubmitDeclaration',
              number: pageList.indexOf('Form19SubmitDeclaration')
            }));
          }
        }
      }
    } else if (pageList[pageNumber] === 'Form19SubmitDeclaration') {
      await trigger([
        `form19.completingFormOnBehalf`,
        `form19.reasonForCompletingFormOnBehalf`,
        `form19.capacityCode`,
        `form19.personCompletingForm.givenNames`,
        `form19.personCompletingForm.familyName`,
        `form19.personCompletingForm.passportNumber`,
        `form19.personCompletingForm.occupation`,
        `form19.personCompletingForm.address`,
        `form19.personCompletingForm.phones`,
        `form19.personCompletingForm.emailAddress`,
        `form19.personCompletingForm.confirmEmailAddress`
      ]);

      if (isOtherCapacity) {
        await trigger([`form19.otherCapacity`]);
      }

      if (isEmpty(errors)) {
        setPage((currenState) => ({
          ...currenState,
          name: 'Form20Signature',
          number: pageList.indexOf('Form20Signature')
        }));
      }
    } else if (pageList[pageNumber] === 'Form20Signature') {
      await trigger([
        `form20.signed`,
        `form20.signature`,
        `form20.dateOfSignature`
      ]);
    }
  };

  // console.log('errors: ', errors);

  const totalNzdEquivalentAmount = useWatch({
    control,
    name: 'form2.totalNzdEquivalentAmount'
  });
  let disableNext = null;
  disableNext =
    (pageList[pageNumber] === 'Form1RegOverview' ||
    totalNzdEquivalentAmount >= 10000
      ? false
      : true) || (isEmpty(errors) ? false : true);

  useEffect(() => {
    setProgress((100 / 18) * pageNumber);
  }, [pageNumber]);

  const onSubmit = async (data) => {
    // console.log('initial data: ', data);

    // format data to match api spec
    if (bniOnly?.length > 0) {
      const form3 = merge(data.form3?.bni, bniOnly);

      data.cash = data.form2?.cash.map((c) => {
        let hasfound = form3.find(
          (f3) =>
            f3.typeOfCash?.code === c.typeOfCash?.code &&
            f3.currencyName?.code === c.currencyName?.code &&
            f3.amount === c.amount &&
            f3.exchangeRate === c.exchangeRate
        );
        if (hasfound === undefined) {
          return c;
        } else {
          return hasfound;
        }
      });
    } else {
      data.cash = data.form2?.cash;
    }

    data.totalNzdEquivalentAmount = data.form2?.totalNzdEquivalentAmount;

    if (data.form4?.cashOwner?.nzResident === 'yes') {
      data.cashOwner = merge(data.form4?.cashOwner, data.form5?.cashOwner);
    } else {
      data.cashOwner = merge(data.form4?.cashOwner, data.form6?.cashOwner);
    }

    if (
      data.form7?.cashMovementDirection?.code === 'bringingIn' ||
      data.form7?.cashMovementDirection?.code === 'receiving'
    ) {
      data.cashOwner = merge(data.cashOwner, data.form12?.cashOwner);
    } else {
      data.cashOwner = merge(data.cashOwner, data.form13?.cashOwner);
    }

    data = merge(data, data.form7);

    if (data.form7?.cashMovementMethod === 'cargo') {
      if (
        data.form7?.cashMovementDirection?.code === 'bringingIn' ||
        data.form7?.cashMovementDirection?.code === 'receiving'
      ) {
        data = merge(data, data.form8);
      } else {
        data = merge(data, data.form9);
      }
    }

    if (data.form7?.cashMovementMethod === 'mail') {
      if (
        data.form7?.cashMovementDirection?.code === 'bringingIn' ||
        data.form7?.cashMovementDirection?.code === 'receiving'
      ) {
        data = merge(data, data.form10);
      } else {
        data = merge(data, data.form11);
      }
    }

    if (data.form7?.cashMovementMethod === 'inPerson') {
      if (
        data.form7?.cashMovementDirection?.code === 'bringingIn' ||
        data.form7?.cashMovementDirection?.code === 'receiving'
      ) {
        const form12Omitted = omit(data.form12, [
          'cashOwner',
          'hasTransitStops'
        ]);
        data = merge(data, form12Omitted);
      } else {
        const form13Omitted = omit(data.form13, [
          'cashOwner',
          'hasTransitStops'
        ]);
        data = merge(data, form13Omitted);
      }
    }

    data = merge(data, data.form14);

    data.movingCashOnBehalfOf = data.form15?.movingCashOnBehalfOf;
    const isOnBehalfOf = data.form15?.onBehalfOf;

    data.deliveringCashTo = data.form16?.deliveringCashTo;
    const isDeliveringTo = data.form16?.deliveringTo;

    data = merge(data, data.form19);
    data = merge(data, data.form20);

    let formattedData = omit(data, [
      'form2',
      'form3',
      'form4',
      'form5',
      'form6',
      'form7',
      'form8',
      'form9',
      'form10',
      'form11',
      'form12',
      'form13',
      'form14',
      'form15',
      'form16',
      'form19',
      'form20'
    ]);

    // console.log('formattedData: ', formattedData);

    // cash object
    formattedData.cash.forEach((c) => {
      if (c.currencyName?.code !== 'Other') {
        c.otherCurrencyName = null;
      }
      c.currencyName = c.currencyName?.code;

      if (c.typeOfCash?.code !== 'other') {
        c.otherTypeOfCash = null;
      }
      c.typeOfCash = c.typeOfCash?.code;

      if (c?.addressOfIssuerOrDrawer?.streetType) {
        c.addressOfIssuerOrDrawer.streetType =
          c?.addressOfIssuerOrDrawer?.streetType?.code;
      }

      if (c?.addressOfIssuerOrDrawer?.country) {
        c.countryOfIssuerOrDrawer = c?.addressOfIssuerOrDrawer?.country?.code;
        c.addressOfIssuerOrDrawer.country =
          c?.addressOfIssuerOrDrawer?.country?.code;
      }

      if (c?.addressOfPayeeOrBeneficiary?.streetType) {
        c.addressOfPayeeOrBeneficiary.streetType =
          c?.addressOfPayeeOrBeneficiary?.streetType?.code;
      }

      if (c?.addressOfPayeeOrBeneficiary?.country) {
        c.countryOfPayeeOrBeneficiary =
          c?.addressOfPayeeOrBeneficiary?.country?.code;
        c.addressOfPayeeOrBeneficiary.country =
          c?.addressOfPayeeOrBeneficiary?.country?.code;
      }
    });

    // object on top level
    formattedData.cashMovementDirection =
      formattedData.cashMovementDirection?.code;

    // We should only map in transit countries if the hasTransitStops radio button
    // is set to true? Otherwise a user can enter in transit countries, then decide not
    // to have them but the list of countries will still me mapped into the
    // transit country array.

    formattedData.transitCountries =
      (data?.form12?.hasTransitStops === 'yes' ||
        data?.form13?.hasTransitStops === 'yes') &&
      !isEmpty(formattedData?.transitCountries[0])
        ? formattedData?.transitCountries?.map((t) => t.code)
        : [];

    formattedData.countryFrom = formattedData.countryFrom?.code;
    formattedData.countryTo = formattedData.countryTo?.code;

    if (formattedData.capacityCode?.code !== 'other') {
      formattedData.otherCapacity = null;
    }
    formattedData.capacityCode = formattedData.capacityCode?.code;

    // cashOwner object
    formattedData.cashOwner.nzResident =
      formattedData.cashOwner.nzResident === 'yes';

    formattedData.cashOwner.gender = formattedData.cashOwner?.gender?.code;

    formattedData.cashOwner.dateOfBirth = moment
      .tz(formattedData.cashOwner.dateOfBirth, 'Pacific/Auckland')
      .format('YYYY-MM-DD');

    formattedData.cashOwner.citizenship = formattedData.cashOwner?.nzResident
      ? 'NZ'
      : formattedData.cashOwner?.citizenship?.code;

    formattedData.cashOwner.address.streetType =
      formattedData.cashOwner?.address?.streetType?.code;

    if (formattedData.cashOwner?.address?.country?.code) {
      formattedData.cashOwner.address.country =
        formattedData.cashOwner?.address?.country?.code;
    } else if (formattedData.cashOwner?.nzResident === true) {
      formattedData.cashOwner.address.country = 'NZ';
    }

    formattedData.cashOwner.countryOfResidence =
      formattedData.cashOwner?.address?.country?.code;

    if (
      formattedData.cashOwner?.phones?.find((p) => p.phoneTypeCode === 'Mobile')
    ) {
      formattedData.cashOwner.mobilePhoneNumber = {
        ...formattedData.cashOwner?.phones?.find(
          (p) => p.phoneTypeCode === 'Mobile'
        )
      };
      formattedData.cashOwner.mobilePhoneNumber.phoneTypeCode = 'mobile';
      formattedData.cashOwner.mobilePhoneNumber.countryCode =
        formattedData.cashOwner.mobilePhoneNumber.countryCode.code;
    }

    if (
      formattedData.cashOwner?.phones?.find((p) => p.phoneTypeCode === 'Work')
    ) {
      formattedData.cashOwner.workPhoneNumber = {
        ...formattedData.cashOwner?.phones?.find(
          (p) => p.phoneTypeCode === 'Work'
        )
      };
      formattedData.cashOwner.workPhoneNumber.phoneTypeCode = 'work';
      formattedData.cashOwner.workPhoneNumber.countryCode =
        formattedData.cashOwner.workPhoneNumber.countryCode.code;
    }

    if (
      formattedData.cashOwner.phones?.find((p) => p.phoneTypeCode === 'Home')
    ) {
      formattedData.cashOwner.homePhoneNumber = {
        ...formattedData.cashOwner.phones?.find(
          (p) => p.phoneTypeCode === 'Home'
        )
      };
      formattedData.cashOwner.homePhoneNumber.phoneTypeCode = 'home';
      formattedData.cashOwner.homePhoneNumber.countryCode =
        formattedData.cashOwner.homePhoneNumber.countryCode.code;
    }

    formattedData.cashOwner.phones = [];
    let newData = omit(formattedData.cashOwner, [
      'phones',
      'confirmEmailAddress'
    ]);
    formattedData.cashOwner = { ...newData };

    // movingCashOnBehalf object
    formattedData.movingCashOnBehalf =
      formattedData.movingCashOnBehalf === 'yes';

    if (formattedData.movingCashOnBehalf) {
      if (isOnBehalfOf === 'person') {
        formattedData.movingCashOnBehalfOf.person.countryOfResidence =
          formattedData.movingCashOnBehalfOf?.person?.address?.country?.code;

        formattedData.movingCashOnBehalfOf.person.address.streetType =
          formattedData.movingCashOnBehalfOf?.person?.address?.streetType?.code;

        formattedData.movingCashOnBehalfOf.person.address.country =
          formattedData.movingCashOnBehalfOf?.person?.address?.country?.code;

        if (
          formattedData.movingCashOnBehalfOf?.person?.phones?.find(
            (p) => p.phoneTypeCode === 'Mobile'
          )
        ) {
          formattedData.movingCashOnBehalfOf.person.mobilePhoneNumber = {
            ...formattedData.movingCashOnBehalfOf?.person?.phones?.find(
              (p) => p.phoneTypeCode === 'Mobile'
            )
          };
          formattedData.movingCashOnBehalfOf.person.mobilePhoneNumber.phoneTypeCode =
            'mobile';
          formattedData.movingCashOnBehalfOf.person.mobilePhoneNumber.countryCode =
            formattedData.movingCashOnBehalfOf.person.mobilePhoneNumber.countryCode.code;
        }

        if (
          formattedData.movingCashOnBehalfOf?.person?.phones?.find(
            (p) => p.phoneTypeCode === 'Work'
          )
        ) {
          formattedData.movingCashOnBehalfOf.person.workPhoneNumber = {
            ...formattedData.movingCashOnBehalfOf?.person?.phones?.find(
              (p) => p.phoneTypeCode === 'Work'
            )
          };
          formattedData.movingCashOnBehalfOf.person.workPhoneNumber.phoneTypeCode =
            'work';
          formattedData.movingCashOnBehalfOf.person.workPhoneNumber.countryCode =
            formattedData.movingCashOnBehalfOf.person.workPhoneNumber.countryCode.code;
        }

        if (
          formattedData.movingCashOnBehalfOf?.person?.phones?.find(
            (p) => p.phoneTypeCode === 'Home'
          )
        ) {
          formattedData.movingCashOnBehalfOf.person.homePhoneNumber = {
            ...formattedData.movingCashOnBehalfOf?.person?.phones?.find(
              (p) => p.phoneTypeCode === 'Home'
            )
          };
          formattedData.movingCashOnBehalfOf.person.homePhoneNumber.phoneTypeCode =
            'home';
          formattedData.movingCashOnBehalfOf.person.homePhoneNumber.countryCode =
            formattedData.movingCashOnBehalfOf.person.homePhoneNumber.countryCode.code;
        }

        formattedData.movingCashOnBehalfOf.person.phones = [];

        let removed = omit(formattedData.movingCashOnBehalfOf?.person, [
          'phones'
        ]);

        formattedData.movingCashOnBehalfOf.person = { ...removed };

        removed = omit(formattedData.movingCashOnBehalfOf, ['organisation']);

        formattedData.movingCashOnBehalfOf = { ...removed };
      } else {
        formattedData.movingCashOnBehalfOf.organisation.address.streetType =
          formattedData.movingCashOnBehalfOf?.organisation?.address?.streetType?.code;

        formattedData.movingCashOnBehalfOf.organisation.address.country =
          formattedData.movingCashOnBehalfOf?.organisation?.address?.country?.code;

        if (
          formattedData.movingCashOnBehalfOf?.organisation?.phones?.find(
            (p) => p.phoneTypeCode === 'Mobile'
          )
        ) {
          formattedData.movingCashOnBehalfOf.organisation.mobilePhoneNumber = {
            ...formattedData.movingCashOnBehalfOf?.organisation?.phones?.find(
              (p) => p.phoneTypeCode === 'Mobile'
            )
          };
          formattedData.movingCashOnBehalfOf.organisation.mobilePhoneNumber.phoneTypeCode =
            'mobile';
          formattedData.movingCashOnBehalfOf.organisation.mobilePhoneNumber.countryCode =
            formattedData.movingCashOnBehalfOf.organisation.mobilePhoneNumber.countryCode.code;
        }

        if (
          formattedData.movingCashOnBehalfOf?.organisation?.phones?.find(
            (p) => p.phoneTypeCode === 'Work'
          )
        ) {
          formattedData.movingCashOnBehalfOf.organisation.workPhoneNumber = {
            ...formattedData.movingCashOnBehalfOf?.organisation?.phones?.find(
              (p) => p.phoneTypeCode === 'Work'
            )
          };
          formattedData.movingCashOnBehalfOf.organisation.workPhoneNumber.phoneTypeCode =
            'work';
          formattedData.movingCashOnBehalfOf.organisation.workPhoneNumber.countryCode =
            formattedData.movingCashOnBehalfOf.organisation.workPhoneNumber.countryCode.code;
        }

        if (
          formattedData.movingCashOnBehalfOf?.organisation?.phones?.find(
            (p) => p.phoneTypeCode === 'Home'
          )
        ) {
          formattedData.movingCashOnBehalfOf.organisation.homePhoneNumber = {
            ...formattedData.movingCashOnBehalfOf?.organisation?.phones?.find(
              (p) => p.phoneTypeCode === 'Home'
            )
          };
          formattedData.movingCashOnBehalfOf.organisation.homePhoneNumber.phoneTypeCode =
            'home';
          formattedData.movingCashOnBehalfOf.organisation.homePhoneNumber.countryCode =
            formattedData.movingCashOnBehalfOf.organisation.homePhoneNumber.countryCode.code;
        }

        let removed = omit(formattedData.movingCashOnBehalfOf, ['person']);
        formattedData.movingCashOnBehalfOf = { ...removed };

        formattedData.movingCashOnBehalfOf.organisation.phones = [];
        removed = omit(formattedData.movingCashOnBehalfOf?.organisation, [
          'phones'
        ]);
        formattedData.movingCashOnBehalfOf.organisation = {
          ...removed
        };
      }
    } else {
      let removed = omit(formattedData, ['movingCashOnBehalfOf']);
      formattedData = { ...removed };
    }

    formattedData.deliveringCashToCompanyOrPerson =
      formattedData.deliveringCashToCompanyOrPerson === 'yes';

    // deliveringCashTo object
    if (formattedData.deliveringCashToCompanyOrPerson) {
      if (isDeliveringTo === 'person') {
        formattedData.deliveringCashTo.person.address =
          convertCodeDescriptionAddressToBcrAddress(
            formattedData?.deliveringCashTo?.person?.address
          );

        formattedData.deliveringCashTo.person.countryOfResidence =
          formattedData.deliveringCashTo?.person?.address?.country;

        if (
          formattedData.deliveringCashTo?.person?.phones?.find(
            (p) => p.phoneTypeCode === 'Mobile'
          )
        ) {
          formattedData.deliveringCashTo.person.mobilePhoneNumber = {
            ...formattedData.deliveringCashTo?.person?.phones?.find(
              (p) => p.phoneTypeCode === 'Mobile'
            )
          };
          formattedData.deliveringCashTo.person.mobilePhoneNumber.phoneTypeCode =
            'mobile';
          formattedData.deliveringCashTo.person.mobilePhoneNumber.countryCode =
            formattedData.deliveringCashTo.person.mobilePhoneNumber.countryCode.code;
        }

        if (
          formattedData.deliveringCashTo?.person?.phones?.find(
            (p) => p.phoneTypeCode === 'Work'
          )
        ) {
          formattedData.deliveringCashTo.person.workPhoneNumber = {
            ...formattedData.deliveringCashTo?.person?.phones?.find(
              (p) => p.phoneTypeCode === 'Work'
            )
          };
          formattedData.deliveringCashTo.person.workPhoneNumber.phoneTypeCode =
            'work';
          formattedData.deliveringCashTo.person.workPhoneNumber.countryCode =
            formattedData.deliveringCashTo.person.workPhoneNumber.countryCode.code;
        }

        if (
          formattedData.deliveringCashTo?.person?.phones?.find(
            (p) => p.phoneTypeCode === 'Home'
          )
        ) {
          formattedData.deliveringCashTo.person.homePhoneNumber = {
            ...formattedData.deliveringCashTo?.person?.phones?.find(
              (p) => p.phoneTypeCode === 'Home'
            )
          };
          formattedData.deliveringCashTo.person.homePhoneNumber.phoneTypeCode =
            'home';
          formattedData.deliveringCashTo.person.homePhoneNumber.countryCode =
            formattedData.deliveringCashTo.person.homePhoneNumber.countryCode.code;
        }

        formattedData.deliveringCashTo.person.phones = [];

        let removed = omit(formattedData.deliveringCashTo?.person, ['phones']);
        formattedData.deliveringCashTo.person = { ...removed };

        removed = omit(formattedData.deliveringCashTo, ['organisation']);
        formattedData.deliveringCashTo = { ...removed };
      } else {
        formattedData.deliveringCashTo.organisation.address.streetType =
          formattedData.deliveringCashTo?.organisation?.address?.streetType?.code;

        formattedData.deliveringCashTo.organisation.address.country =
          formattedData.deliveringCashTo?.organisation?.address?.country?.code;

        if (
          formattedData.deliveringCashTo?.organisation?.phones?.find(
            (p) => p.phoneTypeCode === 'Mobile'
          )
        ) {
          formattedData.deliveringCashTo.organisation.mobilePhoneNumber = {
            ...formattedData.deliveringCashTo?.organisation?.phones?.find(
              (p) => p.phoneTypeCode === 'Mobile'
            )
          };
          formattedData.deliveringCashTo.organisation.mobilePhoneNumber.phoneTypeCode =
            'mobile';
          formattedData.deliveringCashTo.organisation.mobilePhoneNumber.countryCode =
            formattedData.deliveringCashTo.organisation.mobilePhoneNumber.countryCode.code;
        }

        if (
          formattedData.deliveringCashTo?.organisation?.phones?.find(
            (p) => p.phoneTypeCode === 'Work'
          )
        ) {
          formattedData.deliveringCashTo.organisation.workPhoneNumber = {
            ...formattedData.deliveringCashTo?.organisation?.phones?.find(
              (p) => p.phoneTypeCode === 'Work'
            )
          };
          formattedData.deliveringCashTo.organisation.workPhoneNumber.phoneTypeCode =
            'work';
          formattedData.deliveringCashTo.organisation.workPhoneNumber.countryCode =
            formattedData.deliveringCashTo.organisation.workPhoneNumber.countryCode.code;
        }

        if (
          formattedData.deliveringCashTo?.organisation?.phones?.find(
            (p) => p.phoneTypeCode === 'Home'
          )
        ) {
          formattedData.deliveringCashTo.organisation.homePhoneNumber = {
            ...formattedData.deliveringCashTo?.organisation?.phones?.find(
              (p) => p.phoneTypeCode === 'Home'
            )
          };
          formattedData.deliveringCashTo.organisation.homePhoneNumber.phoneTypeCode =
            'home';
          formattedData.deliveringCashTo.organisation.homePhoneNumber.countryCode =
            formattedData.deliveringCashTo.organisation.homePhoneNumber.countryCode.code;
        }

        let removed = omit(formattedData.deliveringCashTo, ['person']);
        formattedData.deliveringCashTo = { ...removed };

        formattedData.deliveringCashTo.organisation.phones = [];

        removed = omit(formattedData.deliveringCashTo?.organisation, [
          'phones'
        ]);

        formattedData.deliveringCashTo.organisation = { ...removed };
      }
    } else {
      let removed = omit(formattedData, ['deliveringCashTo']);
      formattedData = { ...removed };
    }

    formattedData.completingFormOnBehalf =
      formattedData.completingFormOnBehalf === 'yes';

    formattedData.dateOfSignature = moment
      .tz(formattedData.dateOfSignature, 'Pacific/Auckland')
      .format();

    if (formattedData.cashMovementMethod === 'mail') {
      formattedData.airOrSea = null;
      formattedData.dateOfArrivalOrDeparture = moment(
        formattedData.dateOfArrivalOrDeparture,
        'DD/MM/YYYY'
      ).format('YYYY-MM-DD');
    }

    formattedData.dateOfArrivalOrDeparture = moment
      .tz(formattedData.dateOfArrivalOrDeparture, 'Pacific/Auckland')
      .format('YYYY-MM-DD');

    //personCompletingForm object
    if (formattedData?.completingFormOnBehalf) {
      formattedData.personCompletingForm.countryOfResidence =
        formattedData.personCompletingForm?.address?.country?.code;

      formattedData.personCompletingForm.address.country =
        formattedData.personCompletingForm?.address?.country?.code;

      formattedData.personCompletingForm.address.streetType =
        formattedData.personCompletingForm?.address?.streetType?.code;

      if (
        formattedData.personCompletingForm?.phones?.find(
          (p) => p.phoneTypeCode === 'Mobile'
        )
      ) {
        formattedData.personCompletingForm.mobilePhoneNumber = {
          ...formattedData.personCompletingForm?.phones?.find(
            (p) => p.phoneTypeCode === 'Mobile'
          )
        };
        formattedData.personCompletingForm.mobilePhoneNumber.phoneTypeCode =
          'mobile';
        formattedData.personCompletingForm.mobilePhoneNumber.countryCode =
          formattedData.personCompletingForm.mobilePhoneNumber.countryCode.code;
      }

      if (
        formattedData.personCompletingForm?.phones?.find(
          (p) => p.phoneTypeCode === 'Work'
        )
      ) {
        formattedData.personCompletingForm.workPhoneNumber = {
          ...formattedData.personCompletingForm?.phones?.find(
            (p) => p.phoneTypeCode === 'Work'
          )
        };
        formattedData.personCompletingForm.workPhoneNumber.phoneTypeCode =
          'work';
        formattedData.personCompletingForm.workPhoneNumber.countryCode =
          formattedData.personCompletingForm.workPhoneNumber.countryCode.code;
      }

      if (
        formattedData.personCompletingForm.phones?.find(
          (p) => p.phoneTypeCode === 'Home'
        )
      ) {
        formattedData.personCompletingForm.homePhoneNumber = {
          ...formattedData.personCompletingForm.phones?.find(
            (p) => p.phoneTypeCode === 'Home'
          )
        };
        formattedData.personCompletingForm.homePhoneNumber.phoneTypeCode =
          'home';
        formattedData.personCompletingForm.homePhoneNumber.countryCode =
          formattedData.personCompletingForm.homePhoneNumber.countryCode.code;
      }

      formattedData.personCompletingForm.phones = [];

      let removed = omit(formattedData.personCompletingForm, [
        'phones',
        'confirmEmailAddress'
      ]);
      formattedData.personCompletingForm = { ...removed };
    } else {
      let removed = omit(formattedData, ['personCompletingForm']);
      formattedData = { ...removed };
    }

    formattedData.status = 'submitted';

    // console.log('onSubmit form data: ', formattedData);

    const reCapResponse = await reCaptchaRef.current.callbacks.execute();
    // console.log('reCapResponse: ', reCapResponse);

    let bcrId = null;
    await postBCR(formattedData, reCapResponse)
      .then((res) => {
        if (res.status === 200) {
          bcrId = res.data;
        }
      })
      .catch((error) => {
        // console.log(
        //   'Submitting the form has failed with the error: ',
        //   error.message
        // );
        if (error.message === 'FetchError - Unauthorized') {
          bcrId = 'Your session has expired';
        } else {
          bcrId = 'error';
        }
      })
      .finally(() => {
        history.push({ pathname: '/bcrId', state: { bcrId } });
      });
  };

  const onResolved = async () => {
    await reCaptchaRef.current.callbacks.getResponse();
  };

  const isFolder = useMediaQuery(bcrTheme.breakpoints.down(281));

  return (
    <>
      <LinearProgress
        variant="determinate"
        value={progress}
        color="secondary"
        className={classes.progress}
      />
      <Grid container spacing={2}>
        <Grid item container justifyContent="center">
          <Grid item xs={4} md={2} xl={2}>
            <Button
              disabled={pageNumber === 0}
              variant="contained"
              color="secondary"
              startIcon={<ArrowBackIosIcon />}
              onClick={handlePreClick}
            >
              Prev
            </Button>
          </Grid>

          <Grid item xs={isFolder ? 3 : 4} md={2} xl={2}>
            {/* {isFolder ? (
              <>
                <Typography className={classes.pageSteps}>
                  {pageNumber < pageList.length && <div>{pageNumber + 1}</div>}
                </Typography>
              </>
            ) : (
              <>
                <Typography className={classes.pageSteps}>
                  {pageNumber < pageList.length && (
                    <div>
                      Page {pageNumber + 1} of {pageList.length}
                    </div>
                  )}
                </Typography>
              </>
            )} */}
          </Grid>

          <Grid item xs={isFolder ? 5 : 4} md={2} xl={2}>
            {pageNumber >= pageList.indexOf('Form20Signature') ? (
              <Button
                disabled={disableNext}
                type="button"
                variant="contained"
                color="secondary"
                form="hook-form"
                endIcon={<ArrowForwardIosIcon />}
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </Button>
            ) : (
              <Button
                disabled={disableNext}
                type="button"
                variant="contained"
                color="secondary"
                endIcon={<ArrowForwardIosIcon />}
                onClick={handleNextClick}
              >
                Next
              </Button>
            )}
          </Grid>
          <Grid container className={classes.fields}>
            <Grid item xs={12} md={12} xl={12}>
              <Notes />
              <br />
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              xl={12}
              direction="row"
              className={classes.recaptcha}
            >
              <Recaptcha
                ref={reCaptchaRef}
                badge="inline"
                sitekey={`${process.env.REACT_APP_SITE_KEY}`}
                onResolved={onResolved}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
