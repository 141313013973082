import { Paper } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  sectionHeader: {
    padding: theme.spacing(2, 1, 2, 2),
    fontWeight: 500
  },
  warningColor: {
    color: theme.palette.warning.light
  },
  paper: {
    background: '#f1f5f9',
    borderLeftColor: theme.palette.warning.light,
    borderLeftStyle: 'solid',
    borderLeftWidth: '10px',
    marginRight: theme.spacing(-2)
  }
}));

const Notes = () => {
  const classes = useStyles();

  return (
    <>
      <Paper elevation={0} square={true} className={classes.paper}>
        <Typography variant="subtitle1" className={classes.sectionHeader}>
          <strong className={classes.warningColor}>
            WARNING: Forfeiture and Seizure
          </strong>
          <br />
          False or misleading reporting or non-reporting of cash may result in
          its forfeiture and seizure because, if it is moved in breach of the
          Act, it is a prohibited import/export under the Customs and Excise Act
          2018.
        </Typography>
      </Paper>
    </>
  );
};

export default Notes;
