import React, { useEffect } from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useFormContext } from 'react-hook-form';
import ControllerDatePicker from 'components/ControllerDatePicker';
import ControllerRadioGroup from 'components/ControllerRadioGroup';
import { CountriesComponent } from 'components/CountriesComponent';
import ControllerTextField from 'components/ControllerTextField';
import { CodeDescription } from 'types';
import * as yup from 'yup';
import {
  ALPHABETS_NUMBERS_HYPHENS,
  ALPHABETS_NUMBERS_HYPHENS_MATCHES,
  MAX_LONG_STRING_LENGTH,
  MAX_LONG_STRING_LENGTH_WARNING
} from 'lib/yupConstants';
import ControllerAutocomplete from 'components/ControllerAutocomplete';
import moment from 'moment';
import { bcrTheme } from 'config/bcrTheme';

const useStyles = makeStyles((theme: Theme) => ({
  fields: {
    margin: theme.spacing(1, 0, 0, 0)
  },
  sectionHeader: {
    margin: theme.spacing(1, 5, 0, 0)
  },
  tooltipInfo: {
    color: theme.palette.customs.lightBlue
  }
}));

export const Form12InPersonArrivalSchema = (
  isInPerson: boolean,
  isArrival: boolean
) => {
  if (isInPerson && isArrival) {
    return yup.object().shape({
      cashOwner: yup.object().shape({
        passportNumber: yup
          .string()
          .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
          .matches(
            ALPHABETS_NUMBERS_HYPHENS_MATCHES,
            `${ALPHABETS_NUMBERS_HYPHENS}`
          )
          .nullable()
          .required('This is a required field')
          .trim()
          .min(1)
      }),
      airOrSea: yup.string().required('This is a required field'),
      flightNumberOrVesselName: yup
        .string()
        .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
        .matches(ALPHABETS_NUMBERS_HYPHENS_MATCHES, ALPHABETS_NUMBERS_HYPHENS)
        .nullable()
        .required('This is a required field')
        .trim()
        .min(1),
      dateOfArrivalOrDeparture: yup
        .string()
        .nullable()
        .required('This is a required field')
        .test('DOAOD', 'Please enter a valid date', (value) => {
          if (moment(value).isValid()) {
            return true;
          } else {
            return false;
          }
        }),
      overseasPort: yup
        .string()
        .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
        .matches(ALPHABETS_NUMBERS_HYPHENS_MATCHES, ALPHABETS_NUMBERS_HYPHENS)
        .nullable()
        .required('This is a required field')
        .trim()
        .min(1),
      countryFrom: yup
        .object()
        .shape({
          code: yup.string().required('This is a required field'),
          description: yup.string().required('This is a required field')
        })
        .nullable()
        .required('This is a required field'),
      nzPort: yup
        .string()
        .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
        .matches(ALPHABETS_NUMBERS_HYPHENS_MATCHES, ALPHABETS_NUMBERS_HYPHENS)
        .nullable()
        .required('This is a required field')
        .trim()
        .min(1),
      hasTransitStops: yup.string().required('This is a required field'),
      transitCountries: yup.array().when('hasTransitStops', {
        is: (hasTransitStops) => hasTransitStops === 'yes',
        then: yup
          .array()
          .of(
            yup
              .object()
              .shape({
                code: yup
                  .string()
                  .nullable()
                  .required('This is a required field'),
                description: yup
                  .string()
                  .nullable()
                  .required('This is a required field')
              })
              .nullable()
              .required('This is a required field')
          )
          .min(1)
          .required('This is a required field'),
        otherwise: yup.array().of(
          yup
            .object()
            .shape({
              code: yup.string().nullable().notRequired(),
              description: yup.string().nullable().notRequired()
            })
            .nullable()
            .notRequired()
        )
      })
    });
  } else {
    return yup.object({});
  }
};

export const form12InPersonArrivalDefaultValues = () => ({
  cashOwner: {
    passportNumber: ''
  },
  airOrSea: '',
  flightNumberOrVesselName: '',
  dateOfArrivalOrDeparture: null,
  overseasPort: '',
  countryFrom: null,
  nzPort: '',
  hasTransitStops: '',
  transitCountries: [{}]
});

interface Form12Props {
  countryCodes: CodeDescription[];
}

export const Form12InPersonArrival = ({ countryCodes }: Form12Props) => {
  const classes = useStyles();
  const isDown590 = useMediaQuery(bcrTheme.breakpoints.down(590));
  const { control, watch, getValues, clearErrors } = useFormContext();
  watch('form12.airOrSea');
  const hasTransitStops = watch('form12.hasTransitStops');

  useEffect(() => {
    if (hasTransitStops === 'no') {
      clearErrors('form12.transitCountries');
    }
  }, [clearErrors, hasTransitStops]);

  return (
    <>
      <Grid container item spacing={2} className={classes.fields}>
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.sectionHeader}>
            Travel Information – In Person Arrival
          </Typography>
        </Grid>
      </Grid>
      <Box my={4}>
        <Grid container>
          <Grid container item spacing={3}>
            <Grid item xs={12} md={6} xl={6}>
              <ControllerTextField
                name={`form12.cashOwner.passportNumber`}
                label="Passport Number"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <ControllerRadioGroup
                label="Did you travel by an air craft or sea craft?"
                name={`form12.airOrSea`}
                defaultValue={'air'}
                options={[
                  { label: 'Air Craft', value: 'air' },
                  { label: 'Sea Craft', value: 'sea' }
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <ControllerDatePicker
                name={`form12.dateOfArrivalOrDeparture`}
                defaultValue={null}
                format="DD/MM/YYYY"
                label="Date of Arrival into New Zealand"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <ControllerTextField
                name={`form12.flightNumberOrVesselName`}
                label={
                  getValues(`form12.airOrSea`) === 'sea'
                    ? 'Name of Vessel'
                    : 'Flight Number'
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12} xl={12}>
              {isDown590 && (
                <>
                  <Typography variant="subtitle1">
                    Which overseas port did you start your trip from?
                  </Typography>
                </>
              )}
              <ControllerTextField
                name={`form12.overseasPort`}
                label={
                  isDown590
                    ? ''
                    : 'Which overseas port did you start your trip from?'
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              {isDown590 && (
                <>
                  <Typography variant="subtitle1">
                    What overseas country did you start your trip from?
                  </Typography>
                </>
              )}
              <ControllerAutocomplete
                name={`form12.countryFrom`}
                label={
                  isDown590
                    ? ''
                    : 'What overseas country did you start your trip from?'
                }
                options={countryCodes}
                getOptionLabel={(option: CodeDescription) =>
                  option.description || ''
                }
                getOptionSelected={(option, selected) =>
                  option?.code === selected?.code
                }
              />
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <ControllerTextField
                name={`form12.nzPort`}
                label="New Zealand Port of Arrival"
                fullWidth
              />
            </Grid>
            <Grid item xs={11} md={12} xl={12}>
              <ControllerRadioGroup
                label="Did you have any transit stops on your travel to New Zealand?"
                name={`form12.hasTransitStops`}
                options={[
                  { label: 'Yes', value: 'yes' },
                  { label: 'No', value: 'no' }
                ]}
              />
            </Grid>
            {getValues('form12.hasTransitStops') === 'yes' ? (
              <Grid item xs={12}>
                <CountriesComponent
                  control={control}
                  name={`form12.transitCountries`}
                  description="What countries did you transit through?"
                  countryCodes={countryCodes}
                />
              </Grid>
            ) : undefined}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
