import { About } from 'components/About';
import { Accessibility } from 'components/Accessibility';
import { Home } from 'containers/Home';
import BcrID from 'forms/BcrID';

const paths = [
  {
    path: '/',
    exact: true,
    name: 'Home',
    inNav: true, // Navbar provides home by default
    component: Home
  },

  {
    path: '/bcrId',
    exact: true,
    name: 'BcrID',
    inNav: false, // Navbar provides home by default
    component: BcrID
  },
  {
    path: '/about',
    exact: true,
    name: 'About',
    inNav: true, // Navbar provides home by default
    component: About
  },
  {
    path: '/accessibility',
    exact: true,
    name: 'Accessibility',
    inNav: true, // Navbar provides home by default
    component: Accessibility
  }
];

export default paths;
