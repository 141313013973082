const COLOR = {
    CUSTOMS_BLUE: "#003d6e",
    BLUE: "#0062CB",
    SECONDARY_BLUE: "#406E92",
    LIGHT_BLUE: "#f2f5f8",
    BORDER_BLUE: "#ced4da",
    BORDER_BLUE_SHADOW: "#b8c5d0",
    GREYISH_BROWN: "#4c4c4c",
    LIGHT_GREYISH_BROWN: "#6f6f6f",
    ERROR_RED: "#bc0000",
    LIGHT_GREY: "#EDECED",
    VERY_LIGHT_BLUE: "rgba(240, 247, 255, 1)",
    VERY_LIGHT_GREY: "rgba(0, 0, 0, 0.12)",
    VERY_LIGHT_ORANGE: "#fcf0e7",
    SPRINGLOAD_DARK_BLUE: "rgba(0, 61, 110, 1)",
    ORANGE: "#e5720f",
    BROWNGREY: "#a8a8a8",
    PAGINATION_GREY: "rgba(0, 0, 0, 0.54)",
    TABLE_BORDER_GREY: "#e0e0e0",
    DISABLED: "#a8a8a8",
    SUCCESS: "#44db29",
    INFO: "#ff9f04",
};
  
export default COLOR;
  