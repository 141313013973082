import { OutlinedTextFieldProps } from '@material-ui/core';
import TextField from './TextField';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

export interface ControllerTextFieldProps
  extends Partial<OutlinedTextFieldProps> {
  /** Name of the text field & name of the controller. No spaces allowed - (set label prop instead ) */
  name: string;
  /** Must be set here or when setting up useForm */
  defaultValue?: any;
  /** When set, will trim the event.target.value sent to onChange() */
  trim?: boolean;

  enforceUppercase?: boolean;
  control?: Control;
}

/**
 * Material UI Text field, wrapped with react hook form
 */
function ControllerTextField({
  name = 'controlled_text_field',
  defaultValue = null,
  trim = false,
  enforceUppercase = false,
  control,
  ...otherProps
}: ControllerTextFieldProps) {
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState
      }) => (
        <TextField
          id={`textfield_${name}`}
          name={name}
          data-cy={name}
          onBlur={onBlur}
          inputProps={{
            autoComplete: 'new-password'
          }}
          onChange={(e) => {
            const rawInputVal = e?.target?.value;
            let processedValue = rawInputVal;
            if (enforceUppercase) {
              processedValue = processedValue?.toUpperCase();
            }
            if (trim) {
              //trimming the empty strings from start and end
              processedValue = processedValue?.replace(/\S.*\S/, '');
            }
            onChange({
              ...e,
              target: { ...e?.target, value: processedValue }
            });
          }}
          autoComplete="new-password"
          value={value}
          inputRef={ref}
          size="medium"
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
          {...otherProps}
        />
      )}
    />
  );
}

export default ControllerTextField;
