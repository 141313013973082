import * as yup from 'yup';
import {
  MAX_LONG_STRING_LENGTH,
  MAX_LONG_STRING_LENGTH_WARNING
} from 'lib/yupConstants';

export const email = {
  emailAddress: yup
    .string()
    .email('Must be a valid email address')
    .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
    .nullable()
    .required('This is a required field')
    .trim()
    .min(1),
  confirmEmailAddress: yup
    .string()
    .oneOf(
      [yup.ref('emailAddress'), null],
      'Confirmation Email Address must match the Email Address'
    )
    .nullable()
    .required('Email confirmation is required')
};
