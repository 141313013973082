import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import PropTypes from 'prop-types';
import React from 'react';

const useLightStyle = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.white
  },
  tooltip: {
    boxShadow: theme.shadows[1],
    backgroundColor: theme.palette.common.white,
    color: theme.palette.customs.lightBlue
  }
}));

const useNormalStyle = makeStyles((theme) => ({
  tooltip: {
    fontSize: theme.typography.fontSize
  }
}));

BCRTooltip.propTypes = {
  /** Text to apply on the tooltip */
  title: PropTypes.any,
  /** When true, change the appearance to a lighter mode */
  light: PropTypes.bool,
  /** Text to apply on the tooltip */
  children: PropTypes.any
};

function BCRTooltip(props) {
  const { title = '', light = false, children = null, ...otherProps } = props;
  const lightStyle = useLightStyle();
  const normalStyle = useNormalStyle();
  return (
    <Tooltip
      enterTouchDelay={0}
      leaveTouchDelay={15000}
      TransitionComponent={Zoom}
      TransitionProps={{ timeout: 300 }}
      title={title}
      classes={light ? lightStyle : normalStyle}
      interactive
      {...otherProps}
    >
      {children}
    </Tooltip>
  );
}

export default BCRTooltip;
