import React, { forwardRef, Ref } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import MuiLink, { LinkProps as MuiLinkProps } from '@material-ui/core/Link';

export type LinkProps = MuiLinkProps & Pick<NavLinkProps, 'to' | 'replace'>;

const createLink = forwardRef(
  (props: NavLinkProps, ref: Ref<HTMLAnchorElement>) => (
    <NavLink innerRef={ref} {...props} />
  )
);

const Link: React.FC<LinkProps> = (props) => {
  return <MuiLink data-testid="Link" {...props} component={createLink} />;
};

export default Link;
