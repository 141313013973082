import { Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ControllerRadioGroup from 'components/ControllerRadioGroup';
import React, { FC, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { CodeDescription } from 'types';
import { Form17InfoOnBehalfPerson, person } from './Form17InfoOnBehalfPerson';
import {
  Form18InfoOnBehalfCompany,
  organisation
} from './Form18InfoOnBehalfCompany';
import * as yup from 'yup';
import { addressDefaultValues } from 'components/AddressComponent';

const useStyles = makeStyles((theme: Theme) => ({
  sectionHeader: {
    margin: theme.spacing(1, 5, 0, 0)
  },
  cardPadding: {
    margin: theme.spacing(2, 0, -2, 0)
  }
}));

export const form16InfoOnCashDeliverToDefaultValues = () => ({
  deliveringTo: '',
  deliveringCashTo: {
    person: {
      phones: [{}],
      nzCitizen: '',
      citizenship: '',
      passportNumber: '',
      countryOfResidence: '',
      givenNames: '',
      familyName: '',
      occupation: '',
      gender: '',
      emailAddress: '',
      address: addressDefaultValues
    },
    organisation: {
      phones: [{}],
      companyName: '',
      businessIdentificationNumber: null,
      relationToCompany: '',
      emailAddress: '',
      address: addressDefaultValues
    }
  }
});

export const Form16InfoOnCashDeliverToSchema = (isDeliver: boolean) => {
  if (isDeliver) {
    return yup.object().shape({
      deliveringTo: yup.string().required('This is a required field'),
      deliveringCashTo: yup.object().when('deliveringTo', {
        is: (deliveringTo) => deliveringTo === 'person',
        then: yup.object().shape({
          person: yup
            .object()
            .shape({ ...person.shape })
            .required('This is a required field')
        }),
        otherwise: yup.object().shape({
          organisation: yup
            .object()
            .shape({ ...organisation.shape })
            .required('This is a required field')
        })
      })
    });
  } else {
    return yup.object({});
  }
};

interface Form16Props {
  countryCodes: CodeDescription[];
  streetTypes: CodeDescription[];
}

export const Form16InfoOnCashDeliverTo: FC<Form16Props> = ({
  countryCodes,
  streetTypes
}) => {
  const classes = useStyles();

  const { control, clearErrors } = useFormContext();

  let deliveringTo = null;
  deliveringTo = useWatch({ control, name: 'form16.deliveringTo' });
  let isDeliverToPerson = null;
  isDeliverToPerson = deliveringTo === 'person' ? true : false;
  let isDeliverToCompany = null;
  isDeliverToCompany = deliveringTo === 'company' ? true : false;

  useEffect(() => {
    clearErrors();
  }, [clearErrors, deliveringTo]);

  return (
    <>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.sectionHeader}>
            Information on who you’re delivering the cash to
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid container item spacing={2} className={classes.cardPadding}>
          <Grid item xs={12}>
            <ControllerRadioGroup
              label="Are you delivering the cash to a"
              name={`form16.deliveringTo`}
              options={[
                { label: 'Person', value: 'person' },
                { label: 'Business or organisation', value: 'company' }
              ]}
            />
          </Grid>
          {isDeliverToPerson === true ? (
            <Form17InfoOnBehalfPerson
              countryCodes={countryCodes}
              streetTypes={streetTypes}
              isCashDelivering={true}
              nameSpace="form16.deliveringCashTo.person"
            />
          ) : undefined}

          {isDeliverToCompany === true ? (
            <Form18InfoOnBehalfCompany
              countryCodes={countryCodes}
              streetTypes={streetTypes}
              isCashDelivering={true}
              nameSpace="form16.deliveringCashTo.organisation"
            />
          ) : undefined}
        </Grid>
      </Grid>
    </>
  );
};
