import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid, Paper } from '@material-ui/core';
import theme from 'styles/theme';

const useStyles = makeStyles((theme: Theme) => ({
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  sectionHeader: {
    padding: theme.spacing(0, 0, 2, 0)
  },
  listDot: {
    listStyleType: 'square'
  },
  listCircle: {
    listStyleType: 'circle'
  },
  sectionColor: {
    padding: theme.spacing(2, 2, 2, 3),
    color: theme.palette.customs.lightBlue
  },
  paper: {
    background: '#f1f5f9',
    borderLeftColor: theme.palette.info.light,
    borderLeftStyle: 'solid',
    borderLeftWidth: '10px',
    marginBottom: theme.spacing(2)
  }
}));

const borderCashReport = () => {
  if (process.env.REACT_APP_ENV === 'prd') {
    return <Typography variant="h4">Border Cash Report</Typography>;
  } else {
    return (
      <Typography variant="h4">
        <span style={{ color: theme.palette.warning.dark }}>
          TEST Website for Border Cash Report
        </span>
      </Typography>
    );
  }
};

export default function Form1RegOverview() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      <Grid container>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <Typography variant="caption">
              <span style={{ float: 'right' }}>
                Anti-Money Laundering and Countering Financing of Terrorism Act
                2009, sections 68-71 - NZCS337
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {borderCashReport()}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.sectionHeader}>
              <strong>
                Movement of Cash Into or Out of New Zealand, or Receipt of Cash
                from Outside New Zealand
              </strong>
            </Typography>
            <Paper elevation={0} square={true} className={classes.paper}>
              <Typography variant="subtitle1" className={classes.sectionColor}>
                This Border Cash Report form must be completed in one sitting
                and cannot be retrieved again if you leave it incomplete.
                <br />
                <br />
                The completion of this Border Cash Report does not mean you have
                declared your cash to New Zealand Customs Service. You must
                still inform a Customs officer that you are moving or receiving
                cash across the border as required under the Anti-Money
                Laundering and Countering Financing of Terrorism Act 2009.
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <Accordion
              expanded={expanded === 'panel1'}
              onChange={handleChange('panel1')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography>
                  Who is required by New Zealand law to give a cash report?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component="span" className={classes.sectionColor}>
                  Sections 68 to 71 of the Anti-Money Laundering and Countering
                  Financing of Terrorism Act 2009 (“the Act”) require a report
                  in respect of the movement of the cash (“a cash report”) to be
                  given in writing in this prescribed form—
                  <br />
                  <ul className={classes.listDot}>
                    <li>
                      by a person moving (bringing accompanied) cash into, or
                      moving (taking accompanied or sending unaccompanied) cash
                      out of, New Zealand, if—
                      <ul className={classes.listCircle}>
                        <li>
                          the total amount of the cash (as determined under
                          section 7 of the Act, if not denominated in
                          New Zealand currency) is NZ$10,000 or more; and
                        </li>
                        <li>
                          the movement of the cash is not exempted under the Act
                          or regulations (if any); and
                        </li>
                      </ul>
                    </li>
                    <li>
                      by a person who is to receive in New Zealand unaccompanied
                      cash moved (sent) to the person (either by the person or
                      by another person) from outside New Zealand, if—
                      <ul className={classes.listCircle}>
                        <li>
                          the total amount of the cash (as determined under
                          section 7 of the Act, if not denominated in
                          New Zealand currency) is NZ$10,000 or more; and
                        </li>
                        <li>
                          the movement of the cash is not exempted under the Act
                          or regulations (if any).
                        </li>
                      </ul>
                    </li>
                  </ul>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Accordion
              expanded={expanded === 'panel2'}
              onChange={handleChange('panel2')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography>
                  Who must complete the report if person required to give it
                  (“A”) is incapable of doing so?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.sectionColor}>
                  If a person (“A”) required to give a cash report is (for
                  example, because of minority or disability) incapable of
                  completing it, then it must be completed on A’s behalf by a
                  person (“B”) authorised to act for A or responsible for A’s
                  care or property.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Accordion
              expanded={expanded === 'panel3'}
              onChange={handleChange('panel3')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography>
                  When and to whom must the report be given?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.sectionColor}>
                  For accompanied cash that a person is bringing into or taking
                  out of New Zealand, the report must be provided to a Customs
                  officer at the same time as section 103 (obligations on
                  persons arriving in New Zealand) or section 119 (obligations
                  of persons leaving New Zealand) of the Immigration Act 2009 is
                  complied with. For unaccompanied cash that a person is sending
                  out of New Zealand, the report must be provided to a Customs
                  officer before the cash leaves New Zealand. For unaccompanied
                  cash sent from outside New Zealand, the report must be
                  provided to a Customs officer before (or, under section 109 of
                  the Act, as soon as practicable after) it is received in New
                  Zealand.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Accordion
              expanded={expanded === 'panel4'}
              onChange={handleChange('panel4')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography>Meaning of “cash” and other key terms</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component="span" className={classes.sectionColor}>
                  “Cash”, as defined by section 5 of the Act, means physical
                  currency, bearer-negotiable instruments (“BNIs”), or both.
                  A bearer-negotiable instrument (“BNI”), as defined by section
                  5 of the Act, means—
                  <ul className={classes.listDot}>
                    <li>a bill of exchange; or</li>
                    <li>a cheque; or</li>
                    <li>a promissory note; or </li>
                    <li>a bearer bond; or</li>
                    <li>a traveller’s cheque; or </li>
                    <li>a money order, postal order, or similar order; or </li>
                    <li>
                      any other instrument prescribed by regulations under the
                      Act.
                    </li>
                  </ul>
                  A “person”, in this report, includes a corporation sole, a
                  body corporate, and an unincorporated body of persons.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <br />
            <Typography variant="h6">
              <strong>Offences, Penalties, Forfeiture, and Seizure</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Accordion
              expanded={expanded === 'panel5'}
              onChange={handleChange('panel5')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography>Offences</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component="span" className={classes.sectionColor}>
                  Sections 106 to 112 of the Act provide for offences and
                  penalties relating to cross-border transportation of cash.
                  For example, a person commits an offence— 
                  <ul className={classes.listDot}>
                    <li>
                      under section 106 or 107 of the Act if the person fails,
                      without reasonable excuse,  to make or cause to be made
                      a cash report concerning cash of 
                      <strong>NZ$10,000 or more (or foreign equivalent)</strong>
                       that the person has moved into or out of New Zealand, or
                      has received in New Zealand after it was sent from
                      overseas, and the person does not prove the defence (as to
                      compliance as soon as practicable) under section 109 of
                      the Act; and
                    </li>
                    <li>
                      under section 110 of the Act if, without reasonable
                      excuse, the person makes or causes to be made a cash
                      report knowing it is false or misleading in any material
                      respect; and
                    </li>
                    <li>
                      under section 111 of the Act if the person wilfully
                      obstructs or, without reasonable excuse, the person fails
                      to answer questions from, a Customs officer who is
                      exercising powers or carrying out duties under the Act.   
                    </li>
                  </ul>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12}>
            <Accordion
              expanded={expanded === 'panel6'}
              onChange={handleChange('panel6')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography>Penalties</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component="span" className={classes.sectionColor}>
                  Under section 112 of the Act, a person who commits any of
                  those offences is liable to the following penalties:
                  <ul className={classes.listDot}>
                    <li>
                      in the case of an individual, a term of imprisonment of
                      not more than 3 months, a fine up to $10,000, or both.
                    </li>
                    <li>
                      in the case of a body corporate, a fine of up to $50,000.
                    </li>
                  </ul>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
