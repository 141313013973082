import React, { FC } from 'react';
import { Grid, Hidden, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { AddressComponent } from 'components/AddressComponent';
import ControllerTextField from 'components/ControllerTextField';
import { PhoneComponent } from 'components/PhoneComponent';
import { useFormContext } from 'react-hook-form';
import { CodeDescription } from 'types';
import * as yup from 'yup';
import {
  ALPHABETS_NUMBERS_HYPHENS,
  ALPHABETS_NUMBERS_HYPHENS_MATCHES,
  MAX_LONG_STRING_LENGTH,
  MAX_LONG_STRING_LENGTH_WARNING
} from 'lib/yupConstants';
import { address } from 'lib/yupAddress';
import { phones } from 'lib/yupPhones';

const useStyles = makeStyles((theme: Theme) => ({
  fields: {
    margin: theme.spacing(1, 1, 0, 0)
  },
  sectionHeader: {
    margin: theme.spacing(1, 5, 0, 0)
  },
  cardPadding: {
    margin: theme.spacing(1, 0, 1, 0)
  },
  bottom: {
    marginBottom: theme.spacing(-2)
  }
}));

export const person = {
  shape: {
    givenNames: yup
      .string()
      .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
      .matches(
        ALPHABETS_NUMBERS_HYPHENS_MATCHES,
        `${ALPHABETS_NUMBERS_HYPHENS}`
      )
      .nullable()
      .required('This is a required field')
      .trim()
      .min(1),
    familyName: yup
      .string()
      .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
      .matches(
        ALPHABETS_NUMBERS_HYPHENS_MATCHES,
        `${ALPHABETS_NUMBERS_HYPHENS}`
      )
      .nullable()
      .required('This is a required field')
      .trim()
      .min(1),
    occupation: yup
      .string()
      .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
      .matches(
        ALPHABETS_NUMBERS_HYPHENS_MATCHES,
        `${ALPHABETS_NUMBERS_HYPHENS}`
      )
      .nullable()
      .required('This is a required field')
      .trim()
      .min(1),
    relationshipToPerson: yup
      .string()
      .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
      .matches(
        ALPHABETS_NUMBERS_HYPHENS_MATCHES,
        `${ALPHABETS_NUMBERS_HYPHENS}`
      )
      .nullable()
      .required('This is a required field')
      .trim()
      .min(1),
    address: address(),
    phones: yup.array().min(1).of(phones()).required()
  }
};

interface Form17Props {
  countryCodes: CodeDescription[];
  streetTypes: CodeDescription[];
  isCashDelivering: boolean;
  nameSpace: string;
}

export const Form17InfoOnBehalfPerson: FC<Form17Props> = ({
  countryCodes,
  streetTypes,
  isCashDelivering,
  nameSpace
}) => {
  const classes = useStyles();

  const { control, setValue, getValues } = useFormContext();

  return (
    <>
      <Grid container>
        <Grid container item spacing={2} className={classes.cardPadding}>
          <Hidden xsDown>
            <Grid item xs={12} md={6} xl={6}>
              <ControllerTextField
                name={`${nameSpace}.givenNames`}
                label={
                  isCashDelivering
                    ? 'Given name(s) of the person you are delivering the cash to'
                    : 'Given name(s) of the person you are moving or receiving the cash for'
                }
                defaultValue={''}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <ControllerTextField
                name={`${nameSpace}.familyName`}
                label={
                  isCashDelivering
                    ? 'Family name of the person you are delivering the cash to'
                    : 'Family name of the person you are moving or the receiving the cash for'
                }
                defaultValue={''}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <ControllerTextField
                name={`${nameSpace}.occupation`}
                label="What is their occupation, business, or main activity?"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <ControllerTextField
                name={`${nameSpace}.relationshipToPerson`}
                label="What is your relationship with this person?"
                fullWidth
              />
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid item xs={12} md={6} xl={6}>
              <Typography variant="subtitle1">
                {isCashDelivering
                  ? 'Given name(s) of the person you are delivering the cash to:'
                  : 'Given name(s) of the person you are moving or receiving the cash for:'}
              </Typography>
              <ControllerTextField
                name={`${nameSpace}.givenNames`}
                label=""
                defaultValue={''}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <Typography variant="subtitle1">
                {isCashDelivering
                  ? 'Family name of the person you are delivering the cash to:'
                  : 'Family name of the person you are moving or the receiving the cash for:'}
              </Typography>
              <ControllerTextField
                name={`${nameSpace}.familyName`}
                label=""
                defaultValue={''}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <Typography variant="subtitle1">
                What is their occupation, business, or main activity?
              </Typography>
              <ControllerTextField
                name={`${nameSpace}.occupation`}
                label=""
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <Typography variant="subtitle1">
                What is your relationship with this person?
              </Typography>
              <ControllerTextField
                name={`${nameSpace}.relationshipToPerson`}
                label=""
                fullWidth
              />
            </Grid>
          </Hidden>
        </Grid>

        <Grid container item spacing={2} className={classes.fields}>
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.sectionHeader}>
              Their contact information
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <AddressComponent
              description="What is their permanent residential address?"
              name={`${nameSpace}.address`}
              countryCodes={countryCodes}
              streetTypes={streetTypes}
            />
          </Grid>
          <Grid item xs={12} className={classes.bottom}>
            <PhoneComponent
              control={control}
              description="Phone Number:"
              name={`${nameSpace}.phones`}
              growNo={3}
              phoneTypes={['Mobile', 'Work', 'Home']}
              getValues={getValues}
              setValue={setValue}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
