// import { theme as customsTheme } from '@customs/react-components';
import { createTheme } from '@material-ui/core/styles';

/*---------------------------------
EXTEND TYPES
-----------------------------------*/
declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    customs: {
      mainBlue: string;
      secondaryBlue: string;
      lightBlue: string;
      darkGrey: string;
    };
  }
  interface PaletteOptions {
    customs: {
      mainBlue: string;
      secondaryBlue: string;
      lightBlue: string;
      darkGrey: string;
    };
  }
}

/*---------------------------------
VARIABLES
-----------------------------------*/
// const fontBase = '"Futura PT", "Helvetica", "Arial", sans-serif';
const fontBase = '"FuturaPT Book"';
const customsMainBlue = '#003D6E';
const customsMainBlueOpacity = 'rgb(0 61 110 / 5%)';
const customsSecondaryBlue = '#406E92';
const customsLightBlue = '#F0F7FF';
const customsDarkGrey = '#4C4C4C';

/*---------------------------------
THEME
-----------------------------------*/
const theme = createTheme({
  typography: {
    htmlFontSize: 16,
    fontSize: 14,
    fontFamily: ['"Futura PT"', 'sans-serif'].join(',')
  },
  palette: {
    customs: {
      mainBlue: customsMainBlue,
      secondaryBlue: customsSecondaryBlue,
      lightBlue: customsLightBlue,
      darkGrey: customsDarkGrey
    },
    primary: {
      main: customsMainBlue
    }
  },
  breakpoints: {
    values: {
      xs: 450,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    },
    get down() {
      return (key: number | string) =>
        `@media (max-width:${
          typeof key === 'string' ? this.values[key] - 0.5 : key - 0.5
        }px)`;
    },
    get up() {
      return (key: number | string) =>
        `@media (min-width:${
          typeof key === 'string' ? this.values[key] + 0.5 : key + 0.5
        }px)`;
    },
    get between() {
      return (keyA: number | string, keyB: number | string) =>
        `@media (min-width:${
          typeof keyA === 'string' ? this.values[keyA] : keyA
        }px) and (max-width:${
          typeof keyB === 'string' ? this.values[keyB] - 0.5 : keyB - 0.5
        }px)`;
    }
  },
  overrides: {
    MuiTypography: {
      h1: {
        fontFamily: fontBase,
        fontWeight: 600
      },
      h2: {
        fontFamily: fontBase,
        fontWeight: 600
      },
      h3: {
        fontFamily: fontBase,
        fontWeight: 600
      },
      h4: {
        fontFamily: fontBase,
        fontWeight: 600
      },
      h5: {
        fontFamily: fontBase,
        fontWeight: 600
      },
      h6: {
        fontFamily: fontBase,
        fontWeight: 600
      },
      body1: {
        fontFamily: fontBase
      },
      body2: {
        fontFamily: fontBase
      },
      subtitle1: {
        fontFamily: fontBase
      },
      subtitle2: {
        fontFamily: fontBase
      },
      caption: {
        fontFamily: fontBase
      },
      overline: {
        fontFamily: fontBase
      }
    },
    MuiButton: {
      text: {
        fontFamily: fontBase
      }
    },
    MuiIconButton: {
      colorSecondary: {
        '&:hover': {
          backgroundColor: customsMainBlueOpacity
        },
        color: customsMainBlue
      }
    },
    MuiMenuItem: {
      root: {
        fontFamily: fontBase
      }
    },
    MuiTableCell: {
      body: {
        fontFamily: fontBase
      }
    },
    MuiFormControl: {
      root: {
        fontFamily: fontBase,
        width: '100%'
      }
    },
    MuiSelect: {
      root: {
        fontFamily: fontBase
      }
    },
    MuiInputBase: {
      input: {
        fontFamily: fontBase
      }
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: customsMainBlue,
          '&:hover': {
            backgroundColor: customsMainBlueOpacity
          }
        }
      }
    },
    MuiRadio: {
      colorSecondary: {
        '&$checked': {
          color: customsMainBlue,
          '&:hover': {
            backgroundColor: customsMainBlueOpacity
          }
        }
      }
    },
    MuiFormLabel: {
      root: {
        fontFamily: fontBase
      }
    }
  }
});

export default theme;
