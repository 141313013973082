import { Button, Grid, Link, Paper, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ControllerAutocomplete from 'components/ControllerAutocomplete';
import RemoveIcon from '@material-ui/icons/Remove';
import React from 'react';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch
} from 'react-hook-form';
import BCRTooltip from 'components/BCRTooltip';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';
import { CodeDescription, ExchangeRate } from 'types';
import { useDeepCompareEffect } from 'hooks/useDeepCompare';
import NumberFormat from 'react-number-format';
import TextField from 'components/TextField';
import * as yup from 'yup';
import ControllerTextField from 'components/ControllerTextField';
import isEqual from 'lodash/isEqual';
import {
  ALPHABETS_NUMBERS_HYPHENS,
  ALPHABETS_NUMBERS_HYPHENS_MATCHES,
  MAX_LONG_STRING_LENGTH,
  MAX_LONG_STRING_LENGTH_WARNING
} from 'lib/yupConstants';

const useStyles = makeStyles((theme: Theme) => ({
  note: {
    margin: theme.spacing(2, 0, 4, 0),
    padding: theme.spacing(2, 2, 2, 2),
    color: theme.palette.customs.lightBlue
  },
  sectionHeader: {
    padding: theme.spacing(2, 0, 2, 0)
  },
  sectionSpacing: {
    marginBottom: theme.spacing(5)
  },
  tooltipInfo: {
    color: theme.palette.customs.lightBlue
  },
  paper: {
    '@media (min-width:600px)': {
      marginRight: theme.spacing(-1)
    },
    background: '#f1f5f9',
    borderLeftColor: theme.palette.info.light,
    borderLeftStyle: 'solid',
    borderLeftWidth: '10px'
  },
  inputTextColor: {
    color: 'rgba(0, 0, 0, 1)',
    backgroundColor: 'rgb(226, 224, 224)'
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.primary.main}`,
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    padding: 0
  }
}));

export const form2DeclareCashItemDefaultValues = () => ({
  totalNzdEquivalentAmount: '',
  cash: [
    {
      typeOfCash: null,
      currencyName: null,
      nzdEquivalent: ''
    }
  ]
});

const CURRENCY_NAME_TOOLTIP_TEXT = (
  <Typography variant="body1" color="inherit">
    Each form of cash being moved or received needs to be declared in a separate
    line. Please press the <strong>“+ADD ANOTHER TYPE OF CASH”</strong> button
    to begin a new line.
  </Typography>
);

const EXCHANGE_RATE_TOOLTIP_TEXT = (
  <Typography variant="body1" color="inherit">
    To obtain the relevant exchange rate refer to{' '}
    <Link
      href="https://www.xe.com"
      underline="always"
      color="inherit"
      target="_blank"
      rel="noreferrer"
    >
      www.xe.com
    </Link>{' '}
    or ask a NZ Customs officer.
  </Typography>
);

const NEXT_BUTTON_TOOLTIP_TEXT = (
  <Typography variant="body1" color="inherit">
    The <strong>"Next"</strong> button will not be enabled until you have a
    total NZD equivalent of $10,000 NZD.
  </Typography>
);

export const Form2DeclareCashItemSchema = () =>
  yup.object().shape({
    cash: yup.array().of(
      yup.object().shape({
        typeOfCash: yup
          .object()
          .shape({
            code: yup.string().required('This is a required field'),
            description: yup.string().required('This is a required field')
          })
          .nullable()
          .required('This is a required field'),
        otherTypeOfCash: yup.string().when('typeOfCash', {
          is: (typeOfCash) =>
            isEqual(typeOfCash, {
              code: 'other',
              description:
                'Other instrument prescribed by regulations under the Act'
            }),
          then: yup
            .string()
            .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
            .matches(
              ALPHABETS_NUMBERS_HYPHENS_MATCHES,
              `${ALPHABETS_NUMBERS_HYPHENS}`
            )
            .nullable()
            .required('This is a required field')
            .trim()
            .min(1),
          otherwise: yup
            .string()
            .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
            .matches(
              ALPHABETS_NUMBERS_HYPHENS_MATCHES,
              `${ALPHABETS_NUMBERS_HYPHENS}`
            )
            .notRequired()
        }),
        amount: yup
          .number()
          .min(1, 'This is a required field')
          .required('This is a required field'),
        currencyName: yup
          .object()
          .shape({
            code: yup.string().required('This is a required field'),
            description: yup.string().required('This is a required field')
          })
          .nullable()
          .required('This is a required field'),
        otherCurrencyName: yup.string().when('currencyName', {
          is: (currencyName) =>
            isEqual(currencyName, {
              code: 'Other',
              description: 'Other - Other'
            }),
          then: yup
            .string()
            .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
            .matches(
              ALPHABETS_NUMBERS_HYPHENS_MATCHES,
              `${ALPHABETS_NUMBERS_HYPHENS}`
            )
            .nullable()
            .required('This is a required field')
            .trim()
            .min(1),
          otherwise: yup
            .string()
            .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
            .matches(
              ALPHABETS_NUMBERS_HYPHENS_MATCHES,
              `${ALPHABETS_NUMBERS_HYPHENS}`
            )
            .notRequired()
        }),
        exchangeRate: yup
          .number()
          .min(0)
          .moreThan(0, 'Exchange Rate must be greater than 0')
          .nullable()
          .required('This is a required field')
      })
    )
  });

interface Form2Props {
  typeOfCurrencies: CodeDescription[];
  currencyNameCodes: CodeDescription[];
  exchangeRates: ExchangeRate[];
}

export const Form2DeclareCashItem = ({
  typeOfCurrencies,
  currencyNameCodes,
  exchangeRates
}: Form2Props) => {
  const classes = useStyles();
  const { control, setValue, getValues, clearErrors, trigger } =
    useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'form2.cash'
  });
  const cash = useWatch({ control, name: 'form2.cash' });
  const nzdEquArray = cash.map((item) => item.nzdEquivalent);
  const nzTotal = nzdEquArray.reduce((prev, curr) => {
    let currentValue = 0;
    if (curr) {
      currentValue = curr;
    }
    return prev + currentValue;
  }, 0);

  useDeepCompareEffect(() => {
    if (nzTotal > 0) {
      setValue('form2.totalNzdEquivalentAmount', nzTotal);
    } else if (nzTotal === 0) {
      setValue('form2.totalNzdEquivalentAmount', '');
    }
  }, [nzTotal, setValue]);

  return (
    <>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.sectionHeader}>
            Information about cash being moved or received
          </Typography>
          <Paper elevation={0} square={true} className={classes.paper}>
            <Typography variant="subtitle1" className={classes.note}>
              <strong>NOTE:</strong> Each type of currency needs to be declared
              separately.
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      {fields.map((item, index) => {
        return (
          <>
            <Grid container item spacing={2} key={item.id}>
              <Grid item md={5} xs={11} xl={5}>
                <ControllerAutocomplete
                  name={`form2.cash[${index}].typeOfCash`}
                  label="What is the type of cash?"
                  options={typeOfCurrencies || [{ description: '', code: '' }]}
                  getOptionLabel={(option: CodeDescription) =>
                    option.description || ''
                  }
                  getOptionSelected={(option, selected) =>
                    option?.code === selected?.code
                  }
                  onChange={(e, value: CodeDescription) => {
                    setValue(`form2.cash[${index}].typeOfCash`, value);
                    if (value?.code !== 'other') {
                      clearErrors(`form2.cash[${index}].otherTypeOfCash`);
                    }
                  }}
                />
              </Grid>
              <Grid item md={5} xs={11} xl={5}>
                <ControllerAutocomplete
                  name={`form2.cash[${index}].currencyName`}
                  label={'Currency Name – Currency Code'}
                  options={currencyNameCodes || [{ description: '', code: '' }]}
                  getOptionLabel={(option: CodeDescription) =>
                    option?.description || ''
                  }
                  getOptionSelected={(option, selected) =>
                    option?.code === selected?.code
                  }
                  onChange={(event, value: CodeDescription) => {
                    let rate = 1;
                    setValue(`form2.cash[${index}].currencyName`, value);

                    if (value?.code !== 'Other') {
                      clearErrors(`form2.cash[${index}].otherCurrency`);
                      trigger(`form2.cash[${index}].exchangeRate`);
                    }

                    const rateObj = exchangeRates.find((el) => {
                      return el?.code === value?.code;
                    });

                    setValue(
                      `form2.cash[${index}].exchangeRate`,
                      Number(rateObj.rate)
                    );
                    rate = rateObj.rate;
                    const amount = getValues(`form2.cash[${index}].amount`);
                    if (amount > 0 && rateObj !== undefined) {
                      setValue(
                        `form2.cash[${index}].nzdEquivalent`,
                        amount / rate
                      );
                    } else {
                      setValue(`form2.cash[${index}].nzdEquivalent`, '');
                    }
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <BCRTooltip title={CURRENCY_NAME_TOOLTIP_TEXT}>
                  <InfoIcon fontSize="small" className={classes.tooltipInfo} />
                </BCRTooltip>
              </Grid>
              {getValues(`form2.cash[${index}].typeOfCash`)?.code ===
              'other' ? (
                <Grid item xs={11} md={5} xl={5}>
                  <ControllerTextField
                    name={`form2.cash[${index}].otherTypeOfCash`}
                    label="Please specify the other type of cash"
                    defaultValue={''}
                    fullWidth
                  />
                </Grid>
              ) : undefined}
              <Grid item md={5} xs={11} xl={5}>
                <Controller
                  name={`form2.cash[${index}].amount`}
                  control={control}
                  render={({
                    field: { onChange, onBlur, name, value, ref },
                    fieldState
                  }) => (
                    <NumberFormat
                      customInput={TextField}
                      id={`controller_number_input_${name}`}
                      data-cy={`controller_number_input_${name}`}
                      allowNegative={false}
                      decimalScale={2}
                      thousandSeparator={true}
                      fixedDecimalScale
                      label="Amount of Cash"
                      onValueChange={(values) => {
                        const amount = values.floatValue;
                        setValue(`form2.cash[${index}].amount`, amount);
                        const exRate = getValues(
                          `form2.cash[${index}].exchangeRate`
                        );
                        if (amount > 0 && exRate > 0) {
                          setValue(
                            `form2.cash[${index}].nzdEquivalent`,
                            amount / exRate
                          );
                        } else {
                          setValue(`form2.cash[${index}].nzdEquivalent`, '');
                        }
                        trigger(`form2.cash[${index}].amount`);
                      }}
                      name={name}
                      value={value}
                      onBlur={onBlur}
                      inputRef={ref}
                      fullWidth
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>
              {getValues(`form2.cash[${index}].currencyName`)?.code ===
              'Other' ? (
                <Grid item xs={11} md={5} xl={5}>
                  <ControllerTextField
                    name={`form2.cash[${index}].otherCurrencyName`}
                    label="Please specify the other currency"
                    defaultValue={''}
                    fullWidth
                  />
                </Grid>
              ) : undefined}
              <Grid item md={5} xs={11} xl={5}>
                <Controller
                  name={`form2.cash[${index}].exchangeRate`}
                  control={control}
                  render={({
                    field: { onChange, onBlur, name, value, ref },
                    fieldState
                  }) => (
                    <NumberFormat
                      customInput={TextField}
                      id={`controller_number_input_${name}`}
                      data-cy={`controller_number_input_${name}`}
                      allowNegative={false}
                      decimalScale={2}
                      fixedDecimalScale
                      label={
                        <span style={{ color: '#696666' }}>Exchange Rate</span>
                      }
                      onValueChange={(values) => {
                        const exRate = values.floatValue;
                        if (exRate && exRate !== 0) {
                          setValue(
                            `form2.cash[${index}].exchangeRate`,
                            Number(exRate)
                          );
                        }

                        const amount = getValues(`form2.cash[${index}].amount`);
                        if (amount > 0 && values.floatValue > 0) {
                          setValue(
                            `form2.cash[${index}].nzdEquivalent`,
                            amount / exRate
                          );
                        } else {
                          setValue(`form2.cash[${index}].nzdEquivalent`, '');
                        }
                        trigger(`form2.cash[${index}].exchangeRate`);
                      }}
                      name={name}
                      value={value}
                      onBlur={onBlur}
                      inputRef={ref}
                      inputProps={
                        getValues(`form2.cash[${index}].currencyName`)?.code ===
                        'Other'
                          ? undefined
                          : {
                              className: classes.inputTextColor
                            }
                      }
                      disabled={
                        getValues(`form2.cash[${index}].currencyName`)?.code !==
                        'Other'
                      }
                      fullWidth
                      error={!!fieldState.error}
                      helperText={fieldState.error?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={1}>
                <BCRTooltip title={EXCHANGE_RATE_TOOLTIP_TEXT}>
                  <InfoIcon fontSize="small" className={classes.tooltipInfo} />
                </BCRTooltip>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item md={5} xs={11} xl={5}>
                  <Controller
                    name={`form2.cash[${index}].nzdEquivalent`}
                    control={control}
                    render={({
                      field: { onChange, onBlur, name, value, ref }
                    }) => (
                      <NumberFormat
                        customInput={TextField}
                        id={`controller_number_input_${name}`}
                        data-cy={`controller_number_input_${name}`}
                        allowNegative={false}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale
                        label={
                          <span style={{ color: '#696666' }}>
                            NZD Equivalent
                          </span>
                        }
                        onValueChange={(values) => onChange(values.floatValue)}
                        prefix="$"
                        name={name}
                        value={value}
                        onBlur={onBlur}
                        inputRef={ref}
                        fullWidth
                        inputProps={{
                          className: classes.inputTextColor
                        }}
                        disabled
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={1} xl={1}>
                  {index >= 1 ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      data-testid="Form2-Delete-button"
                      startIcon={<RemoveIcon />}
                      onClick={() => remove(index)}
                    >
                      Delete
                    </Button>
                  ) : undefined}
                </Grid>
              </Grid>
            </Grid>
            <hr className={classes.divider} />
            <br />
          </>
        );
      })}
      <Grid container item spacing={2}>
        <Grid item xs={12} md={5} xl={5}>
          <Button
            variant="contained"
            color="secondary"
            data-testid="Form2-Add-button"
            startIcon={<AddIcon />}
            onClick={() => {
              append({});
            }}
          >
            Add Another Type of Cash
          </Button>
        </Grid>
        <Grid item md={5} xs={11} xl={5}>
          <Controller
            name={`form2.totalNzdEquivalentAmount`}
            control={control}
            render={({ field: { onChange, onBlur, name, value, ref } }) => (
              <NumberFormat
                customInput={TextField}
                id={`controller_number_input_${name}`}
                data-cy={`controller_number_input_${name}`}
                allowNegative={false}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale
                label={
                  <span style={{ color: '#696666' }}>Total NZD Equivalent</span>
                }
                onValueChange={(values) => onChange(values.floatValue)}
                prefix="$"
                name={name}
                value={value}
                onBlur={onBlur}
                inputRef={ref}
                fullWidth
                inputProps={{
                  className: classes.inputTextColor
                }}
                disabled
              />
            )}
          />
        </Grid>
        <Grid item xs={1}>
          <BCRTooltip title={NEXT_BUTTON_TOOLTIP_TEXT}>
            <InfoIcon fontSize="small" className={classes.tooltipInfo} />
          </BCRTooltip>
        </Grid>
      </Grid>
    </>
  );
};
