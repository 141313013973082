import { Container, Grid, makeStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  sectionHeader: {
    fontSize: '1.875rem',
    margin: theme.spacing(0, 0, 0, 0),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(4, 0, 0, 0),
      fontSize: '2.125rem'
    }
  }
}));

export const About = () => {
  const classes = useStyles();

  return (
    <>
      <Container maxWidth="lg">
        <Grid container>
          <Grid
            container
            item
            direction="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            spacing={4}
          >
            <Grid item>
              <Typography variant="h4" className={classes.sectionHeader}>
                About
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                The Border Cash Report is a New Zealand government website. It
                is managed by New Zealand Customs Service. The site is intended
                to provide any person required to declare cash to New Zealand
                Customs Service with a secure process to complete and submit a
                Border Cash Report.
              </Typography>
              <br />
              <Typography variant="body1">
                The completion of the Border Cash Report does not mean the cash
                has been declared to the New Zealand Customs Service. The person
                moving or receiving the cash across the New Zealand border must
                still inform a Customs officer as required under the Anti-Money
                Laundering and Countering Financing of Terrorism Act 2009.
              </Typography>
              <br />
              <br />
              <br />
              <br />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
