import { Container, Grid } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  form2DeclareCashItemDefaultValues,
  Form2DeclareCashItemSchema
} from 'forms/Form2DeclareCashItem';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { FormList } from './FormList';
import { PageSteps } from './PageSteps';
import moment from 'moment';
import 'moment/locale/en-nz';
import DateFnsUtils from '@date-io/moment';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Form3BNIDetailsFormSchema } from 'forms/Form3BNIDetailsForm';
import {
  form4PersonalInfoDefaultValues,
  Form4PersonalInfoSchema
} from 'forms/Form4PersonalInfo';
import {
  form5NZContactInfoDefaultValues,
  Form5NZContactInfoSchema
} from 'forms/Form5NZContactInfo';
import {
  form6NonNZContactInfoDefaultValues,
  Form6NonNZContactInfoSchema
} from 'forms/Form6NonNZContactInfo';
import {
  form7CashMovementDefaultValues,
  Form7CashMovementSchema
} from 'forms/Form7CashMovement';
import {
  form8CargoImportInfoDefaultValues,
  Form8CargoImportInfoSchema
} from 'forms/Form8CargoImportInfo';
import {
  Form9CargoExportInfoSchema,
  form9CargoImportInfoDefaultValues
} from 'forms/Form9CargoExportInfo';
import {
  form10MailImportInfoDefaultValues,
  Form10MailImportInfoSchema
} from 'forms/Form10MailImportInfo';
import {
  form11MailExportInfoDefaultValues,
  Form11MailExportInfoSchema
} from 'forms/Form11MailExportInfo';
import {
  form12InPersonArrivalDefaultValues,
  Form12InPersonArrivalSchema
} from 'forms/Form12InPersonArrival';
import {
  form13InPersonDepartureDefaultValues,
  Form13InPersonDepartureSchema
} from 'forms/Form13InPersonDeparture';
import {
  form14InfoOnCashDefaultValues,
  Form14InfoOnCashSchema
} from 'forms/Form14InfoOnCash';
import {
  form15InfoOnCashBehalfDefaultValues,
  Form15InfoOnCashBehalfSchema
} from 'forms/Form15InfoOnCashBehalf';
import {
  form19SubmitDeclarationDefaultValues,
  Form19SubmitDeclarationSchema
} from 'forms/Form19SubmitDeclaration';
import {
  form20SignatureDefaultValues,
  Form20SignatureSchema
} from 'forms/Form20Signature';
import { CodeDescription, ExchangeRate } from 'types';
import { getAccessToken } from 'api/auth';
import {
  fetchCapacities,
  fetchCashMovementDirections,
  fetchCashTypes,
  fetchCountryCodes,
  fetchExchangeRates,
  fetchStreetTypes
} from 'api/getCodesApi';
import {
  form16InfoOnCashDeliverToDefaultValues,
  Form16InfoOnCashDeliverToSchema
} from 'forms/Form16InfoOnCashDeliverTo';
import { yupResolver } from '@hookform/resolvers/yup';
import sortBy from 'lodash/sortBy';
import * as yup from 'yup';
import { useDeepCompareEffect } from 'hooks/useDeepCompare';
import CircularIndeterminate from 'components/CircularIndeterminater';

const locale = 'en-nz';

moment.locale(locale); // it is required to select default locale manually

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    position: 'relative'
  },
  container: {
    flexGrow: 1
  },
  spinner: {
    position: 'absolute',
    height: '60%'
  },
  errorMessage: {
    marginLeft: theme.spacing(4),
    paddingRight: theme.spacing(8)
  }
}));

let typeOfCurrencies: CodeDescription[] = [];
let currencyNameCodes: CodeDescription[] = [];
let exchangeRates: ExchangeRate[] = [];
let countryCodes: CodeDescription[] = [];
let nonNZCountryCodes: CodeDescription[] = [];
let streetTypes: CodeDescription[] = [];
let capacityTypes: CodeDescription[] = [];
let cashMovementDirections: CodeDescription[] = [];

export const Home = () => {
  const classes = useStyles();

  const [hasData, setHasData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [page, setPage] = useState({ name: 'Form1RegOverview', number: 0 });
  const [validationSchema, setValidationSchema] = useState(
    yup.object().shape({
      form4: Form4PersonalInfoSchema(),
      form2: Form2DeclareCashItemSchema()
    })
  );

  const _defaultValue = () => ({
    form2: form2DeclareCashItemDefaultValues(),
    // form3: form3BNIDetailsFormDefaultValues(),
    form4: form4PersonalInfoDefaultValues(),
    form5: form5NZContactInfoDefaultValues(),
    form6: form6NonNZContactInfoDefaultValues(),
    form7: form7CashMovementDefaultValues(),
    form8: form8CargoImportInfoDefaultValues(),
    form9: form9CargoImportInfoDefaultValues(),
    form10: form10MailImportInfoDefaultValues(),
    form11: form11MailExportInfoDefaultValues(),
    form12: form12InPersonArrivalDefaultValues(),
    form13: form13InPersonDepartureDefaultValues(),
    form14: form14InfoOnCashDefaultValues(),
    form15: form15InfoOnCashBehalfDefaultValues(),
    form16: form16InfoOnCashDeliverToDefaultValues(),
    form19: form19SubmitDeclarationDefaultValues(),
    form20: form20SignatureDefaultValues()
  });

  const methods = useForm({
    defaultValues: _defaultValue(),
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
    mode: 'all'
  });

  // console.log('validationSchema: ', validationSchema);

  const { control, watch } = methods;

  useEffect(() => {
    async function getData() {
      try {
        // set to run under your localhost
        let response = null;
        if (process.env.REACT_APP_ENV === 'local') {
          response = await getAccessToken();
          if (response.status !== 200) {
            throw new Error('Cannot get access token');
          }
        }

        const result1 = await fetchExchangeRates();
        let currencyNameCodesOrigin: CodeDescription[] = [];

        exchangeRates = [];
        result1?.data.forEach((item) => {
          currencyNameCodesOrigin.push({
            description: `${item.CurrencyName} - ${item.CurrencyCode}`,
            code: item.CurrencyCode
          });
          exchangeRates.push({
            code: item.CurrencyCode,
            rate: Number(item.CurrencyRateNow)
          });
        });

        // sort currency list first
        let currencyNameCodesSorted: CodeDescription[] = [];
        currencyNameCodesSorted = sortBy(
          currencyNameCodesOrigin,
          'description'
        );

        currencyNameCodes = [];
        // move "Other - Other" item to the end
        currencyNameCodes = sortBy(currencyNameCodesSorted, ({ description }) =>
          description === 'Other - Other' ? 1 : 0
        );

        let countryCodesOriginal: CodeDescription[] = [];
        const result2 = await fetchCountryCodes();
        result2?.data.forEach((item) => {
          if (item.Status === 'A') {
            countryCodesOriginal.push({
              code: item.CountryCode,
              description: item.Description
            });
          }
        });
        countryCodes = [];
        countryCodes = sortBy(countryCodesOriginal, 'description');

        nonNZCountryCodes = [];
        nonNZCountryCodes = countryCodes.filter((c) => c.code !== 'NZ');

        streetTypes = [];
        const result3 = await fetchStreetTypes();
        result3?.data.forEach((item) => {
          if (item.Status === 'A') {
            streetTypes.push({
              code: item.StreetType,
              description: item.Description
            });
          }
        });

        capacityTypes = [];
        const result4 = await fetchCapacities();
        result4?.data.forEach((item) => {
          capacityTypes.push({
            code: item.code,
            description: item.description
          });
        });

        typeOfCurrencies = [];
        let result5 = await fetchCashTypes();
        result5.data = sortBy(result5.data, 'id');
        result5?.data.forEach((item) => {
          typeOfCurrencies.push({
            code: item.code,
            description: item.description
          });
        });

        cashMovementDirections = [];
        let result6 = await fetchCashMovementDirections();
        result6?.data.forEach((item) => {
          cashMovementDirections.push({
            code: item.code,
            description: item.description
          });
        });

        setHasData(true);
        setError(null);
      } catch (err) {
        // console.log('Error: ', err.message);
        setError(err.message);
        setHasData(false);
      } finally {
        setLoading(false);
      }
    }

    getData();
  }, []);

  // const cash = useWatch({ control, name: 'form2.cash' });

  const cash = watch('form2.cash');

  let bniOnly = null;
  bniOnly = cash.filter(
    (item) =>
      item.typeOfCash !== null &&
      item.typeOfCash?.code !== 'physicalCash' &&
      item.typeOfCash?.code !== 'other' &&
      item.typeOfCash?.code !== ''
  );

  // const nzResident = useWatch({ control, name: `form4.cashOwner.nzResident` });
  const nzResident = watch(`form4.cashOwner.nzResident`);
  let isNzResident = null;
  isNzResident = nzResident === 'yes';

  // const cashMovementMethod = useWatch({
  //   control,
  //   name: 'form7.cashMovementMethod'
  // });

  const cashMovementMethod = watch('form7.cashMovementMethod');

  const cashMovementDirection = useWatch({
    control,
    name: 'form7.cashMovementDirection'
  });

  let isCargo = cashMovementMethod === 'cargo';
  let isMail = cashMovementMethod === 'mail';
  let isInPerson = cashMovementMethod === 'inPerson';
  let isArrival = !!(
    cashMovementDirection?.code === 'bringingIn' ||
    cashMovementDirection?.code === 'receiving'
  );

  const movingCashOnBehalf = useWatch({
    control,
    name: 'form14.movingCashOnBehalf'
  });
  let isOnBehalf = movingCashOnBehalf === 'yes';
  const deliveringCashToCompanyOrPerson = useWatch({
    control,
    name: 'form14.deliveringCashToCompanyOrPerson'
  });
  let isDeliver = deliveringCashToCompanyOrPerson === 'yes';

  useDeepCompareEffect(() => {
    setValidationSchema(
      yup.object().shape({
        form2: Form2DeclareCashItemSchema(),
        form3: Form3BNIDetailsFormSchema(bniOnly.length),
        form4: Form4PersonalInfoSchema(),
        form5: Form5NZContactInfoSchema(isNzResident),
        form6: Form6NonNZContactInfoSchema(isNzResident),
        form7: Form7CashMovementSchema(),
        form8: Form8CargoImportInfoSchema(isCargo, isArrival),
        form9: Form9CargoExportInfoSchema(isCargo, isArrival),
        form10: Form10MailImportInfoSchema(isMail, isArrival),
        form11: Form11MailExportInfoSchema(isMail, isArrival),
        form12: Form12InPersonArrivalSchema(isInPerson, isArrival),
        form13: Form13InPersonDepartureSchema(isInPerson, isArrival),
        form14: Form14InfoOnCashSchema(),
        form15: Form15InfoOnCashBehalfSchema(isOnBehalf),
        form16: Form16InfoOnCashDeliverToSchema(isDeliver),
        form19: Form19SubmitDeclarationSchema(),
        form20: Form20SignatureSchema()
      })
    );
  }, [
    bniOnly.length,
    isNzResident,
    isCargo,
    isMail,
    isInPerson,
    isArrival,
    isOnBehalf,
    isDeliver
  ]);

  // console.log('watch', watch());
  // console.log('validationSchema', validationSchema);

  return (
    <>
      {loading && (
        <div>
          <Grid
            container
            item
            direction="column"
            alignItems="center"
            justifyContent="center"
            className={classes.spinner}
          >
            <CircularIndeterminate />
          </Grid>
        </div>
      )}
      {error && (
        <div>
          <Grid
            container
            item
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ height: '700px' }}
            className={classes.errorMessage}
          >
            {`Oops! there is a technical problem, please try again later.`}
          </Grid>
        </div>
      )}
      {hasData && (
        <div>
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            libInstance={moment}
            locale={locale}
          >
            <FormProvider {...methods}>
              <div className={classes.root}>
                <Container maxWidth="lg" className={classes.container}>
                  <form id="hook-form">
                    <FormList
                      pageName={page.name}
                      typeOfCurrencies={typeOfCurrencies}
                      currencyNameCodes={currencyNameCodes}
                      exchangeRates={exchangeRates}
                      countryCodes={countryCodes}
                      nonNZCountryCodes={nonNZCountryCodes}
                      streetTypes={streetTypes}
                      capacityTypes={capacityTypes}
                      cashMovementDirections={cashMovementDirections}
                    />
                    <PageSteps setPage={setPage} pageNumber={page.number} />
                  </form>
                  <br />
                </Container>
              </div>
            </FormProvider>
          </MuiPickersUtilsProvider>
        </div>
      )}
    </>
  );
};
