import { Route, Switch } from 'react-router-dom';
import routes from '../routes';
import { makeStyles } from '@material-ui/core/styles';
import Navigation from 'containers/Navigation';
import NotFound from 'components/NotFound';

/*---------------------------------
CUSTOM STYLES - MUI
-----------------------------------*/
const useStyles = makeStyles((theme) => ({
  base: {
    fontFamily: theme.typography.fontFamily,
    overflowX: 'hidden',
    overflowY: 'auto'
    //   scrollbarWidth: 'none' /* Firefox */,
    //   maxHeight: '817px',
    //   '&::-webkit-scrollbar': {
    //     display: 'none'
    //   } /* Chrome */
  },
  flexWrapper: {
    fontFamily: theme.typography.fontFamily,
    overflowX: 'hidden',
    display: 'flex',
    minHeight: '100%',
    flexDirection: 'column'
  }
}));

/*---------------------------------
EXPORT COMPONENT
-----------------------------------*/
const App = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.flexWrapper} data-testid="App">
        <Navigation>
          <Switch>
            {routes.map(({ path, exact, component }) => (
              <Route
                key={path}
                exact={exact}
                path={path}
                component={component}
              />
            ))}
            <Route component={NotFound} />
          </Switch>
        </Navigation>
      </div>
    </>
  );
};

export default App;
