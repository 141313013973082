import { makeStyles, Theme } from "@material-ui/core/styles";

const globalUseStyles = makeStyles((theme: Theme) => ({
  /** Sets display:grid and sets two columns when screen is sm or bigger */
  responsiveTwoColumnOnSmGrid: {
    display: `grid`,
    gridColumnGap: theme.spacing(2),
    gridRowGap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    gridTemplateColumns: " repeat(1, 1fr)",
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: " repeat(2, 1fr)",
    },
  },
  /** Sets gridColumn start+end. Takes up one column */
  oneColumnWide: {
    [theme.breakpoints.up("sm")]: {
      gridColumnStart: 1,
      gridColumnEnd: 2,
    },
  },
  /** Sets gridColumn start+end. Takes up two columns */
  twoColumnsWide: {
    [theme.breakpoints.up("sm")]: {
      gridColumnStart: 1,
      gridColumnEnd: 3,
    },
  },
  pb2: {
    paddingBottom: theme.spacing(2),
  },
}));
export { globalUseStyles };
