import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  FormHelperText,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
  Typography
} from '@material-ui/core';
import React, { ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { globalUseStyles } from 'styles/globalUseStyles';
import { fieldErrors } from '../lib/util';

const useStyles = makeStyles((theme: Theme) => ({
  paddingBottom: {
    paddingBottom: theme.spacing(1)
  }
}));

export type RadioOption = Pick<
  FormControlLabelProps,
  'label' | 'value' | 'disabled'
>;

export interface ControllerRadioGroupProps
  extends Pick<FormControlProps, 'className'> {
  name: string;
  label?: string;
  options: RadioOption[];
  helperText?: string;
  error?: boolean;
  defaultValue?: string;
}

function ControllerRadioGroup({
  name,
  label,
  options,
  helperText,
  error,
  defaultValue,
  ...otherProps
}: ControllerRadioGroupProps): ReactElement {
  const {
    formState: { errors },
    trigger,
    setValue
  } = useFormContext();

  const message = fieldErrors(errors, name)?.message;
  const isError = error || !!fieldErrors(errors, name);
  const classes = useStyles();
  const globalStyles = globalUseStyles();

  return (
    <div data-testid="ControllerRadioGroup">
      <Controller
        name={name}
        render={({ field }) => (
          <FormControl component="fieldset" error={isError} {...otherProps}>
            {label && (
              <FormLabel component="legend" className={classes.paddingBottom}>
                {label}
              </FormLabel>
            )}
            {helperText && (
              <Typography
                variant="body1"
                className={globalStyles.twoColumnsWide}
              >
                {helperText}
              </Typography>
            )}
            <RadioGroup
              aria-label={name}
              row
              name={name}
              defaultValue={defaultValue}
              {...field}
              onChange={(e) => {
                setValue(name, e.target.value);
                trigger(name);
              }}
            >
              {options.map(({ label, value, disabled }) => (
                <FormControlLabel
                  data-testid={`ControllerRadioGroup-label[${value}]`}
                  key={`${name}_${value}`}
                  value={value}
                  label={label}
                  disabled={disabled}
                  control={<Radio />}
                />
              ))}
            </RadioGroup>
            {message && <FormHelperText>{message}</FormHelperText>}
          </FormControl>
        )}
      ></Controller>
    </div>
  );
}

export default ControllerRadioGroup;
