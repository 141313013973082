import fetch from '../lib/fetch';

export const postBCR = async (data: any, reCapResponse: string) => {
  return fetch(`${process.env.REACT_APP_GATEWAY_BASE_URL}/bcr/report`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'x-reCapResponse': reCapResponse
    },
    formatResponse: async (rsp: Response) => rsp.text(),
    body: JSON.stringify({ ...data })
  });
};
