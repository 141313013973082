import equal from "fast-deep-equal/react";
import { useEffect, useRef } from "react";

export function deepCompareEquals(a: any, b: any) {
  return equal(a, b);
}

export function useDeepCompareMemoize(value: any) {
  const ref = useRef();

  if (!deepCompareEquals(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

export function useDeepCompareEffect(
  callback: () => void,
  dependencies: any[],
) {
  useEffect(callback, useDeepCompareMemoize(dependencies));
}
