import React, {
  FC,
  useState,
  HTMLAttributes,
  ReactChild,
  ReactNode
} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import Container from '@material-ui/core/Container';

import MenuIcon from '@material-ui/icons/Menu';

import { logo } from '../assets/images/Customs-logo-images';

const useStyles = makeStyles((theme) => ({
  main: {
    marginTop: 92 // TODO: not just hardcode estimate
  },
  toolbar: {
    // flexWrap: 'wrap',
  },
  links: {
    flexGrow: 1,
    whiteSpace: 'nowrap'
  },
  nav: {
    display: 'inline-block'
  },
  logo: {
    maxHeight: 45,
    height: 50,
    marginRight: theme.spacing(1),
    [theme.breakpoints.up(290)]: {
      maxHeight: 55, // TODO: not just hardcode estimate
      marginRight: theme.spacing(2),
      height: 55
    },
    [theme.breakpoints.up('sm')]: {
      maxHeight: 60, // TODO: not just hardcode estimate
      marginRight: theme.spacing(2),
      height: 60
    },
    [theme.breakpoints.up('md')]: {
      maxHeight: 70, // TODO: not just hardcode estimate
      marginRight: theme.spacing(2),
      height: 70
    }
  },
  drawerPaper: {
    padding: 20,
    background: theme.palette.primary.dark,
    color: theme.palette.common.white,
    maxHeight: '25vh', //Drawer height
    height: 'calc(100% - 55px)',
    top: 45,
    overflow: 'hidden',
    [theme.breakpoints.up(281)]: {
      top: 55
    }
  },
  iconButton: {
    float: 'right'
  }
}));

export interface Props extends HTMLAttributes<HTMLDivElement> {
  /** home link, req: provide link to homepage of application. Note: still visible in mobile */
  home: ReactNode;
  /** links, req: provide links for main navigation. Nore: not visible in mobile */
  links: ReactNode;
  /** drawer, req: provide alternative links for slide out mobile main navigation */
  drawer: ReactNode;
  /** menu, req: provide right-hand menu such as profile drop-down, or logout */
  menu: ReactNode;
  /** children, req: main content of the "page" to render */
  children: ReactChild;
  showMenuIcon?: boolean;
}

export const NavBar: FC<Props> = ({
  home,
  links,
  drawer,
  menu,
  children,
  showMenuIcon = true,
  ...props
}) => {
  const classes = useStyles();

  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <div data-testid="NavBar" {...props}>
      <AppBar position="absolute">
        <Toolbar variant="dense" className={classes.toolbar}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              {/* <Hidden mdDown> */}
              <a
                href="https://www.customs.govt.nz/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={logo}
                  alt="NZ Customs Logo"
                  className={classes.logo}
                />
              </a>
              {/* </Hidden> */}
            </Grid>

            <Grid item className={classes.links}>
              {home}
            </Grid>

            {menu && <Grid item>{menu}</Grid>}

            <Grid item>
              {showMenuIcon && (
                <>
                  <Hidden smUp>
                    <IconButton
                      edge="end"
                      className={classes.iconButton}
                      size="small"
                      color="inherit"
                      aria-label="open navigation menu"
                      onClick={() => setDrawerOpen(!drawerOpen)}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Hidden>
                </>
              )}
            </Grid>
            <Grid item>
              <nav className={classes.nav}>
                <Hidden xsDown>{links}</Hidden>
              </nav>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.main}>
        <Hidden smUp>
          <Drawer
            variant="temporary"
            anchor="right"
            open={drawerOpen}
            onClick={() => setDrawerOpen(false)}
            ModalProps={{
              keepMounted: true // Berr pen performance on mobile
            }}
            classes={{ paper: classes.drawerPaper }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Container maxWidth={false}>{children}</Container>
      </div>
    </div>
  );
};
