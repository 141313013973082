// BBOO env config

declare global {
  interface Window {
    _CUSTOMS_CONFIG_: any;
  }
}

const env = window._CUSTOMS_CONFIG_ || {};

interface Config {
  appVersion: string;
  appName: string;
  env: string;
  gatewayBaseUrl: string;
}

const config: Config = {
  // declare explicity
  appVersion: env.VERSION || '1.0',
  appName: process.env.REACT_APP_NAME || '',
  env: env.ENV,
  gatewayBaseUrl: env.GATEWAY_BASE_URL
};

export default config;
