import React, { FC, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useFormContext, useWatch } from 'react-hook-form';
import ControllerRadioGroup from 'components/ControllerRadioGroup';
import {
  Form18InfoOnBehalfCompany,
  organisation
} from './Form18InfoOnBehalfCompany';
import * as yup from 'yup';
import { Form17InfoOnBehalfPerson, person } from './Form17InfoOnBehalfPerson';
import { CodeDescription } from 'types';
import { addressDefaultValues } from 'components/AddressComponent';

const useStyles = makeStyles((theme: Theme) => ({
  fields: {
    margin: theme.spacing(1, 0, 0, 0)
  },
  sectionHeader: {
    margin: theme.spacing(1, 5, 0, 0)
  },
  cardPadding: {
    padding: theme.spacing(2, 0)
  }
}));

export const form15InfoOnCashBehalfDefaultValues = () => ({
  onBehalfOf: '',
  movingCashOnBehalfOf: {
    person: {
      phones: [{}],
      nzCitizen: '',
      citizenship: '',
      passportNumber: '',
      countryOfResidence: '',
      givenNames: '',
      familyName: '',
      gender: '',
      occupation: '',
      emailAddress: '',
      relationshipToPerson: '',
      address: addressDefaultValues
    },
    organisation: {
      phones: [{}],
      companyName: '',
      businessIdentificationNumber: null,
      relationToCompany: '',
      address: addressDefaultValues
    }
  }
});

export const Form15InfoOnCashBehalfSchema = (isOnBehalf: boolean) => {
  if (isOnBehalf) {
    return yup.object().shape({
      onBehalfOf: yup.string().required('This is a required field'),
      movingCashOnBehalfOf: yup.object().when('onBehalfOf', {
        is: (onBehalfOf) => onBehalfOf === 'person',
        then: yup.object().shape({
          person: yup
            .object()
            .shape({ ...person.shape })
            .required('This is a required field')
        }),
        otherwise: yup.object().shape({
          organisation: yup
            .object()
            .shape({ ...organisation.shape })
            .required('This is a required field')
        })
      })
    });
  } else {
    return yup.object({});
  }
};

interface Form15Props {
  countryCodes: CodeDescription[];
  streetTypes: CodeDescription[];
}

export const Form15InfoOnCashBehalf: FC<Form15Props> = ({
  countryCodes,
  streetTypes
}) => {
  const classes = useStyles();

  const { control, clearErrors } = useFormContext();

  let onBehalfOf;
  onBehalfOf = useWatch({ control, name: 'form15.onBehalfOf' });
  let isOnBehalfOfPerson = null;
  isOnBehalfOfPerson = onBehalfOf === 'person' ? true : false;
  let isOnBehalfOfCompany = null;
  isOnBehalfOfCompany = onBehalfOf === 'company' ? true : false;

  useEffect(() => {
    clearErrors();
  }, [clearErrors, onBehalfOf]);

  return (
    <>
      <Grid container item spacing={2} className={classes.fields}>
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.sectionHeader}>
            Information on who you’re moving the cash on behalf of
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid container item spacing={2} className={classes.cardPadding}>
          <Grid item xs={12}>
            <ControllerRadioGroup
              label="Are you moving the cash on behalf of a"
              name={`form15.onBehalfOf`}
              options={[
                { label: 'Person', value: 'person' },
                { label: 'Business or organisation', value: 'company' }
              ]}
            />
          </Grid>
          {isOnBehalfOfPerson === true ? (
            <Form17InfoOnBehalfPerson
              countryCodes={countryCodes}
              streetTypes={streetTypes}
              isCashDelivering={false}
              nameSpace="form15.movingCashOnBehalfOf.person"
            />
          ) : undefined}

          {isOnBehalfOfCompany === true ? (
            <Form18InfoOnBehalfCompany
              countryCodes={countryCodes}
              streetTypes={streetTypes}
              isCashDelivering={false}
              nameSpace="form15.movingCashOnBehalfOf.organisation"
            />
          ) : undefined}
        </Grid>
      </Grid>
    </>
  );
};
