import { ThemeOptions } from '@material-ui/core';
import COLOR from './colors';

const FuturaPT_Demi = require('../fonts/FuturaPT-Demi.woff2');
const FuturaPT_Book = require('../fonts/FuturaPT-Book.woff2');
// const FuturaPT_Book = require('../fonts/FuturaPT-Book.ttf');

const futuraPT_Demi: any = {
  fontFamily: 'FuturaPT-Demi',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `
    local('FuturaPT-Demi'),
    url(${FuturaPT_Demi}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
};

const futuraPT_Book: any = {
  fontFamily: 'FuturaPT-Book',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `
    local('FuturaPT-Book'),
    url(${FuturaPT_Book}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
};

export const bcrTheme: ThemeOptions = {
  direction: 'ltr',

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    },
    get down() {
      return (key: number | string) =>
        `@media (max-width:${
          typeof key === 'string' ? this.values[key] - 0.5 : key - 0.5
        }px)`;
    },
    get up() {
      return (key: number | string) =>
        `@media (min-width:${
          typeof key === 'string' ? this.values[key] + 0.5 : key + 0.5
        }px)`;
    },
    get between() {
      return (keyA: number | string, keyB: number | string) =>
        `@media (min-width:${
          typeof keyA === 'string' ? this.values[keyA] : keyA
        }px) and (max-width:${
          typeof keyB === 'string' ? this.values[keyB] - 0.5 : keyB - 0.5
        }px)`;
    }
  },

  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [futuraPT_Book, futuraPT_Demi]
      }
    },
    MuiButton: {
      root: {
        minWidth: '100px',
        minHeight: '40px',
        padding: '5px 15px'
      },
      text: {
        // fontFamily: 'FuturaPT-Demi',
        // fontFamily: 'Futura PT Book',
        letterSpacing: '1.25px'
      },
      sizeSmall: {
        paddingLeft: 20,
        paddingRight: 20
      },
      outlined: {
        borderColor: COLOR.SECONDARY_BLUE
      }
    },
    MuiFormControl: {
      root: {
        // fontFamily: 'Futura PT Book',
        fontSize: '1rem',
        letterSpacing: '0.5px'
      }
    },
    MuiTypography: {
      root: {
        color: '#4c4c4c'
      },
      h1: {
        // fontFamily: 'Futura PT Book',
        fontSize: '6rem',
        letterSpacing: '-1.5px'
      },
      h2: {
        // fontFamily: 'Futura PT Book',
        fontSize: '3.75rem',
        letterSpacing: '-0.5px'
      },
      h3: {
        // fontFamily: 'FuturaPT-Demi',
        // fontFamily: 'Futura PT Book',
        fontSize: '3rem'
      },
      h4: {
        // fontFamily: 'Futura PT Book',
        // fontSize: '2.125rem',
        // letterSpacing: '0.25px'
        fontSize: '1.575rem',
        letterSpacing: '0.25px',
        '@media (min-width:600px)': {
          fontSize: '1.875rem'
        }
      },
      h5: {
        // fontFamily: 'FuturaPT-Demi',
        // fontFamily: 'Futura PT Book',
        fontSize: '1.5rem',
        textTransform: 'none'
      },
      h6: {
        textTransform: 'none',
        // fontFamily: 'FuturaPT-Demi',
        // fontFamily: 'Futura PT Book',
        fontSize: '0.875rem',
        letterSpacing: '0.25px',
        '@media (min-width:600px)': {
          fontSize: '1.25rem'
        }
        // lineHeight: 1.7,
        // color: COLOR.GREYISH_BROWN,
      },

      body1: {
        // fontFamily: 'Futura PT Book',
        fontSize: '1rem',
        letterSpacing: '0.5px'
        // lineHeight: 1.6,
        // color: COLOR.GREYISH_BROWN,
      },
      body2: {
        // fontFamily: 'Futura PT Book',
        fontSize: '0.875rem',
        letterSpacing: '0.25px'
        // lineHeight: 1.6,
        // color: COLOR.GREYISH_BROWN,
        // marginTop: 2,
      },
      subtitle1: {
        // fontFamily: 'FuturaPT-Demi',
        // fontFamily: 'Futura PT Book',
        fontSize: '1rem',
        letterSpacing: '0.15px'
      },
      subtitle2: {
        // fontFamily: 'FuturaPT-Demi',
        // fontFamily: 'Futura PT Book',
        fontSize: '0.875rem',
        letterSpacing: '0.1px'
      },
      caption: {
        // fontFamily: 'FuturaPT-Demi',
        // fontFamily: 'Futura PT Book',
        fontSize: '0.75rem',
        letterSpacing: '0.4px',
        '@media (min-width:600px)': {
          fontSize: '0.875rem'
        }
        // lineHeight: "1.6em",
        // color: COLOR.BLUE,
      },
      overline: {
        // fontFamily: 'FuturaPT-Demi',
        // fontFamily: 'Futura PT Book',
        fontSize: '0.625rem',
        letterSpacing: '2px'
      }
    },
    MuiExpansionPanel: {
      root: {
        boxShadow: 'none',
        borderTop: 'solid 1px',
        borderColor: COLOR.CUSTOMS_BLUE,
        borderRadius: '0 !important',
        '&$expanded': {
          marginTop: 0,
          boxShadow:
            '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)'
        }
      }
    },
    MuiExpansionPanelSummary: {
      root: {
        color: COLOR.CUSTOMS_BLUE,
        backgroundColor: 'white',
        '&$expanded': {
          backgroundColor: COLOR.SECONDARY_BLUE,
          color: 'white'
        }
      }
    },
    MuiDialog: {
      container: {
        width: '100vw'
      },
      paper: {
        width: 636,
        padding: 0
      }
    },
    MuiDialogTitle: {
      root: {
        backgroundColor: COLOR.CUSTOMS_BLUE,
        color: 'white',
        width: '100%',
        alignItems: 'center',
        display: 'flex'
      }
    },
    MuiDialogContent: {
      root: {
        margin: 0,
        backgroundColor: 'white',
        padding: 16,
        height: 'auto'
      }
    },
    MuiDialogActions: {
      root: {
        backgroundColor: COLOR.LIGHT_BLUE,
        margin: 0,
        padding: 16
      }
    },
    MuiMenuItem: {
      root: {
        // fontFamily: 'Futura PT Book',
        fontSize: '1rem'
      }
    },
    MuiSelect: {
      root: {
        // fontFamily: 'Futura PT Book',
        fontSize: '1rem'
      },
      selectMenu: {
        whiteSpace: 'normal'
      }
    },
    MuiInputBase: {
      input: {
        // fontFamily: 'Futura PT Book',
        fontSize: '1rem'
      }
    },
    MuiTableCell: {
      body: {
        // fontFamily: 'Futura PT Book',
        fontSize: '1rem'
      }
    },
    MuiInputLabel: {
      root: {
        width: 'calc(100% - 50px)',
        'white-space': 'nowrap',
        overflow: 'hidden',
        'text-overflow': 'ellipsis'
      },
      shrink: {
        width: 'unset',
        'white-space': 'unset',
        overflow: 'unset',
        'text-overflow': 'unset'
      }
    }
  },
  palette: {
    primary: {
      main: '#003d6e'
      // light: "rgb(71, 145, 219)",
      // dark: "rgb(17, 82, 147)",
      // contrastText: "#fff",
    },
    secondary: {
      main: '#0062cb'
      //main: COLOR.ERROR_RED,
      // light: "rgb(227, 51, 113)",
      // dark: "rgb(154, 0, 54)",
      // contrastText: "#fff",
    },
    error: {
      main: '#bc0000'
      //   light: "#e57373",
      //   main: "#f44336",
      //   dark: "#d32f2f",
      //   contrastText: "#fff",
    },
    warning: {
      //   light: "#ffb74d",
      main: '#e5720f'
      //   dark: "#f57c00",
      //   contrastText: "rgba(0, 0, 0, 0.87)",
    },
    info: {
      //   light: "#64b5f6",
      main: '#0062cb'
      //   dark: "#1976d2",
      //   contrastText: "#fff",
    },
    success: {
      //   light: "#81c784",
      main: '#178512'
      //   dark: "#388e3c",
      //   contrastText: "rgba(0, 0, 0, 0.87)",
    },

    // contrastThreshold: 3,
    // tonalOffset: 0.2,
    text: {
      primary: '#4c4c4c',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: '#a8a8a8',
      hint: 'rgba(0, 0, 0, 0.38)'
    },
    // divider: "rgba(0, 0, 0, 0.12)",
    background: {
      paper: '#fff',
      default: '#fefefe'
    },
    customs: {
      mainBlue: '#003d6e',
      secondaryBlue: '#0062cb',
      lightBlue: '#0062cb',
      darkGrey: '#0062cb'
    }
    // action: {
    //   active: "rgba(0, 0, 0, 0.54)",
    //   hover: "rgba(0, 0, 0, 0.04)",
    //   hoverOpacity: 0.04,
    //   selected: "rgba(0, 0, 0, 0.08)",
    //   selectedOpacity: 0.08,
    //   disabled: "rgba(0, 0, 0, 0.26)",
    //   disabledBackground: "rgba(0, 0, 0, 0.12)",
    // },
  },
  props: {},

  typography: {
    htmlFontSize: 16,
    fontSize: 14
    // fontFamily:
    //   '"FuturaPT-Demi", "FuturaPT-Book", "Helvetica", "Arial", sans-serif'
    // fontFamily: '"FuturaPT-Demi", "FuturaPT-Book"'
  },

  shape: {
    borderRadius: 4
  }
};
