import fetch from '../lib/fetch';

export const fetchExchangeRates = async () => {
  return fetch(
    `${process.env.REACT_APP_GATEWAY_BASE_URL}/bcr/codes/currencyExchangeRates`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    }
  );
};

export const fetchCountryCodes = async () => {
  return fetch(
    `${process.env.REACT_APP_GATEWAY_BASE_URL}/bcr/codes/countryCodes`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    }
  );
};

export const fetchStreetTypes = async () => {
  return fetch(
    `${process.env.REACT_APP_GATEWAY_BASE_URL}/bcr/codes/streetTypeCodes`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    }
  );
};

export const fetchCashTypes = async () => {
  return fetch(`${process.env.REACT_APP_GATEWAY_BASE_URL}/bcr/cash_types`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  });
};

export const fetchCapacities = async () => {
  return fetch(`${process.env.REACT_APP_GATEWAY_BASE_URL}/bcr/capacities`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  });
};

export const fetchCashMovementDirections = async () => {
  return fetch(
    `${process.env.REACT_APP_GATEWAY_BASE_URL}/bcr/cash_movement_directions`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    }
  );
};
