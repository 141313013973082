import React, { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useFormContext } from 'react-hook-form';
import { AddressComponent } from 'components/AddressComponent';
import { CodeDescription } from 'types';
import { PhoneComponent } from 'components/PhoneComponent';
import { SubmittingWarningNote } from './SubmittingWarningNote';
import * as yup from 'yup';
import { phones } from 'lib/yupPhones';
import { address } from 'lib/yupAddress';
import { email } from 'lib/yupEmail';
import EmailComponent from 'components/EmailComponent';

const useStyles = makeStyles((theme: Theme) => ({
  fields: {
    margin: theme.spacing(2, 0, 2, 0)
  },
  sectionHeader: {
    margin: theme.spacing(1, 5, 0, 0)
  },
  sectionColor: {
    padding: theme.spacing(2, 0),
    color: theme.palette.info.light
  }
}));

export const form6NonNZContactInfoDefaultValues = () => ({
  cashOwner: {
    phones: [{}],
    emailAddress: ''
  }
});

export const Form6NonNZContactInfoSchema = (isNZResident) => {
  if (!isNZResident) {
    return yup.object().shape({
      cashOwner: yup.object().shape({
        address: address(),
        phones: yup.array().min(1).of(phones()).required(),
        emailAddress: email.emailAddress,
        confirmEmailAddress: email.confirmEmailAddress
      })
    });
  } else {
    return yup.object({});
  }
};

interface Form6Props {
  countryCodes: CodeDescription[];
  streetTypes: CodeDescription[];
}

export const Form6NonNZContactInfo: FC<Form6Props> = ({
  countryCodes,
  streetTypes
}) => {
  const classes = useStyles();

  const { control, getValues, setValue } = useFormContext();

  return (
    <>
      <Grid container item>
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.sectionHeader}>
            Your contact information
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.fields}>
          <SubmittingWarningNote />
        </Grid>

        <Grid container item spacing={2}>
          <Grid item xs={11} md={12} xl={12}>
            <AddressComponent
              description="Permanent Overseas Residential Address:"
              name="form6.cashOwner.address"
              countryCodes={countryCodes}
              streetTypes={streetTypes}
            />
          </Grid>
          <Grid item xs={11} md={12} xl={12}>
            <PhoneComponent
              control={control}
              description="Phone Number:"
              name="form6.cashOwner.phones"
              growNo={3}
              getValues={getValues}
              setValue={setValue}
            />
          </Grid>
          <Grid item xs={11} md={12} xl={12}>
            <EmailComponent name={`form6.cashOwner`} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
