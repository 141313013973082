import React, { useState } from 'react';
import { Grid, Tooltip, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useFormContext } from 'react-hook-form';
import InfoIcon from '@material-ui/icons/Info';
import ControllerDatePicker from 'components/ControllerDatePicker';
import ControllerRadioGroup from 'components/ControllerRadioGroup';
import ControllerTextField from 'components/ControllerTextField';
import * as yup from 'yup';
import {
  ALPHABETS_NUMBERS_HYPHENS,
  ALPHABETS_NUMBERS_HYPHENS_MATCHES,
  MAX_LONG_STRING_LENGTH,
  MAX_LONG_STRING_LENGTH_WARNING
} from 'lib/yupConstants';
import ControllerAutocomplete from 'components/ControllerAutocomplete';
import { CodeDescription } from 'types';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) => ({
  fields: {
    margin: theme.spacing(1, 0, 0, 0)
  },
  sectionHeader: {
    margin: theme.spacing(1, 5, 0, 0)
  },
  cardPadding: {
    margin: theme.spacing(2, 0, 0, 0),
    padding: theme.spacing(2, 0)
  },
  tooltipInfo: {
    color: theme.palette.customs.lightBlue
  }
}));

export const Form9CargoExportInfoSchema = (
  isCargo: boolean,
  isArrival: boolean
) => {
  if (isCargo && !isArrival) {
    return yup.object().shape({
      airOrSea: yup.string().required('This is a required field'),
      flightNumberOrVesselName: yup
        .string()
        .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
        .matches(ALPHABETS_NUMBERS_HYPHENS_MATCHES, ALPHABETS_NUMBERS_HYPHENS)
        .nullable()
        .required('This is a required field')
        .trim()
        .min(1),
      nzCustomsNumber: yup
        .string()
        .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
        .matches(ALPHABETS_NUMBERS_HYPHENS_MATCHES, ALPHABETS_NUMBERS_HYPHENS)
        .nullable()
        .optional(),
      overseasPort: yup
        .string()
        .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
        .matches(ALPHABETS_NUMBERS_HYPHENS_MATCHES, ALPHABETS_NUMBERS_HYPHENS)
        .nullable()
        .required('This is a required field')
        .trim()
        .min(1),
      countryTo: yup
        .object()
        .shape({
          code: yup.string().required('This is a required field'),
          description: yup.string().required('This is a required field')
        })
        .nullable()
        .required('This is a required field'),
      nzPort: yup
        .string()
        .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
        .matches(ALPHABETS_NUMBERS_HYPHENS_MATCHES, ALPHABETS_NUMBERS_HYPHENS)
        .nullable()
        .required('This is a required field')
        .trim()
        .min(1),
      dateOfArrivalOrDeparture: yup
        .string()
        .nullable()
        .required('This is a required field')
        .test('DOAOD', 'Please enter a valid date', (value) => {
          if (moment(value).isValid()) {
            return true;
          } else {
            return false;
          }
        })
    });
  } else {
    return yup.object({});
  }
};

export const form9CargoImportInfoDefaultValues = () => ({
  airOrSea: '',
  flightNumberOrVesselName: '',
  nzCustomsNumber: '',
  overseasPort: '',
  countryTo: null,
  nzPort: '',
  dateOfArrivalOrDeparture: null
});

interface Form9Props {
  nonNZCountryCodes: CodeDescription[];
}
export const Form9CargoExportInfo = ({ nonNZCountryCodes }: Form9Props) => {
  const classes = useStyles();
  const [showOverseasPortInfo, setShowOverseasPortInfo] = useState(false);
  const [showOverseasCountryInfo, setShowOverseasCountryInfo] = useState(false);

  const { watch, getValues } = useFormContext();
  watch('form9.airOrSea');

  return (
    <>
      <Grid container item spacing={2} className={classes.fields}>
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.sectionHeader}>
            Export Information – Cargo Movement
          </Typography>
        </Grid>
      </Grid>

      {/* <Box my={3}> */}
      <Grid container>
        <Grid container item spacing={2} className={classes.cardPadding}>
          <Grid item xs={12} md={12} xl={12}>
            <ControllerRadioGroup
              label="Is the cash departing via an air craft or sea craft?"
              name={`form9.airOrSea`}
              defaultValue={'sea'}
              options={[
                { label: 'Air Craft', value: 'air' },
                { label: 'Sea Craft', value: 'sea' }
              ]}
            />
          </Grid>
          <Grid item xs={12} md={5} xl={5}>
            <ControllerTextField
              name={`form9.flightNumberOrVesselName`}
              label={
                getValues(`form9.airOrSea`) === 'air'
                  ? 'Flight Number'
                  : 'Name of Vessel'
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={5} xl={5}>
            <ControllerTextField
              name={`form9.nzCustomsNumber`}
              label="New Zealand Customs Number (optional)"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={5} xl={5}>
            <ControllerTextField
              name={`form9.nzPort`}
              label="New Zealand Port of Departure"
              fullWidth
            />
          </Grid>
          <Grid item xs={11} md={5} xl={5}>
            <ControllerTextField
              name={`form9.overseasPort`}
              label="Overseas Port of Arrival"
              fullWidth
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip
              className={classes.tooltipInfo}
              title={
                <Typography variant="body1" color="inherit">
                  Which overseas port is the cash going to?
                </Typography>
              }
              aria-controls="DepOverseasPort-Info"
              data-testid="DepOverseasPort-info"
              placement="left-start"
              open={showOverseasPortInfo}
              disableHoverListener
              onMouseEnter={() => setShowOverseasPortInfo(true)}
              onMouseLeave={() => setShowOverseasPortInfo(false)}
            >
              <InfoIcon>info</InfoIcon>
            </Tooltip>
          </Grid>
          <Grid item xs={11} md={4} xl={4}>
            <ControllerAutocomplete
              name={`form9.countryTo`}
              label="Overseas Country of Arrival"
              options={nonNZCountryCodes}
              getOptionLabel={(option: CodeDescription) =>
                option.description || ''
              }
              getOptionSelected={(option, selected) =>
                option?.code === selected?.code
              }
            />
          </Grid>
          <Grid item xs={1}>
            <Tooltip
              className={classes.tooltipInfo}
              title={
                <Typography variant="body1" color="inherit">
                  Which overseas country is the cash going to?
                </Typography>
              }
              aria-controls="ArrOverseasCountry-Info"
              data-testid="ArrOverseasCountry-info"
              placement="left-start"
              open={showOverseasCountryInfo}
              disableHoverListener
              onMouseEnter={() => setShowOverseasCountryInfo(true)}
              onMouseLeave={() => setShowOverseasCountryInfo(false)}
            >
              <InfoIcon>info</InfoIcon>
            </Tooltip>
          </Grid>
          <Grid item xs={12} md={5} xl={5}>
            <ControllerDatePicker
              name={`form9.dateOfArrivalOrDeparture`}
              defaultValue={null}
              format="DD/MM/YYYY"
              label="Date of Export"
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      {/* </Box> */}
    </>
  );
};
