import React, { HTMLAttributes, ReactChild, Fragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import InfoIcon from '@material-ui/icons/Info';
import { NavBar } from '../components/NavBar';

import Link from 'components/Link';
import routes from 'routes';
import appConfig from 'config';
import { useMediaQuery } from '@material-ui/core';
import Footer from './Footer';
import BCRTooltip from 'components/BCRTooltip';
import { bcrTheme } from 'config/bcrTheme';

const useStyles = makeStyles((theme) => ({
  link: {
    fontSize: '0.815rem', //fontSize for "BCR" and "Border Cash Report"
    textUnderlinePosition: 'under',
    '&.active': {
      textDecoration: 'none'
    },
    [theme.breakpoints.up(290)]: {
      marginLift: theme.spacing(0), //margin left on "Border Cash Report"
      fontSize: '1.25rem', //fontSize for "BCR" and "Border Cash Report"
      letterSpacing: '0.25px'
    }
  },
  auth: {
    color: theme.palette.common.white,
    fontSize: '1rem',
    fontWeight: 500,
    textTransform: 'capitalize'
  },
  info: {
    color: theme.palette.info.light,
    margin: theme.spacing(0, 1, 0, 0)
  },
  linksMargin: {
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0, 2, 0, 1), //margin right and left for Home About Accessibility
      fontSize: '1rem',
      letterSpacing: '0.25px'
    },
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 3, 0, 1),
      fontSize: '1rem',
      letterSpacing: '0.25px'
    }
  },
  block: {
    display: 'block'
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  children: {
    minHeight: 'calc(100vh - 92px - 180px)', //set height to be viewport height minus header and footer
    [theme.breakpoints.up('sm')]: {
      minHeight: 'calc(100vh - 92px - 76px)'
    }
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.primary.main}`,
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    padding: 0
  },
  tooltipInfo: {
    color: theme.palette.info.light,
    margin: theme.spacing(0, 0, 1, 1)
  },
  drawerFont: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  }
}));

export interface NavigationProps extends HTMLAttributes<HTMLDivElement> {
  /** children, req: main content of the "page" to render */
  children: ReactChild;
}

function Navigation({ children, ...props }: NavigationProps) {
  const classes = useStyles();

  const navRoutes = routes.filter(({ inNav }) => inNav);
  const isFolder = useMediaQuery(bcrTheme.breakpoints.down(281));
  const isMobile = useMediaQuery(bcrTheme.breakpoints.down(819));

  return (
    <>
      <div data-testid="Navigation" {...props}>
        <Helmet titleTemplate="BCR | %s" defaultTitle="" />

        <NavBar
          home={
            <>
              <Link
                to="/"
                variant="h6"
                color="inherit"
                rel="noreferrer"
                className={classes.link}
              >
                <>
                  {isMobile ? 'BCR' : 'Border Cash Report'}
                  {isFolder ? undefined : (
                    <BCRTooltip
                      title={`Client version: ${appConfig.appVersion}`}
                    >
                      <InfoIcon
                        fontSize="small"
                        className={classes.tooltipInfo}
                      />
                    </BCRTooltip>
                  )}
                </>
              </Link>
            </>
          }
          links={
            <>
              {navRoutes.map(({ name, path }, p) => (
                <Link
                  key={p}
                  to={path}
                  color="inherit"
                  rel="noreferrer"
                  className={classes.linksMargin}
                >
                  {name}
                </Link>
              ))}
            </>
          }
          drawer={
            <List component="nav" aria-label="main navigation">
              <>
                {navRoutes.map(({ name, path }, p) => (
                  <Fragment key={p}>
                    <ListItem
                      button
                      component={RouterLink}
                      to={path}
                      className={classes.drawerFont}
                    >
                      {name}
                    </ListItem>
                    <Divider />
                  </Fragment>
                ))}
              </>
            </List>
          }
          menu={<></>}
        >
          <></>
        </NavBar>
      </div>
      <div className={classes.contentWrapper}>
        {' '}
        <div className={classes.children}>{children}</div>
        <div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Navigation;
