import { Box } from '@material-ui/core';
import Form1RegOverview from 'forms/Form1RegOverview';
import { Form2DeclareCashItem } from 'forms/Form2DeclareCashItem';
import { Form3BNIDetailsForm } from 'forms/Form3BNIDetailsForm';
import { Form4PersonalInfo } from 'forms/Form4PersonalInfo';
import { Form5NZContactInfo } from 'forms/Form5NZContactInfo';
import { Form6NonNZContactInfo } from 'forms/Form6NonNZContactInfo';
import { Form7CashMovement } from 'forms/Form7CashMovement';
import { Form8CargoImportInfo } from 'forms/Form8CargoImportInfo';
import { Form9CargoExportInfo } from 'forms/Form9CargoExportInfo';
import { Form10MailImportInfo } from 'forms/Form10MailImportInfo';
import { Form11MailExportInfo } from 'forms/Form11MailExportInfo';
import { Form12InPersonArrival } from 'forms/Form12InPersonArrival';
import { Form13InPersonDeparture } from 'forms/Form13InPersonDeparture';
import { Form14InfoOnCash } from 'forms/Form14InfoOnCash';
import { Form15InfoOnCashBehalf } from 'forms/Form15InfoOnCashBehalf';
import { Form19SubmitDeclaration } from 'forms/Form19SubmitDeclaration';
import Form20Signature from 'forms/Form20Signature';
import React from 'react';
import { Form16InfoOnCashDeliverTo } from 'forms/Form16InfoOnCashDeliverTo';

export const FormList = ({
  pageName,
  typeOfCurrencies,
  currencyNameCodes,
  exchangeRates,
  countryCodes,
  nonNZCountryCodes,
  streetTypes,
  capacityTypes,
  cashMovementDirections
}) => {
  return (
    <>
      <Box
        mb={2}
        style={{
          display: pageName === 'Form1RegOverview' ? 'block' : 'none'
        }}
      >
        <Form1RegOverview />
      </Box>
      <Box
        mb={2}
        style={{
          display: pageName === 'Form2DeclareCashItem' ? 'block' : 'none'
        }}
      >
        <Form2DeclareCashItem
          typeOfCurrencies={typeOfCurrencies}
          currencyNameCodes={currencyNameCodes}
          exchangeRates={exchangeRates}
        />
      </Box>
      <Box
        mb={2}
        style={{
          display: pageName === 'Form3BNIDetailsForm' ? 'block' : 'none'
        }}
      >
        <Form3BNIDetailsForm
          countryCodes={countryCodes}
          streetTypes={streetTypes}
        />
      </Box>
      <Box
        style={{
          display: pageName === 'Form4PersonalInfo' ? 'block' : 'none'
        }}
      >
        <Form4PersonalInfo countryCodes={countryCodes} />
      </Box>
      <Box
        style={{
          display: pageName === 'Form5NZContactInfo' ? 'block' : 'none'
        }}
      >
        <Form5NZContactInfo
          countryCodes={countryCodes}
          streetTypes={streetTypes}
        />
      </Box>
      <Box
        style={{
          display: pageName === 'Form6NonNZContactInfo' ? 'block' : 'none'
        }}
      >
        <Form6NonNZContactInfo
          countryCodes={countryCodes}
          streetTypes={streetTypes}
        />
      </Box>
      <Box
        style={{
          display: pageName === 'Form7CashMovement' ? 'block' : 'none'
        }}
      >
        <Form7CashMovement cashMovementDirections={cashMovementDirections} />
      </Box>
      <Box
        style={{
          display: pageName === 'Form8CargoImportInfo' ? 'block' : 'none'
        }}
      >
        <Form8CargoImportInfo countryCodes={countryCodes} />
      </Box>
      <Box
        style={{
          display: pageName === 'Form9CargoExportInfo' ? 'block' : 'none'
        }}
      >
        <Form9CargoExportInfo nonNZCountryCodes={nonNZCountryCodes} />
      </Box>
      <Box
        style={{
          display: pageName === 'Form10MailImportInfo' ? 'block' : 'none'
        }}
      >
        <Form10MailImportInfo countryCodes={countryCodes} />
      </Box>
      <Box
        style={{
          display: pageName === 'Form11MailExportInfo' ? 'block' : 'none'
        }}
      >
        <Form11MailExportInfo nonNZCountryCodes={nonNZCountryCodes} />
      </Box>
      <Box
        style={{
          display: pageName === 'Form12InPersonArrival' ? 'block' : 'none'
        }}
      >
        <Form12InPersonArrival countryCodes={countryCodes} />
      </Box>
      <Box
        style={{
          display: pageName === 'Form13InPersonDeparture' ? 'block' : 'none'
        }}
      >
        <Form13InPersonDeparture nonNZCountryCodes={nonNZCountryCodes} />
      </Box>
      <Box
        style={{
          display: pageName === 'Form14InfoOnCash' ? 'block' : 'none'
        }}
      >
        <Form14InfoOnCash />
      </Box>
      <Box
        style={{
          display: pageName === 'Form15InfoOnCashBehalf' ? 'block' : 'none'
        }}
      >
        <Form15InfoOnCashBehalf
          countryCodes={countryCodes}
          streetTypes={streetTypes}
        />
      </Box>
      <Box
        style={{
          display: pageName === 'Form16InfoOnCashDeliverTo' ? 'block' : 'none'
        }}
      >
        <Form16InfoOnCashDeliverTo
          countryCodes={countryCodes}
          streetTypes={streetTypes}
        />
      </Box>
      <Box
        style={{
          display: pageName === 'Form19SubmitDeclaration' ? 'block' : 'none'
        }}
      >
        <Form19SubmitDeclaration
          countryCodes={countryCodes}
          streetTypes={streetTypes}
          capacityTypes={capacityTypes}
        />
      </Box>
      <Box
        style={{
          display: pageName === 'Form20Signature' ? 'block' : 'none'
        }}
      >
        <Form20Signature />
      </Box>
    </>
  );
};
