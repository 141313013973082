import { Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, useEffect } from 'react';
import ControllerCheckbox from 'components/ControllerCheckbox';
import ControllerTextField from 'components/ControllerTextField';
import ControllerDatePicker from 'components/ControllerDatePicker';
import * as yup from 'yup';
import {
  ALPHABETS_NUMBERS_HYPHENS,
  ALPHABETS_NUMBERS_HYPHENS_MATCHES,
  MAX_LONG_STRING_LENGTH,
  MAX_LONG_STRING_LENGTH_WARNING
} from 'lib/yupConstants';
import moment from 'moment';
import { useFormContext } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) => ({
  fields: {
    margin: theme.spacing(1, 0, 0, 0)
  },
  sectionHeader: {
    padding: theme.spacing(2)
  },
  section: {
    padding: theme.spacing(0, 2, 3, 2)
  },
  formControlLabel: {
    fontSize: '1rem',
    '@media (min-width:600px)': {
      fontSize: '1.25rem'
    },
    '& label': { fontSize: '0.6rem' },
    color: theme.palette.info.light
  }
}));

export const form20SignatureDefaultValues = () => ({
  signature: '',
  signed: false,
  dateOfSignature: ''
});

export const Form20SignatureSchema = () =>
  yup.object().shape({
    signed: yup.boolean().oneOf([true], 'This is a required field'),
    signature: yup
      .string()
      .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
      .matches(
        ALPHABETS_NUMBERS_HYPHENS_MATCHES,
        `${ALPHABETS_NUMBERS_HYPHENS}`
      )
      .nullable()
      .required('This is a required field')
      .trim()
      .min(1),
    dateOfSignature: yup
      .string()
      .nullable()
      .required('This is a required field')
      .test('DOS', 'Please enter a valid date', (value) => {
        if (moment(value).isValid()) {
          return true;
        } else {
          return false;
        }
      })
  });

const Form20Signature: FC = () => {
  const classes = useStyles();

  const { setValue } = useFormContext();

  useEffect(() => {
    const newDate = new Date();
    setValue(
      `form20.dateOfSignature`,
      moment(newDate).format(moment.HTML5_FMT.DATETIME_LOCAL_MS)
    );
  }, [setValue]);

  return (
    <>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.sectionHeader}>
            Submitting the Border Cash Report declaration
          </Typography>
        </Grid>
      </Grid>
      <Grid container item spacing={3} className={classes.fields}>
        <Grid item xs={12}>
          <ControllerCheckbox
            name={'form20.signed'}
            label={
              <Typography className={classes.formControlLabel}>
                I declare that the information contained in this report is true
                and correct to the best of my knowledge.
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={11} md={6} xl={6}>
          <ControllerTextField
            name={`form20.signature`}
            label="Full Name"
            fullWidth
          />
        </Grid>
        <Grid item xs={11} md={6} xl={6}>
          <ControllerDatePicker
            name={`form20.dateOfSignature`}
            defaultValue={null}
            format="DD/MM/YYYY"
            label="Date"
            disableFuture
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container item spacing={2} className={classes.fields}>
        <Grid item xs={12}>
          <Typography variant="h5" className={classes.sectionHeader}>
            Use of and access to report once submitted
          </Typography>
          <Typography variant="h6" className={classes.section}>
            A Customs officer to whom a cash report is made is required by
            section 71(1) of the Act to forward it to the Commissioner of
            Police. Section 139 of the Act authorises New Zealand Customs
            Service to disclose information (that is not personal information)
            obtained by it under the Act to any government agency for law
            enforcement purposes.
            <br />
            <br />A person by whom, or on whose behalf, a cash report is given
            may obtain a copy of it from the Commissioner of Police, Police
            National Headquarters, Financial Intelligence Unit (FIU), PO Box
            3017, Wellington.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default Form20Signature;
