/**
 * Utility helpers for controller wrapper components.
 */

import { FieldError, FieldErrors } from 'react-hook-form';

/**
 * From a error object of the entire form, returns the error object for the given field name.
 * This helper method can deal with fieldNames with hierarchy, for example "postalAddress.postCode"
 * returns the correct error message for the field
 * @param allErrors the error object of the entire form
 * @param fieldName the name of the field
 * @returns
 */
export const fieldErrors = (
  allErrors: FieldErrors,
  fieldName: string
): FieldError | undefined => {
  const split = fieldName
    .split('.')
    .map((item) => item.split('['))
    .flat()
    .map((item) => item.split(']'))
    .flat()
    .filter((item) => !!item);
  // Return only the error object
  return split.reduce((acc, val) => {
    return acc ? acc[val] : undefined;
  }, allErrors);
};
