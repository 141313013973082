import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  DatePickerView
} from '@material-ui/pickers';
import { bcrTheme } from 'config/bcrTheme';
import moment from 'moment-timezone';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { NumberFormatValues } from 'react-number-format';
import * as yup from 'yup';
import { fieldErrors } from '../lib/util';

export interface ControllerDatePickerProps
  extends Partial<KeyboardDatePickerProps> {
  /** Name of the text field & name of the controller. No spaces allowed - (set label prop instead ) */
  name: string;
  label: string;
  /** Override the error message, based on name */
  helperText?: string;
  /** Override the error for the provided name */
  error?: boolean;
  /** Override the internal error validation */
  disableInternalValidation?: boolean;
  views?: DatePickerView[];
}

/**
 * `material-ui-pickers` inline date picker,
 *  wrapped with `react-hook-form` Controller.
 * @see https://material-ui-pickers.dev/demo/datepicker
 */
function ControllerDatePicker({
  name = 'controlled_text_field',
  helperText,
  error,
  label,
  format = 'DD/MM/YYYY',
  inputVariant = 'outlined',
  variant = 'inline',
  size = 'small',
  autoOk = true,
  disableInternalValidation = false,
  defaultValue,
  views,
  ...otherProps
}: ControllerDatePickerProps) {
  const {
    formState: { errors },

    trigger
  } = useFormContext();
  const isUp910 = useMediaQuery(bcrTheme.breakpoints.up(910));
  /**
   * useForm from react-hook-form doesn't give us enough flexibility around dates.
   * This helps prevent running a search if the date entered is invalid & showing an error.
   *
   * @param {string} errorMessage - if date is invalid/doesn't match requirements on element
   * @param {object} type - moment object
   * @param {*} name - name of input for manually setting/clearing error in useForm
   */

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, value, name, ref, ...field } }) => (
        <KeyboardDatePicker
          inputProps={field}
          inputRef={ref}
          variant={variant}
          views={views}
          placeholder={`${format}`}
          size={isUp910 ? 'small' : 'medium'}
          format={format}
          name={name}
          autoOk={autoOk}
          id={`controller_date_picker_${name}`}
          data-cy={`controller_date_picker_${name}`}
          onBlur={() => {
            trigger(name);
          }}
          onChange={(date, value) => {
            /**
             * TODO: Remove this onChange
             *       not only is this not guaranteed NZ, hijacking onChang, in unnecessarily opinionated
             */

            if (date) {
              const asNz = moment(date).format(moment.HTML5_FMT.DATE);
              // const asNz = moment(date);
              onChange(asNz);
            } else {
              onChange(null);
            }
            trigger(name);
          }}
          value={value || null}
          error={!!fieldErrors(errors, name) || error}
          helperText={fieldErrors(errors, name)?.message || helperText}
          inputVariant={inputVariant}
          label={label}
          InputAdornmentProps={{
            position: 'start'
          }}
          PopoverProps={{ id: 'calendar-popover' }}
          KeyboardButtonProps={{
            'aria-label': 'calendar-btn'
          }}
          FormHelperTextProps={{ role: 'alert' }}
          {...otherProps}
        />
      )}
    />
  );
}

export default ControllerDatePicker;

export type NumberValue = NumberFormatValues;

/** Yup validation object to reflect the shape of what numberInput returns  */
export const yupNumberFormat = yup
  .object({
    formattedValue: yup.string(),
    value: yup.string(),
    floatValue: yup.number()
  })
  .nullable();
