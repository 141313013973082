import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { CodeDescription } from 'types';
import ControllerAutocomplete from './ControllerAutocomplete';
import ControllerTextField from './ControllerTextField';

const useStyles = makeStyles((theme: Theme) => ({
  fields: {
    margin: theme.spacing(1, 0, 0, 0)
  }
}));

export const addressDefaultValues = {
  unit: '',
  floor: '',
  number: '',
  streetName: '',
  streetType: '',
  suburb: '',
  city: '',
  postcode: '',
  state: '',
  country: ''
};

interface AddressComponentProps {
  description: string;
  name: string;
  countryCodes: CodeDescription[];
  streetTypes: CodeDescription[];
  hidden?: boolean;
}

export const AddressComponent = ({
  description,
  name,
  countryCodes,
  streetTypes,
  hidden = false
}: AddressComponentProps) => {
  const classes = useStyles();
  return (
    <>
      <Grid container>
        <Grid container item>
          <Grid item xs={12}>
            <Typography variant="body1">{description}</Typography>
          </Grid>
        </Grid>
        <Grid container item spacing={2} className={classes.fields}>
          <Grid item xs={12} md={3} xl={3}>
            <ControllerTextField
              name={`${name}.unit`}
              label="Unit (optional)"
              defaultValue={''}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} xl={3}>
            <ControllerTextField
              name={`${name}.floor`}
              label="Floor (optional)"
              defaultValue={''}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} xl={3}>
            <ControllerTextField
              name={`${name}.number`}
              label="Street Number"
              defaultValue={''}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} xl={3}>
            <ControllerTextField
              name={`${name}.streetName`}
              label="Street Name"
              defaultValue={''}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} xl={3}>
            <ControllerAutocomplete
              name={`${name}.streetType`}
              label="Street Type"
              options={streetTypes || [{ description: '', code: '' }]}
              getOptionLabel={(option: CodeDescription) =>
                option.description || ''
              }
              getOptionSelected={(option, selected) =>
                option?.code === selected?.code
              }
            />
          </Grid>
          <Grid item xs={12} md={3} xl={3}>
            <ControllerTextField
              name={`${name}.suburb`}
              defaultValue={''}
              label="Suburb"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} xl={3}>
            <ControllerTextField
              name={`${name}.city`}
              label="City"
              defaultValue={''}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} xl={3}>
            <ControllerTextField
              name={`${name}.postcode`}
              label="Post/Zip Code"
              defaultValue={''}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3} xl={3}>
            <Box display={hidden === true ? 'none' : undefined}>
              <ControllerTextField
                name={`${name}.state`}
                label="State (optional)"
                defaultValue={''}
                fullWidth
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={3} xl={3}>
            <Box display={hidden === true ? 'none' : undefined}>
              <ControllerAutocomplete
                name={`${name}.country`}
                label="Country"
                // defaultValue={{ description: 'New Zealand', code: 'NZ' }}
                options={
                  countryCodes || [{ description: 'New Zealand', code: 'NZ' }]
                }
                getOptionLabel={(option: CodeDescription) =>
                  option.description || ''
                }
                getOptionSelected={(option, selected) =>
                  option?.code === selected?.code
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
