import * as yup from 'yup';

export const phones = () =>
  yup.object().shape({
    phoneTypeCode: yup
      .string()
      .nullable()
      .optional()
      .required('This is a required field'),
    countryCode: yup
      .object()
      .shape({
        code: yup.string().required('This is a required field'),
        description: yup.string().required('This is a required field')
      })
      .nullable()
      .required('This is a required field'),
    number: yup
      .string()
      .max(20)
      .nullable()
      .matches(/^[0-9]*$/g, 'Only number is required')
      .optional()
      .required('This is a required field')
  });
