import React from 'react';
import { Grid } from '@material-ui/core';
import ControllerTextField from 'components/ControllerTextField';

interface EmailComponentProps {
  name: string;
}

const EmailComponent = (props: EmailComponentProps) => {
  const { name } = props;

  return (
    <>
      <Grid container item spacing={2}>
        <Grid item xs={12} md={6} xl={6}>
          <ControllerTextField
            name={`${name}.emailAddress`}
            label="Email Address"
            type="email"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          <ControllerTextField
            name={`${name}.confirmEmailAddress`}
            label="Confirm Email Address"
            type="email"
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
};

export default EmailComponent;
