import { Container, Grid, makeStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  sectionHeader: {
    fontSize: '1.875rem',
    margin: theme.spacing(0, 0, 0, 0),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(4, 0, 0, 0),
      fontSize: '2.125rem'
    }
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.primary.main}`,
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    padding: 0
  }
}));

export const Accessibility = () => {
  const classes = useStyles();

  return (
    <>
      <Container maxWidth="lg">
        <Grid container>
          <Grid
            container
            item
            direction="column"
            alignItems="flex-start"
            justifyContent="flex-start"
            spacing={4}
          >
            <Grid item>
              <Typography variant="h4" className={classes.sectionHeader}>
                Accessibility
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">
                Our website has been designed to improve the customer experience
                when filling out the Border Cash Report.
              </Typography>
              <hr className={classes.divider} />
              <Typography variant="subtitle1">
                This website and its content have been developed with reference
                to the New Zealand Government Web Standards.
              </Typography>
              <Typography variant="subtitle1">
                Our design is intended to make our site more accessible. Being
                accessible means the information is easier to find, more
                logical, more complete and, much easier to read and understand.
              </Typography>
              {/* <br /> */}
              <Typography variant="subtitle1">
                Accessibility is also about a certain openness and
                inclusiveness. Customs is very much a part of the community that
                we are charged with protecting. So we have tried to make our
                site attractive and friendly to all. We hope the experience of
                using it is an enjoyable one.
              </Typography>
              <br />
              <Typography variant="h4">
                Browsers and operating environments
              </Typography>
              {/* <br /> */}
              <Typography variant="subtitle1">
                This website is responsive - this means you can view the website
                on multiple devices, e.g. desktops, laptops, iPads, tablets and
                some mobile phones.
              </Typography>
              {/* <br /> */}
              <Typography variant="subtitle1">
                We've also aimed to make the website as accessible as possible
                by ensuring that it has been tested on the following browsers
                and operating environments.
              </Typography>
              <br />
              <Typography variant="h4">Browsers:</Typography>
              {/* <br /> */}
              <ul>
                <li>Firefox</li>
                <li>Edge</li>
                <li>Chrome</li>
                <li>Safari.</li>
              </ul>
              {/* <br /> */}
              <Typography variant="h4">Mobile:</Typography>
              {/* <br /> */}
              <ul>
                <li>Android 9</li>
                <li>iOS.</li>
              </ul>
              {/* <br /> */}
              <Typography variant="h4">Contact</Typography>
              {/* <br /> */}
              <Typography variant="subtitle1">
                Should you experience any technical difficulties, or find
                content problems, or have a suggestion for improving the
                website, please don’t hesitate to contact us.
              </Typography>
              <br />
              <br />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
