import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { AddressComponent } from 'components/AddressComponent';
import { useFormContext } from 'react-hook-form';
import { CodeDescription } from 'types';
import { PhoneComponent } from 'components/PhoneComponent';
import { SubmittingWarningNote } from './SubmittingWarningNote';
import * as yup from 'yup';
import { phones } from 'lib/yupPhones';
import {
  ALPHABETS_NUMBERS_HYPHENS,
  ALPHABETS_NUMBERS_HYPHENS_MATCHES,
  MAX_LONG_STRING_LENGTH,
  MAX_LONG_STRING_LENGTH_WARNING
} from 'lib/yupConstants';
import { email } from 'lib/yupEmail';
import EmailComponent from 'components/EmailComponent';

const useStyles = makeStyles((theme: Theme) => ({
  fields: {
    margin: theme.spacing(2, 0, 4, 0)
  },
  sectionHeader: {
    margin: theme.spacing(0, 5, 0, 0)
  },
  bottom: {
    margin: theme.spacing(2, 0, -4, 0)
  }
}));

export const form5NZContactInfoDefaultValues = () => ({
  cashOwner: {
    phones: [{}]
  }
});

export const Form5NZContactInfoSchema = (isNZResident) => {
  if (isNZResident === true) {
    return yup.object().shape({
      cashOwner: yup
        .object()
        .shape({
          address: yup
            .object()
            .shape({
              unit: yup
                .string()
                .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
                .matches(
                  ALPHABETS_NUMBERS_HYPHENS_MATCHES,
                  `${ALPHABETS_NUMBERS_HYPHENS}`
                )
                .nullable()
                .notRequired(),
              floor: yup
                .string()
                .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
                .matches(
                  ALPHABETS_NUMBERS_HYPHENS_MATCHES,
                  `${ALPHABETS_NUMBERS_HYPHENS}`
                )
                .nullable()
                .notRequired(),
              number: yup
                .string()
                .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
                .matches(
                  ALPHABETS_NUMBERS_HYPHENS_MATCHES,
                  `${ALPHABETS_NUMBERS_HYPHENS}`
                )
                .nullable()
                .required('This is a required field')
                .trim()
                .min(1),
              streetName: yup
                .string()
                .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
                .matches(
                  ALPHABETS_NUMBERS_HYPHENS_MATCHES,
                  `${ALPHABETS_NUMBERS_HYPHENS}`
                )
                .nullable()
                .required('This is a required field')
                .trim()
                .min(1),
              streetType: yup
                .object()
                .shape({
                  code: yup.string().required('This is a required field'),
                  description: yup.string().required('This is a required field')
                })
                .nullable()
                .required('This is a required field'),
              suburb: yup
                .string()
                .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
                .matches(
                  ALPHABETS_NUMBERS_HYPHENS_MATCHES,
                  `${ALPHABETS_NUMBERS_HYPHENS}`
                )
                .nullable()
                .required('This is a required field')
                .trim()
                .min(1),
              city: yup
                .string()
                .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
                .matches(
                  ALPHABETS_NUMBERS_HYPHENS_MATCHES,
                  `${ALPHABETS_NUMBERS_HYPHENS}`
                )
                .nullable()
                .required('This is a required field')
                .trim()
                .min(1),
              postcode: yup
                .string()
                .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
                .matches(
                  ALPHABETS_NUMBERS_HYPHENS_MATCHES,
                  `${ALPHABETS_NUMBERS_HYPHENS}`
                )
                .nullable()
                .required('This is a required field')
                .trim()
                .min(1)
            })
            .required('This is a required field'),
          phones: yup.array().min(1).of(phones()).required(),
          emailAddress: email.emailAddress,
          confirmEmailAddress: email.confirmEmailAddress
        })
        .required()
    });
  } else {
    return yup.object({});
  }
};

interface Form5Props {
  countryCodes: CodeDescription[];
  streetTypes: CodeDescription[];
}

export const Form5NZContactInfo = ({
  countryCodes,
  streetTypes
}: Form5Props) => {
  const classes = useStyles();
  const { control, setValue, getValues } = useFormContext();

  return (
    <>
      <Grid container item>
        <Grid item xs={12}>
          <Typography variant="h4">Your contact information</Typography>
        </Grid>
      </Grid>
      <Grid container item spacing={2} className={classes.fields}>
        <SubmittingWarningNote />
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <AddressComponent
            description="Permanent Physical Address in New Zealand:"
            name="form5.cashOwner.address"
            countryCodes={countryCodes}
            streetTypes={streetTypes}
            hidden={true}
          />
        </Grid>
        <Grid item xs={12}>
          <PhoneComponent
            control={control}
            description="Phone Number:"
            name="form5.cashOwner.phones"
            growNo={3}
            getValues={getValues}
            setValue={setValue}
          />
        </Grid>
        <Grid item xs={12}>
          <EmailComponent name="form5.cashOwner" />
        </Grid>
      </Grid>
    </>
  );
};
