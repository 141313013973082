import React, { FC } from 'react';
import { Grid, Hidden, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ControllerTextField from 'components/ControllerTextField';
import { AddressComponent } from 'components/AddressComponent';
import { useFormContext } from 'react-hook-form';
import { PhoneComponent } from 'components/PhoneComponent';
import { CodeDescription } from 'types';
import * as yup from 'yup';
import {
  ALPHABETS_NUMBERS_HYPHENS,
  ALPHABETS_NUMBERS_HYPHENS_MATCHES,
  MAX_LONG_STRING_LENGTH,
  MAX_LONG_STRING_LENGTH_WARNING
} from 'lib/yupConstants';
import { address } from 'lib/yupAddress';
import { phones } from 'lib/yupPhones';

const useStyles = makeStyles((theme: Theme) => ({
  fields: {
    margin: theme.spacing(1, 0, 0, 0)
  },
  sectionHeader: {
    margin: theme.spacing(1, 5, 0, 0)
  },
  cardPadding: {
    margin: theme.spacing(1, 0, 1, 0)
  },
  bottom: {
    marginBottom: theme.spacing(-2)
  }
}));

export const organisation = {
  shape: {
    companyName: yup
      .string()
      .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
      .matches(
        ALPHABETS_NUMBERS_HYPHENS_MATCHES,
        `${ALPHABETS_NUMBERS_HYPHENS}`
      )
      .nullable()
      .required('This is a required field')
      .trim()
      .min(1),
    businessIdentificationNumber: yup
      .string()
      .nullable()
      .test('NZBN', 'Not a valid NZBN number', (value) => {
        if (value === '' || value === null) {
          return true;
        } else {
          const trimmedValue = value?.trim();
          const checkNumber = trimmedValue?.slice(-1);
          const restNumber = trimmedValue?.slice(0, trimmedValue?.length - 1);
          const restNumberArray = restNumber.split('');

          let index = 1;
          const sum = restNumberArray.reduce((accumulator, currentValue) => {
            if (index % 2 === 1) {
              index++;
              return accumulator + parseInt(currentValue) * 1;
            }
            if (index % 2 === 0) {
              index++;
              return accumulator + parseInt(currentValue) * 3;
            }
          }, 0);

          const subResult = (sum + 9) / 10;
          const result = parseInt(subResult.toString(), 10) * 10 - sum;
          if (
            trimmedValue.length === 13 &&
            result === parseInt(checkNumber.toString(), 10)
          ) {
            return true;
          } else {
            return false;
          }
        }
      })
      .optional(),
    businessActivity: yup
      .string()
      .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
      .matches(
        ALPHABETS_NUMBERS_HYPHENS_MATCHES,
        `${ALPHABETS_NUMBERS_HYPHENS}`
      )
      .nullable()
      .required('This is a required field')
      .trim()
      .min(1),
    relationToCompany: yup
      .string()
      .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
      .matches(
        ALPHABETS_NUMBERS_HYPHENS_MATCHES,
        `${ALPHABETS_NUMBERS_HYPHENS}`
      )
      .nullable()
      .required('This is a required field')
      .trim()
      .min(1),
    address: address(),
    phones: yup.array().min(1).of(phones()).required()
  }
};

interface Form18Props {
  countryCodes: CodeDescription[];
  streetTypes: CodeDescription[];
  isCashDelivering: boolean;
  nameSpace: string;
}

export const Form18InfoOnBehalfCompany: FC<Form18Props> = ({
  countryCodes,
  streetTypes,
  isCashDelivering,
  nameSpace
}) => {
  const classes = useStyles();

  const { control, getValues, setValue } = useFormContext();

  return (
    <>
      <Grid container>
        <Grid container item spacing={2} className={classes.cardPadding}>
          <Hidden xsDown>
            <Grid item xs={12} md={12} xl={12}>
              <ControllerTextField
                name={`${nameSpace}.companyName`}
                label={
                  isCashDelivering
                    ? 'Full name of the business or organisation you are delivering the cash to'
                    : 'Full name of the business or organisation you are moving or the receiving the cash for'
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <ControllerTextField
                name={`${nameSpace}.businessIdentificationNumber`}
                label="What is their business identification number? (optional)"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <ControllerTextField
                name={`${nameSpace}.businessActivity`}
                label="What is their business or main activity?"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12} xl={12}>
              <ControllerTextField
                name={`${nameSpace}.relationToCompany`}
                label="What is your relationship with the business or organisation?"
                fullWidth
              />
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid item xs={12} md={12} xl={12}>
              <Typography variant="subtitle1">
                {isCashDelivering
                  ? 'Full name of the business or organisation you are delivering the cash to:'
                  : 'Full name of the business or organisation you are moving or the receiving the cash for:'}
              </Typography>
              <ControllerTextField
                name={`${nameSpace}.companyName`}
                label=""
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <Typography variant="subtitle1">
                What is their business identification number? (optional):
              </Typography>
              <ControllerTextField
                name={`${nameSpace}.businessIdentificationNumber`}
                label=""
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <Typography variant="subtitle1">
                What is their business or main activity?
              </Typography>
              <ControllerTextField
                name={`${nameSpace}.businessActivity`}
                label=""
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12} xl={12}>
              <Typography variant="subtitle1">
                What is your relationship with the business or organisation?
              </Typography>
              <ControllerTextField
                name={`${nameSpace}.relationToCompany`}
                label=""
                fullWidth
              />
            </Grid>
          </Hidden>
        </Grid>

        <Grid container item spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.sectionHeader}>
              Their contact information
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <AddressComponent
              description="What is their permanent business or organisation address?"
              name={`${nameSpace}.address`}
              countryCodes={countryCodes}
              streetTypes={streetTypes}
            />
          </Grid>
          <Grid item xs={12} className={classes.bottom}>
            <PhoneComponent
              control={control}
              description="Phone Number:"
              name={`${nameSpace}.phones`}
              growNo={2}
              phoneTypes={['Mobile', 'Work']}
              getValues={getValues}
              setValue={setValue}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
