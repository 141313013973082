import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Link from '../components/Link';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    marginTop: '20vh'
  }
}));

function NotFound() {
  const classes = useStyles();

  return (
    <div data-testid="NotFound" className={classes.root}>
      <Typography variant="h3">404 - Page Not Found!</Typography>
      <Link to="/" variant="h6" color="inherit">
        Go Home
      </Link>
    </div>
  );
}

export default NotFound;
