import * as yup from 'yup';
import {
  ALPHABETS_NUMBERS_HYPHENS,
  ALPHABETS_NUMBERS_HYPHENS_MATCHES,
  MAX_LONG_STRING_LENGTH,
  MAX_LONG_STRING_LENGTH_WARNING
} from './yupConstants';

export const address = () =>
  yup.object().shape({
    unit: yup
      .string()
      .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
      .matches(
        ALPHABETS_NUMBERS_HYPHENS_MATCHES,
        `${ALPHABETS_NUMBERS_HYPHENS}`
      )
      .optional(),
    floor: yup
      .string()
      .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
      .matches(
        ALPHABETS_NUMBERS_HYPHENS_MATCHES,
        `${ALPHABETS_NUMBERS_HYPHENS}`
      )
      .optional(),
    number: yup
      .string()
      .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
      .matches(
        ALPHABETS_NUMBERS_HYPHENS_MATCHES,
        `${ALPHABETS_NUMBERS_HYPHENS}`
      )
      .required('This is a required field')
      .trim()
      .min(1),
    streetName: yup
      .string()
      .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
      .matches(
        ALPHABETS_NUMBERS_HYPHENS_MATCHES,
        `${ALPHABETS_NUMBERS_HYPHENS}`
      )
      .required('This is a required field')
      .trim()
      .min(1),
    streetType: yup
      .object()
      .shape({
        code: yup.string().required('This is a required field'),
        description: yup.string().required('This is a required field')
      })
      .nullable()
      .required('This is a required field'),
    suburb: yup
      .string()
      .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
      .matches(
        ALPHABETS_NUMBERS_HYPHENS_MATCHES,
        `${ALPHABETS_NUMBERS_HYPHENS}`
      )
      .required('This is a required field')
      .trim()
      .min(1),
    city: yup
      .string()
      .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
      .matches(
        ALPHABETS_NUMBERS_HYPHENS_MATCHES,
        `${ALPHABETS_NUMBERS_HYPHENS}`
      )
      .required('This is a required field')
      .trim()
      .min(1),
    postcode: yup
      .string()
      .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
      .matches(
        ALPHABETS_NUMBERS_HYPHENS_MATCHES,
        `${ALPHABETS_NUMBERS_HYPHENS}`
      )
      .required('This is a required field')
      .trim()
      .min(1),
    state: yup
      .string()
      .max(MAX_LONG_STRING_LENGTH, MAX_LONG_STRING_LENGTH_WARNING)
      .matches(
        ALPHABETS_NUMBERS_HYPHENS_MATCHES,
        `${ALPHABETS_NUMBERS_HYPHENS}`
      )
      .optional(),
    country: yup
      .object()
      .shape({
        code: yup.string().required('This is a required field'),
        description: yup.string().required('This is a required field')
      })
      .nullable()
      .required('This is a required field')
  });
