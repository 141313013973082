import { makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  sectionColor: {
    padding: theme.spacing(2, 1, 2, 2),
    color: theme.palette.customs.lightBlue
  },
  paper: {
    marginRight: theme.spacing(2),
    '@media (min-width:600px)': {
      marginRight: theme.spacing(1)
    },
    background: '#f1f5f9',
    borderLeftColor: theme.palette.info.light,
    borderLeftStyle: 'solid',
    borderLeftWidth: '10px',
    width: '100%'
  }
}));

export const SubmittingWarningNote = () => {
  const classes = useStyles();
  return (
    <>
      <Paper elevation={0} square={true} className={classes.paper}>
        <Typography variant="subtitle1" className={classes.sectionColor}>
          If you are submitting this Border Cash Report on behalf of someone who
          is unable to, please fill in their information.
        </Typography>
      </Paper>
    </>
  );
};
