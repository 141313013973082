import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ControllerRadioGroup from 'components/ControllerRadioGroup';
import { CodeDescription } from 'types';
import ControllerAutocomplete from 'components/ControllerAutocomplete';
import * as yup from 'yup';
import { useFormContext } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) => ({
  fields: {
    margin: theme.spacing(1, 0, 0, 0)
  },
  sectionHeader: {
    margin: theme.spacing(1, 5, 0, 0)
  },
  areYou: {
    margin: theme.spacing(4, 0, 0, 0)
  },
  leftMargin: {
    marginLeft: theme.spacing(1)
  }
}));

export const Form7CashMovementSchema = () =>
  yup.object().shape({
    cashMovementMethod: yup.string().required('This is a required field'),
    cashMovementDirection: yup
      .object()
      .shape({
        code: yup.string().required('This is a required field'),
        description: yup.string().required('This is a required field')
      })
      .nullable()
      .required('This is a required field')
  });

export const form7CashMovementDefaultValues = () => ({
  cashMovementMethod: '',
  cashMovementDirection: null
});

interface Form7Props {
  cashMovementDirections: CodeDescription[];
}

export const Form7CashMovement = ({ cashMovementDirections }: Form7Props) => {
  const classes = useStyles();
  const [dynamicCashMovementDirections, setDynamicCashMovementDirections] =
    useState(cashMovementDirections);

  const { watch, setValue } = useFormContext();
  let isInPerson = false;
  isInPerson = watch(`form7.cashMovementMethod`) === 'inPerson' ? true : false;

  useEffect(() => {
    if (isInPerson) {
      setValue(`form7.cashMovementDirection`, null);
      setDynamicCashMovementDirections(
        cashMovementDirections.filter(
          (e) => e.description !== 'Receiving cash from outside New Zealand'
        )
      );
    } else {
      setDynamicCashMovementDirections(cashMovementDirections);
    }
  }, [cashMovementDirections, isInPerson, setValue]);

  return (
    <>
      <Grid container item spacing={2} className={classes.fields}>
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.sectionHeader}>
            Information about the cash movement
          </Typography>
        </Grid>
      </Grid>
      <Box my={3}>
        <Grid container>
          <Grid container item spacing={2} className={classes.leftMargin}>
            <Grid item xs={12}>
              <ControllerRadioGroup
                label="How is the cash being moved?"
                name={`form7.cashMovementMethod`}
                defaultValue={null}
                options={[
                  { label: 'By Cargo', value: 'cargo' },
                  { label: 'By Mail', value: 'mail' },
                  { label: 'In Person', value: 'inPerson' }
                ]}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={2} className={classes.areYou}>
            <Grid item xs={12}>
              <ControllerAutocomplete
                name={`form7.cashMovementDirection`}
                label="Are you … (pick an option)"
                options={
                  dynamicCashMovementDirections || [
                    { description: '', code: '' }
                  ]
                }
                getOptionLabel={(option: CodeDescription) =>
                  option.description || ''
                }
                getOptionSelected={(option, selected) =>
                  option?.code === selected?.code
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
