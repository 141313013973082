import React from 'react';
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography
} from '@material-ui/core';
import { LogoNZGovt } from 'assets/logos';
import CustomModal from 'components/Modal';

/*---------------------------------
CUSTOM STYLES - MUI
-----------------------------------*/
const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    zIndex: 100,
    color: 'white',
    // fontFamily: 'FuturaPT-PT',
    fontSize: '1rem',
    fontWeight: 500,
    padding: theme.spacing(0.5, 5, 2, 2),
    '& ul': {
      listStyleType: 'none',
      padding: 0,
      '& li': {
        marginLeft: theme.spacing(2),
        display: 'inline-block',
        '&:first-child': {
          marginLeft: 0
        },
        [theme.breakpoints.down('md')]: {
          display: 'block',
          margin: theme.spacing(1, 0)
        }
      },
      [theme.breakpoints.down('lg')]: {
        justifySelf: 'end'
      },
      [theme.breakpoints.down('xs')]: {
        justifySelf: 'start',
        gridRow: '1'
      }
    },
    '& a': {
      color: 'white',
      textDecoration: 'none'
    },
    [theme.breakpoints.down(700)]: {
      padding: theme.spacing(1, 2, 1, 2)
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
      gap: theme.spacing(3)
    }
  },
  logos: {
    '& svg': {
      width: '100%',
      maxWidth: '13rem'
    }
  },
  logoContainer: {
    marginLeft: theme.spacing(4),
    display: 'inline-block',
    '&:first-child': {
      marginLeft: 0
    },
    [theme.breakpoints.down('lg')]: {
      display: 'block',
      margin: theme.spacing(2, 4, 2, 2)
    }
  },
  block: {
    display: 'block'
  },
  spanColor: {
    color: theme.palette.primary.main
  }
  // menuLinks: {
  //   fontSize: '1.25rem'
  //   // fontWeight: 400
  // }
}));

/*---------------------------------
EXPORT COMPONENT
-----------------------------------*/
const Footer = () => {
  const classes = useStyles();

  return (
    <div data-testid="Footer">
      <footer className={classes.footer}>
        <div className={classes.logos}>
          <div className={classes.logoContainer}>
            <a href="https://www.govt.nz/" target="_blank" rel="noreferrer">
              <LogoNZGovt />
            </a>
          </div>
        </div>
        <ul>
          <li>
            <CustomModal
              buttonStyle="link"
              buttonColor="white"
              buttonText="Privacy Statement"
              children={
                <div>
                  <Typography variant="h4">Privacy Statement</Typography>
                  <Typography variant="subtitle1">
                    Any personal information you provide us with is viewable by
                    site administrators and select staff at Customs for the
                    purpose of processing your application and risk analysis.
                    This information will also be provided to New Zealand Police
                    for assessment and analysis.
                  </Typography>
                  <Typography variant="subtitle1">
                    You are required to provide this information under the
                    Anti-Money Laundering and Countering Financing of Terrorism
                    Act 2009, sections 68-71, if you are moving NZ$10,000 or
                    more, or foreign equivalent in physical currency or a Bearer
                    Negotiable Instrument (BNI) across the border via air or
                    Sea. You may provide this information via our web based form
                    or a physical form which will be provided to you in person.
                  </Typography>
                  <Typography variant="h4">Security</Typography>
                  <Typography variant="subtitle1">
                    Customs’ websites including the Border Cash Report (BCR)
                    interface have security measures in place to prevent the
                    loss, unlawful access, use, modification, disclosure, or
                    other misuse, consistent with good practice and as required
                    by relevant law and policy. In order to maintain the cyber
                    security of the Custom’s systems and information, our
                    systems are subject to ongoing monitoring (including
                    activity logging), analysis and auditing.
                  </Typography>
                  <Typography variant="h4">
                    How we will use your information
                  </Typography>
                  <Typography variant="subtitle1">
                    Your information will be used to process your declaration to
                    transfer cash or a Bearer Negotiable Instrument (BNI) to the
                    value of NZD$10,000 or more or foreign equivalent across the
                    border.
                  </Typography>
                  <Typography variant="subtitle1">
                    We may use information about your use of our websites and/or
                    other IT systems to prevent unauthorised access or attacks
                    on these systems or to resolve such events. We may use this
                    information even if you are not involved in such activity.
                    Customs may utilise services from one or more third party
                    suppliers to monitor or maintain cyber security of its
                    systems and information. These third party suppliers will
                    have access to monitoring and logging information. Use of
                    Customs' websites and/or other IT systems implies acceptance
                    of their being monitored.
                  </Typography>
                  <Typography variant="h4">
                    Access to personal information we hold about you
                  </Typography>
                  <Typography variant="subtitle1">
                    Under the Privacy Act 2020, you have the right to ask us to
                    access any information that Customs holds about you and to
                    make any correction to that information where it is
                    incorrect.
                  </Typography>
                  <Typography variant="h6">
                    Further enquiries should be made in writing to:
                  </Typography>
                  <Typography variant="subtitle1">
                    <strong className={classes.block}>
                      New Zealand Customs Service
                    </strong>
                    <span className={classes.block}>The Privacy Officer</span>
                    <span className={classes.block}>
                      privacy@Customs.govt.nz
                    </span>
                    <span className={classes.block}>
                      The New Zealand Customs Service
                    </span>
                    <span className={classes.block}>PO Box 2218</span>
                    <span className={classes.block}>Wellington 6140</span>
                  </Typography>
                  <Typography variant="h4">Use of Cookies</Typography>
                  <Typography variant="subtitle1">
                    The BCR interface uses 'cookies', which are small files that
                    may be placed on your hard disk for record-keeping purposes.
                    A cookie allows us to remember who you are. Cookies can make
                    your subsequent visits to the web page simpler and more
                    seamless. You can set your browser to notify you when you
                    receive a cookie, giving you the chance to decide whether or
                    not to accept it. (However, by not accepting cookies, some
                    web pages may not display properly or you may not be
                    permitted to access certain information.)
                  </Typography>
                  <Typography variant="h4">Records</Typography>
                  <Typography variant="subtitle1">
                    Your transactions through the BCR interface may constitute
                    public records and be retained to the extent required by the
                    Public Records Act 2005. We may also disclose those
                    materials if required by law (such as under the Official
                    Information Act 1982), Ministers or parliamentary
                    convention.
                  </Typography>
                  <Typography variant="h4">
                    If you have a privacy complaint
                  </Typography>
                  <Typography variant="subtitle1">
                    If you're not satisfied with our response to any
                    privacy-related concern you may have, you can contact the
                    Privacy Commissioner.
                  </Typography>
                  <Typography variant="subtitle1">
                    <strong className={classes.block}>
                      Office of the Privacy Commissioner
                    </strong>
                    <span className={classes.block}>PO Box 10094</span>
                    <span className={classes.block}>Wellington 6143</span>
                    <span className={classes.block}>
                      Freephone: 0800 803 909
                    </span>
                    <span className={classes.block}>Phone: +64 4 474 7590</span>
                    <span className={classes.block}>Fax: +64 4 474 7595</span>
                    <span className={classes.block}>
                      Email:{' '}
                      <a href="mailto:enquiries@privacy.org.nz">
                        enquiries@privacy.org.nz
                      </a>
                    </span>
                  </Typography>
                </div>
              }
            />
          </li>
          <li>
            <CustomModal
              buttonStyle="link"
              buttonColor="white"
              buttonText="Contact"
              children={
                <div>
                  <Typography variant="h4">Contact</Typography>
                  <Typography variant="subtitle1">
                    New Zealand Customs Service
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>National Contact Center</TableCell>
                          <TableCell>Contact</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>Email</TableCell>
                          <TableCell>
                            <a href="mailto:feedback@customs.govt.nz">
                              feedback@customs.govt.nz
                            </a>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Postal Address</TableCell>
                          <TableCell>
                            <span className={classes.block}>
                              The Customhouse
                            </span>
                            <span className={classes.block}>
                              1 Hinemoa Street
                            </span>
                            <span className={classes.block}>Harbour Quays</span>
                            <span className={classes.block}>P.O. Box 2218</span>
                            <span className={classes.block}>
                              Wellington 6140
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              }
            />
          </li>
          <li>
            <CustomModal
              buttonStyle="link"
              buttonColor="white"
              buttonText="Feedback"
              children={
                <div>
                  <Typography variant="h4">Feedback</Typography>
                  <Typography variant="subtitle1">
                    If you have any questions about this site, you can contact
                    the Border Cash Report team on{' '}
                    <a href="mailto:feedback@customs.govt.nz">
                      feedback@customs.govt.nz
                    </a>
                    .
                  </Typography>
                </div>
              }
            />
          </li>
          <li>
            <CustomModal
              buttonStyle="link"
              buttonColor="white"
              buttonText="Terms of use"
              children={
                <div>
                  <Typography variant="h4">Copyright</Typography>
                  <Typography variant="subtitle1">
                    Crown copyright © Copyright material on the{' '}
                    <span className={classes.spanColor}>
                      Border Cash Report
                    </span>{' '}
                    is protected by copyright owned by the New Zealand Customs
                    Service on behalf of the Crown or its licensors. Unless
                    indicated otherwise for specific items or collections of
                    content (either below or within specific items or
                    collections), this copyright material is licensed for re-use
                    under the Creative Commons Attribution 4.0 New Zealand
                    licence. In essence, you are free to copy, distribute and
                    adapt the material, as long as you attribute it to New
                    Zealand Customs Services and abide by other licence terms.
                  </Typography>
                </div>
              }
            />
          </li>
        </ul>
      </footer>
    </div>
  );
};

export default Footer;
